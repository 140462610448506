import { isEmpty } from "lodash";
import { useEffect } from "react";
import {
  Controller,
  FormProvider,
  useFieldArray,
  useForm,
  useFormContext,
  useWatch,
} from "react-hook-form";
import slugify from "slugify";

import { FlowT } from "src/api/flowTypes";
import { useFlowVersion } from "src/api/flowVersionQueries";
import { useCopyFlow, useFlows } from "src/api/queries";
import { Button } from "src/base-components/Button";
import { Input } from "src/base-components/Input";
import {
  LoadingView,
  LoadingViewQueryResult,
} from "src/base-components/LoadingView";
import { Modal } from "src/base-components/Modal";
import { RequiredAsterisk } from "src/base-components/RequiredAsterisk";
import { toastSuccess } from "src/base-components/Toast/utils";
import { FlowAndVersionDropdown } from "src/flowsOverview/FlowAndVersionDropdown";
import { NameInput } from "src/flowsOverview/FlowNameInput";
import { SlugInput } from "src/flowsOverview/FlowSlugInput";
import { OrgAndWorkspaceDropdown } from "src/flowsOverview/OrgAndWorkspaceDropdown";
import { FlowFromTemplateForm } from "src/flowsOverview/types";
import { handleNoFolderId } from "src/flowsOverview/v2/handleNoFolderId";
import {
  nameValidations,
  slugValidations,
} from "src/layout/WorkspaceFormModal/utils";
import { OrgWithWorkspaces } from "src/layout/types";
import { useFolderIdSearchParam } from "src/router/SearchParams";
import { DashboardPageParamsT } from "src/router/urls";
import { wrapWithAxiosResponseErrorHandler } from "src/utils/toastError";
import { useCurrentWorkspace } from "src/utils/useCurrentWorkspace";
import { useParamsDecode } from "src/utils/useParamsDecode";

type PropsT = {
  isOpen: boolean;
  handleClose: () => void;
};

type SelectFlowAndVersionPropsT = {
  flowsResult: LoadingViewQueryResult<FlowT[]>;
} & { index: number | null };

const SelectFlowAndVersion: React.FC<SelectFlowAndVersionPropsT> = ({
  flowsResult,
  index,
}) => {
  const { control, setValue } = useFormContext<FlowFromTemplateForm>();

  return (
    <LoadingView
      queryResult={flowsResult}
      renderUpdated={(flows: FlowT[]) => (
        <div className="mb-6">
          <p className="mb-2 text-gray-800 font-inter-semibold-13px">
            Create from <RequiredAsterisk />
          </p>
          {index !== null ? (
            <Controller
              control={control}
              name={`childFlows.${index}`}
              render={({ field }) => {
                const selectedFlow = flows.find(
                  (flow) => flow.id === field.value.id,
                );
                return (
                  <Input
                    value={`${selectedFlow?.name} - ${
                      field.value.versionIds.length
                    } version${field.value.versionIds.length > 1 ? "s" : ""}`}
                    disabled
                    fullWidth
                  />
                );
              }}
            />
          ) : (
            <Controller
              control={control}
              name="flowToCopy"
              render={({ field }) => (
                <FlowAndVersionDropdown
                  flows={flows}
                  selected={field.value}
                  onSelect={(key) => {
                    field.onChange(key);
                    const selectedFlow = flows.find(
                      (flow) => flow.id === key?.id,
                    );
                    if (selectedFlow) {
                      setValue("name", selectedFlow.name);
                      setValue("slug", selectedFlow.slug);
                    }
                  }}
                />
              )}
            />
          )}
        </div>
      )}
    />
  );
};

const SelectOrgAndWorkspace: React.FC<{
  orgs: OrgWithWorkspaces[];
}> = ({ orgs }) => {
  const { control, resetField } = useFormContext<FlowFromTemplateForm>();
  return (
    <div className="mb-6">
      <p className="mb-2 text-gray-800 font-inter-semibold-13px">
        Select org and workspace <RequiredAsterisk />
      </p>
      <Controller
        control={control}
        name="orgWorkspace"
        render={({ field }) => (
          <OrgAndWorkspaceDropdown
            orgs={orgs}
            selected={field.value}
            onSelect={(value) => {
              field.onChange(value);
              // reset the flow fields when selecting a new workspace
              if (value?.workspaceId !== field.value.workspaceId) {
                resetField("flowToCopy");
                resetField("name");
                resetField("slug");
                resetField("childFlows");
              }
            }}
          />
        )}
      />
    </div>
  );
};

export const CreateFlowFromTemplateModal: React.FC<PropsT> = ({
  isOpen,
  handleClose,
}) => {
  const { wsId } = useParamsDecode<DashboardPageParamsT>();
  const [folderId] = useFolderIdSearchParam();
  const copyFlowMutation = useCopyFlow(wsId);
  const formMethods = useForm<FlowFromTemplateForm>({
    defaultValues: {
      orgWorkspace: { orgId: undefined, workspaceId: undefined },
      flowToCopy: { id: undefined, versionId: undefined },
      childFlows: [],
    },
    mode: "onChange",
    reValidateMode: "onChange",
  });
  const {
    formState: { errors, isSubmitting },
    control,
    reset,
    register,
    setValue,
    handleSubmit,
  } = formMethods;
  const { fields } = useFieldArray({ control: control, name: "childFlows" });

  useEffect(() => {
    if (isOpen) {
      reset();
    }
  }, [isOpen, reset]);

  const { orgs } = useCurrentWorkspace();
  const selectedWorkspaceId = useWatch({
    control,
    name: "orgWorkspace.workspaceId",
  });
  const flowsResult = useFlows({
    workspaceId: selectedWorkspaceId,
    disabled: selectedWorkspaceId === undefined,
  });

  const selectedVersionId = useWatch({
    control,
    name: "flowToCopy.versionId",
  });
  const flowVersion = useFlowVersion(selectedVersionId);

  const onSubmit = wrapWithAxiosResponseErrorHandler(
    handleSubmit(async (formData) => {
      await copyFlowMutation.mutateAsync({
        sourceOrgId: formData.orgWorkspace.orgId,
        flowId: formData.flowToCopy.id,
        flowVersionId: formData.flowToCopy.versionId,
        newName: formData.name,
        newSlug: formData.slug,
        childFlowNamings: Object.fromEntries(
          formData.childFlows.map((childFlow) => [
            childFlow.id,
            { name: childFlow.name, slug: childFlow.slug },
          ]),
        ),
        folderId: handleNoFolderId(folderId),
      });
      toastSuccess({
        title: "Decision Flow created",
        description:
          "The Decision Flow has been successfully created from the template.",
      });
      handleClose();
    }),
  );

  // When a template is selected we need to find its child flows and set them into the form
  useEffect(() => {
    if (flowVersion.data && flowsResult.data) {
      const childFlows: FlowFromTemplateForm["childFlows"] = [];

      if (flowVersion.data.child_versions) {
        for (const childVersion of flowVersion.data.child_versions) {
          const childFlow = flowsResult.data?.find((flow) =>
            flow.versions.some((version) => version.id === childVersion.id),
          );
          const alreadyFoundChild = childFlows.find(
            (childFlowFound) => childFlowFound.id === childFlow?.id,
          );
          if (!alreadyFoundChild) {
            childFlows.push({
              id: childFlow?.id ?? "",
              versionIds: [childVersion.id],
              name: childFlow?.name ?? "",
              slug: childFlow?.slug ?? "",
            });
          } else {
            alreadyFoundChild.versionIds.push(childVersion.id);
          }
        }
        setValue("childFlows", childFlows);
      }
    }
  }, [flowVersion.data, flowsResult.data, setValue]);

  return (
    <Modal
      className="w-120"
      open={isOpen}
      subtitle="Choose the Decision Flow and version you want to duplicate"
      title="Create Decision Flow from a template"
      onClose={handleClose}
    >
      <form>
        <div className="mx-6 my-4 max-h-[70vh] overflow-y-scroll">
          <FormProvider {...formMethods}>
            <SelectOrgAndWorkspace orgs={orgs} />
            <SelectFlowAndVersion flowsResult={flowsResult} index={null} />
            <NameInput
              error={errors.name}
              formProps={register("name", {
                required: true,
                validate: nameValidations,
                onChange: (e) => {
                  setValue(
                    "slug",
                    slugify(e.target.value, { strict: true, lower: true }),
                    {
                      shouldValidate: true,
                    },
                  );
                },
              })}
            />
            <SlugInput
              error={errors.slug}
              formProps={register("slug", {
                required: true,
                minLength: 4,
                maxLength: 20,
                validate: slugValidations,
              })}
            />
            {fields.length > 0 && (
              <div className="border-t border-gray-200 pt-6">
                {fields.map((field, index) => (
                  <div key={field.id}>
                    <p className="pb-3 text-gray-600 font-inter-normal-13px">
                      Child flow {index + 1}
                    </p>
                    <SelectFlowAndVersion
                      flowsResult={flowsResult}
                      index={index}
                    />
                    <NameInput
                      dataLoc={`add-flow-name-child-${index}`}
                      error={errors.childFlows?.[index]?.name}
                      formProps={register(`childFlows.${index}.name`, {
                        required: true,
                        validate: nameValidations,
                        onChange: (e) => {
                          setValue(
                            `childFlows.${index}.slug`,
                            slugify(e.target.value, {
                              strict: true,
                              lower: true,
                            }),
                            {
                              shouldValidate: true,
                            },
                          );
                        },
                      })}
                    />
                    <SlugInput
                      dataLoc={`add-flow-slug-child-${index}`}
                      error={errors.childFlows?.[index]?.slug}
                      formProps={register(`childFlows.${index}.slug`, {
                        required: true,
                        minLength: 4,
                        maxLength: 20,
                        validate: slugValidations,
                      })}
                    />
                  </div>
                ))}
              </div>
            )}
          </FormProvider>
        </div>
        <div className="mb-6 flex flex-row items-center justify-end gap-x-2 border-t border-gray-200 pr-6 pt-6 text-right">
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            dataLoc="add-flow-save"
            disabled={!isEmpty(errors)}
            htmlType="submit"
            loading={isSubmitting}
            variant="primary"
            onClick={onSubmit}
          >
            Create
          </Button>
        </div>
      </form>
    </Modal>
  );
};
