import {
  faSearch,
  faArrowTurnDownLeft,
} from "@fortawesome/pro-regular-svg-icons";
import { Dialog, DialogPanel } from "@headlessui2/react";
import React from "react";
import { useEffect, useRef, useState } from "react";
import { twJoin } from "tailwind-merge";
import { useDebounceCallback } from "usehooks-ts";

import { EmptyState } from "src/base-components/EmptyState";
import { Icon } from "src/base-components/Icon";
import { useFuseSearch } from "src/utils/useFuseSearch";

export type MenuItem = {
  icon?: React.ReactNode;
  label: string | React.ReactNode;
  type: string;
  subType?: string;
  value: string;
  details: string;
  onSelect?: (query: string) => void;
  pattern?: RegExp;
  disabled?: boolean;
};

interface GroupedMenu {
  [key: string]: MenuItem[];
}

type OmniboxBaseProps = {
  open: boolean;
  menuItems: MenuItem[];
  onClose: () => void;
  onQuery?: (query: string) => void;
  placeholder: string;
};

export const OmniboxBase: React.FC<OmniboxBaseProps> = ({
  open,
  menuItems,
  placeholder,
  onClose,
  onQuery,
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [query, setQuery] = useState("");
  const [focused, setFocused] = useState(0);

  // Debounced function to handle input changes
  const handleInputChange = useDebounceCallback((value: string) => {
    setQuery(value);
    onQuery?.(value);
  }, 50);

  const search = useFuseSearch(menuItems, {
    keys: ["label", "details", "type"],
    shouldSort: true,
    threshold: 0.2,
  });

  const filteredMenu = search(query);

  // Update focused item index when filtered menu changes
  useEffect(() => {
    setFocused(Math.max(Math.min(focused, filteredMenu.length - 1), 0));
  }, [filteredMenu.length, focused]);

  // Group filtered menu items by type
  const groupedMenu: GroupedMenu = filteredMenu.reduce<GroupedMenu>(
    (grouped, item) => {
      if (!grouped[item.type]) {
        grouped[item.type] = [];
      }
      grouped[item.type].push(item);
      return grouped;
    },
    {} as GroupedMenu,
  );

  // Flatten grouped menu items without section metadata
  const flatMenuItems: MenuItem[] = Object.values(groupedMenu).flatMap(
    (items) => items,
  );

  // Handle key events
  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    switch (event.key) {
      case "ArrowDown":
        setFocused((prev) => (prev + 1) % flatMenuItems.length);
        break;
      case "ArrowUp":
        setFocused(
          (prev) => (prev - 1 + flatMenuItems.length) % flatMenuItems.length,
        );
        break;
      case "Enter":
        flatMenuItems[focused]?.onSelect?.(query);
        onClose(); // Close the dialog after selection
        break;
      default:
        break;
    }
  };

  return (
    <Dialog
      className="relative z-50"
      open={open}
      autoFocus
      onClose={onClose}
      onTransitionEnd={() => {
        if (open) {
          inputRef.current?.focus();
        }
      }}
    >
      <div className="fixed inset-0 justify-center p-4">
        <DialogPanel
          className="mx-auto mt-48 flex w-150 max-w-lg flex-col rounded-lg bg-white pt-4 shadow-2xl ring-1 ring-gray-100 ring-opacity-50 transition duration-100 ease-out data-[closed]:scale-90 data-[closed]:opacity-0"
          transition
        >
          <div className="flex gap-2 px-4">
            <Icon color="text-gray-400" icon={faSearch} />
            <input
              ref={inputRef}
              className="w-full bg-transparent text-gray-900 outline-none font-inter-normal-13px placeholder:text-gray-400"
              placeholder={placeholder}
              spellCheck="false"
              onBlur={() => null}
              onChange={(event) => handleInputChange(event.target.value)}
              onKeyDown={handleKeyDown}
            />
          </div>
          <div className="mt-3 h-px w-full bg-gray-200 bg-opacity-50"></div>
          {Object.keys(groupedMenu).some(
            (key) => groupedMenu[key].length > 0,
          ) ? (
            <div className="decideScrollbar max-h-[23.5rem] px-2 pb-2 pt-2">
              {Object.keys(groupedMenu).map(
                (type) =>
                  groupedMenu[type].length > 0 && (
                    <div key={type}>
                      <p className="mt-1 px-2 py-2 font-bold text-gray-600 font-inter-medium-11px">
                        {type}s
                      </p>
                      {groupedMenu[type].map((item, _) => {
                        const globalIndex = flatMenuItems.findIndex(
                          (i) => i.value === item.value,
                        );
                        return (
                          <OmniboxItem
                            key={item.value}
                            disabled={item.disabled}
                            focused={focused === globalIndex}
                            onClick={(e) => {
                              if (item && item.onSelect && !item.disabled) {
                                item.onSelect(query);
                                if (!e.metaKey) {
                                  onClose();
                                }
                              }
                            }}
                            onMouseMove={() => setFocused(globalIndex)}
                            {...item}
                          />
                        );
                      })}
                    </div>
                  ),
              )}
            </div>
          ) : (
            <EmptyState
              description="No items match your search or selection criteria."
              headline="No Results Found"
              icon={faSearch}
            />
          )}
        </DialogPanel>
      </div>
    </Dialog>
  );
};

const OmniboxItem: React.FC<
  {
    focused?: boolean;
    onClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    onMouseMove: () => void;
  } & MenuItem
> = ({
  focused,
  value,
  icon,
  type,
  label,
  subType,
  disabled,
  onClick,
  onMouseMove,
}) => {
  const ref = useRef<HTMLButtonElement>(null);
  useEffect(() => {
    if (focused) {
      ref.current?.scrollIntoView({ behavior: "smooth", block: "nearest" });
    }
  }, [focused, value]);

  return (
    <button
      key={value}
      ref={ref}
      className={twJoin(
        "flex w-full items-center justify-between gap-4 overflow-hidden rounded-md p-2 text-gray-900",
        focused && !disabled && "bg-gray-100 bg-opacity-80 text-gray-900",
        disabled && "cursor-not-allowed opacity-40",
      )}
      disabled={disabled}
      onClick={onClick}
      onMouseMove={onMouseMove}
    >
      <div className="flex w-full items-center gap-x-2">
        {icon && <div className="h-6 w-6 shrink-0">{icon}</div>}
        <p className="flex-grow overflow-hidden text-ellipsis whitespace-nowrap text-left font-inter-medium-13px">
          {label}
        </p>
      </div>
      <div className="flex h-auto items-center">
        {focused && !disabled ? (
          <Icon color="text-gray-400" icon={faArrowTurnDownLeft} size="xs" />
        ) : (
          <p className="w-20 shrink-0 text-right text-gray-400 font-inter-normal-12px">
            {subType ? subType : type}
          </p>
        )}
      </div>
    </button>
  );
};
