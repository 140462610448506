import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { AxiosResponse } from "axios";

import {
  ConnectionsEndpoint,
  ProviderEndpoint,
  ManifestConnectionsEndpoint,
  ManifestsEndpoint,
  ResourceConfigsEndpoint,
} from "src/api/connectApi/endpoints";
import { ManifestIntegrationProvider } from "src/api/connectApi/manifestTypes";
import {
  ConnectionCreateT,
  ConnectionT,
  ResourceConfigT,
} from "src/api/connectApi/types";
import { NEVER_REFETCH_OPTIONS } from "src/api/queries";
import { ManifestConnectionT } from "src/connections/config/manifest/types";
import { isManualReviewNodeConnection } from "src/connections/model/inboundWebhook";
import { queryClient } from "src/queryClient";
import { shouldShowInternalResources } from "src/router/featureFlags";

export const useConnection = ({
  baseUrl,
  id,
  neverRefetch = false,
}: {
  baseUrl: string | undefined;
  id: string | undefined;
  neverRefetch?: boolean;
}) => {
  return useQuery<ConnectionT, Error>(
    ["connections", baseUrl, id],
    () => {
      if (baseUrl == null || id == null) {
        throw new Error("Invalid baseUrl or id");
      }
      return ConnectionsEndpoint.get(baseUrl, id);
    },
    {
      enabled: Boolean(baseUrl) && Boolean(id),
      ...(neverRefetch && NEVER_REFETCH_OPTIONS),
    },
  );
};

const setManualReviewConnectionFirst = (data: ConnectionT[]) => {
  const manualReviewConnection = data.find(isManualReviewNodeConnection);

  if (!manualReviewConnection) {
    return data;
  }

  return [
    manualReviewConnection,
    ...data.filter((connection) => connection.id !== manualReviewConnection.id),
  ];
};

const hideManualReviewConnection = (data: ConnectionT[]) => {
  return data.filter((connection) => !isManualReviewNodeConnection(connection));
};

type ConnectionOptions = {
  /**
   * We don't want to show manual review connection
   * in the list of connections on connections page
   */
  hideManualReviewConnection?: boolean;
};

export const useConnections = (
  baseUrl?: string,
  options?: ConnectionOptions,
) => {
  return useQuery<ConnectionT[], Error>(
    ["connections", baseUrl],
    () =>
      ConnectionsEndpoint.all(baseUrl, {
        include_internal_connections: shouldShowInternalResources(),
      }),
    {
      enabled: Boolean(baseUrl),
      select: options?.hideManualReviewConnection
        ? hideManualReviewConnection
        : setManualReviewConnectionFirst,
    },
  );
};

export const prefetchConnections = (baseUrl: string) => {
  return queryClient.prefetchQuery(
    ["connections", baseUrl],
    () =>
      ConnectionsEndpoint.all(baseUrl, {
        include_internal_connections: shouldShowInternalResources(),
      }),
    { cacheTime: Infinity },
  );
};

export const useResourceConfig = (
  baseUrl: string,
  connectionId: string,
  resourceConfigId: string,
) => {
  return useQuery<ResourceConfigT, Error>(
    ["resourceconfigs", connectionId, resourceConfigId],
    async () => {
      return ResourceConfigsEndpoint.get(
        baseUrl,
        connectionId,
        resourceConfigId,
      );
    },
  );
};

export const useCreateConnection = (baseUrl: string | undefined) => {
  const queryClient = useQueryClient();
  return useMutation<ConnectionT, Error, ConnectionCreateT>(
    (data) => ConnectionsEndpoint.create(baseUrl, data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["connections", baseUrl]);
      },
    },
  );
};

export const useCreateConnectionAndResources = (baseUrl: string) => {
  const queryClient = useQueryClient();
  return useMutation<
    Awaited<
      ReturnType<(typeof ConnectionsEndpoint)["createConnectionAndResources"]>
    >,
    Error,
    Parameters<(typeof ConnectionsEndpoint)["createConnectionAndResources"]>[1]
  >((data) => ConnectionsEndpoint.createConnectionAndResources(baseUrl, data), {
    onSuccess: () => {
      queryClient.invalidateQueries(["connections", baseUrl]);
    },
  });
};

type EditParams = {
  id: string;
  payload: ConnectionCreateT;
};

export const useEditConnection = (
  baseUrl: string | undefined,
  id: string | undefined,
) => {
  const queryClient = useQueryClient();
  return useMutation<ConnectionT, Error, EditParams>(
    ({ id, payload }) => ConnectionsEndpoint.update(baseUrl, id, payload),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["connections", baseUrl]);
        queryClient.invalidateQueries(["connections", baseUrl, id]);
      },
    },
  );
};

type EditConnectionAndResourcesParams = {
  id: string;
  payload: Parameters<
    (typeof ConnectionsEndpoint)["updateConnectionAndResources"]
  >[2];
};

export const useEditConnectionAndResources = (baseUrl: string) => {
  const queryClient = useQueryClient();
  return useMutation<
    Awaited<
      ReturnType<(typeof ConnectionsEndpoint)["updateConnectionAndResources"]>
    >,
    Error,
    EditConnectionAndResourcesParams
  >(
    ({ id, payload }) =>
      ConnectionsEndpoint.updateConnectionAndResources(baseUrl, id, payload),
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries(["connections", baseUrl]);
        queryClient.invalidateQueries(["connections", baseUrl, data.id]);
      },
    },
  );
};

export const useDeleteConnection = (baseUrl: string | undefined) => {
  const queryClient = useQueryClient();
  return useMutation(
    (connectionId: string) => ConnectionsEndpoint.delete(baseUrl, connectionId),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["connections", baseUrl]);
      },
    },
  );
};

type ProbeResponse = {
  connectionId?: string;
  status: AxiosResponse["status"];
};

export const useConnectionStatus = (
  baseUrl: string | undefined,
  connection: ConnectionT | undefined,
  {
    onTestPass,
    onTestFail,
  }: {
    onTestPass?: (connection?: ConnectionT) => void;
    onTestFail?: (connection?: ConnectionT, error?: any) => void;
  } = {},
) => {
  return useQuery<ProbeResponse>({
    queryKey: ["probe", baseUrl, connection?.id],
    queryFn: async () => {
      const result = await ConnectionsEndpoint.test(baseUrl, connection?.id);
      return { status: result.status };
    },
    enabled: Boolean(baseUrl) && Boolean(connection),
    onSuccess: () => onTestPass?.(connection),
    onError: (error) => onTestFail?.(connection, error),
    retry: false,
  });
};

export const useRetoolEmbed = (
  baseUrl: string | undefined,
  connection: ConnectionT | undefined,
  userId: string | undefined,
  email: string | undefined,
) => {
  return useQuery<any, Error>(
    ["retool", "retool_embed", baseUrl],
    () =>
      ProviderEndpoint.query(baseUrl, "retool", "retool_embed", {
        provider_args: { max_age_seconds: 0 },
        query: { user_id: userId, email: email },
        resource_config_id: connection?.resource_configs.find(
          (rc) => rc.resource === "retool_embed",
        )?.id,
      }),
    {
      enabled:
        Boolean(baseUrl) &&
        Boolean(connection) &&
        Boolean(userId) &&
        Boolean(email),
    },
  );
};
export const useManifests = (baseUrl: string | undefined) => {
  return useQuery<ManifestIntegrationProvider[], Error>(
    ["/manifests?latest=true", baseUrl],
    () => ManifestsEndpoint.all(baseUrl),
    {
      enabled: Boolean(baseUrl),
    },
  );
};

export const useProviderManifest = (
  baseUrl: string | undefined,
  provider: string | undefined,
  manifest_version: string | undefined,
) => {
  return useQuery<ManifestIntegrationProvider, Error>(
    ["/manifests", baseUrl, provider, manifest_version],
    () => ManifestsEndpoint.get(provider as string, manifest_version, baseUrl),
    {
      enabled:
        Boolean(baseUrl) && Boolean(provider) && Boolean(manifest_version),
    },
  );
};

export const useCreateManifestConnection = (baseUrl: string | undefined) => {
  const queryClient = useQueryClient();
  return useMutation<ConnectionT, Error, ManifestConnectionT>(
    (data) => ManifestConnectionsEndpoint.create(baseUrl, data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["connections", baseUrl]);
      },
    },
  );
};

type EditManifestConnectionParams = {
  connectionId: string;
  payload: ManifestConnectionT;
};

export const useUpdateManifestConnection = (baseUrl: string | undefined) => {
  const queryClient = useQueryClient();
  return useMutation<ConnectionT, Error, EditManifestConnectionParams>(
    ({ connectionId, payload }) =>
      ManifestConnectionsEndpoint.update(baseUrl, connectionId, payload),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["connections", baseUrl]);
      },
    },
  );
};
