import { PROVIDED_ID_NODE_A_NODE_ID } from "src/store/GraphStore";
import { useGraphStore } from "src/store/StoreProvider";
import { useNodeRunStates } from "src/store/runState/RunState";
import * as logger from "src/utils/logger";

export type BranchAnalytics = {
  processed: number;
  relativePercentage: number;
  absolutePercentage: number;
};

export const useBranchAnalytics = (id: string): BranchAnalytics | undefined => {
  const runStates = useNodeRunStates();
  const { findParentSplitNode, inputNode } = useGraphStore();

  const runState = runStates?.get(id);
  const parentSplitNode = findParentSplitNode(id);

  if (parentSplitNode === PROVIDED_ID_NODE_A_NODE_ID) {
    // This can happen because graph store and rendered nodes are out of sync for a render cycle when switching versions.
    // We don't want to spam the console in that case. Let's aim to fix when removing mobX.
    return;
  }
  if (!parentSplitNode) {
    logger.error("No parent Split Node found for node", id);
    return;
  }

  const parentRunState = runStates?.get(parentSplitNode?.id ?? "");
  const inputRunState = runStates?.get(inputNode?.id ?? "");

  if (
    runState?.type === "test-run" &&
    parentRunState?.type === "test-run" &&
    inputRunState?.type === "test-run"
  ) {
    const branchNodeTotalCount = runState.testResult?.total_count ?? 0;
    const parentSplitNodeTotalCount =
      parentRunState.testResult?.total_count ?? 0;
    const overallTotalCount = inputRunState.testResult?.total_count ?? 0;

    return {
      processed: branchNodeTotalCount,
      relativePercentage:
        (branchNodeTotalCount / parentSplitNodeTotalCount) * 100,
      absolutePercentage: (branchNodeTotalCount / overallTotalCount) * 100,
    };
  }

  const thereIsTestRunData = Array.from(runStates.values()).some(
    (runState) => runState.type === "test-run",
  );

  if (thereIsTestRunData) {
    return {
      processed: 0,
      relativePercentage: 0,
      absolutePercentage: 0,
    };
  }
};
