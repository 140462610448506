import { isEmpty } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useCallback } from "react";
import { twJoin } from "tailwind-merge";

import { useNodeResultData } from "src/api/decisionHistoryV2/decisionHistoryQueries";
import { FlowT, FlowVersionT } from "src/api/flowTypes";
import { useFlow } from "src/api/queries";
import { DataRow, DecisionHistoryError, ErroredRow } from "src/api/types";
import { LOCK_NODE_EDITOR_CLASSNAME } from "src/authoringMultiplayerLock/constants";
import { useLockableChildFocused } from "src/authoringMultiplayerLock/useLockableChildFocused";
import { useAcquireVersionResourceLock } from "src/authoringMultiplayerLock/useVersionResourceLock";
import { useIsFloatingWindowPinned } from "src/base-components/FloatingWindow/hooks";
import {
  SearchParamsTabView,
  SearchParamsComponentT,
} from "src/base-components/SearchParamsTabView";
import { ResourceType, WorkspaceDataplane } from "src/clients/flow-api";
import { WorkspaceSimpleRoleType } from "src/clients/taktile-api";
import { NodeComments } from "src/comments/NodeComments";
import { useCommentsSummary } from "src/comments/queries";
import {
  BeMappedNode,
  DatabaseConnectionNode,
  FlowNodeDataT,
  LoopNode,
  NodeHistoricalRunStateV2,
} from "src/constants/NodeDataTypes";
import { FULLSCREEN_NODE_TYPES, NODE_TYPE } from "src/constants/NodeTypes";
import { useQueryResult } from "src/dataTable/DetailedView/DetailedView";
import { ExecutedQuery } from "src/dataTable/DetailedView/tabs/ExecuteQuery";
import { ExecutionResult } from "src/dataTable/DetailedView/tabs/ExecutionResult";
import { LoopIterations } from "src/dataTable/DetailedView/tabs/LoopIterations";
import { DETAILED_VIEW_ID } from "src/dataTable/TableUtils";
import { getFieldErrors } from "src/databaseDebugPopover/BaseDebugPopover";
import { useAuthoringUIActions } from "src/flowContainer/AuthoringUIContext";
import { useRightPane } from "src/flowContainer/hooks/useRightPane";
import { isInputOrOutputNode } from "src/flowGraph/utils";
import { useCanAuthoringEditFlowVersion } from "src/hooks/useCanAuthoringEditFlowVersion";
import { DownloadDataIcon } from "src/nodeEditor/DownloadDataIcon";
import { InsightsPane } from "src/nodeEditor/InsightsPane";
import { InspectDataPanelV2 } from "src/nodeEditor/InspectDataPanelV2";
import { NodeEditor } from "src/nodeEditor/NodeEditor";
import { SelectFlowPane } from "src/nodeEditor/SelectFlowPane/SelectFlowPane";
import { SidePaneHeader } from "src/nodeEditor/SidePaneHeader";
import { SidePaneNodeEditorContainer } from "src/nodeEditor/SidePaneNodeEditorContainer";
import {
  NodeEditorOptions,
  URLKeys,
  useOpenSchemaEditor,
} from "src/router/SearchParams";
import { useAuthoringContext } from "src/router/routerContextHooks";
import { AuthorPageParamsT } from "src/router/urls";
import { useSimpleRole } from "src/store/SimpleRoleProvider";
import { useGraphStore } from "src/store/StoreProvider";
import { useNodeRunState } from "src/store/runState/RunState";
import { MAX_DISPLAY_COUNT } from "src/utils/constants";
import { isFlowNode, isHistoricalRunState } from "src/utils/predicates";
import { getMaximumDisplayedCount } from "src/utils/stringUtils";
import { useParamsDecode } from "src/utils/useParamsDecode";

type NodeDetailsSidePaneProps = {
  workspace: WorkspaceDataplane;
  flow: FlowT;
  onToggleFullscreen: () => void;
  isFullscreen: boolean;
  wrapperRef: React.RefObject<HTMLDivElement>;
  hasLockableFocus: boolean;
};

const isFullScreenEnabledForNode = (nodeType: NODE_TYPE | undefined) => {
  return nodeType ? FULLSCREEN_NODE_TYPES.includes(nodeType) : false;
};

const LoopIterationsDetailsTab: React.FC<{
  node: LoopNode;
  runState: NodeHistoricalRunStateV2;
}> = ({ node, runState }) => {
  const { workspace } = useAuthoringContext();
  const nodeResultDataQuery = useNodeResultData({
    baseUrl: workspace.base_url!!,
    decisionId: runState && "decisionId" in runState ? runState.decisionId : "",
    nodeId: node?.id ?? "",
  });
  let tableData: DataRow = {};
  let error: DecisionHistoryError | undefined = undefined;

  if (nodeResultDataQuery.data) {
    tableData = nodeResultDataQuery.data;
  }

  if (runState?.type === "historical-error") {
    error = runState.error;
    tableData = isEmpty(tableData) ? runState?.error?.data : tableData;
  }

  return (
    <div className="px-6 pb-4">
      <LoopIterations
        data={tableData}
        error={error}
        isFetching={nodeResultDataQuery.isLoading}
        selectedNodeId={node.id}
      />
    </div>
  );
};

const ExecutedQueryDetailsTab: React.FC<{
  node: DatabaseConnectionNode;
  runState: NodeHistoricalRunStateV2;
}> = ({ node, runState }) => {
  const { workspace } = useAuthoringContext();
  const nodeResultDataQuery = useNodeResultData({
    baseUrl: workspace.base_url!!,
    decisionId: runState && "decisionId" in runState ? runState.decisionId : "",
    nodeId: node?.id ?? "",
  });
  let tableData: DataRow = {};
  let error: DecisionHistoryError | undefined = undefined;

  if (nodeResultDataQuery.data && runState?.type === "historical-data") {
    tableData = nodeResultDataQuery.data;
  }
  if (runState?.type === "historical-error") {
    error = runState.error;
    tableData = isEmpty(tableData) ? runState?.error?.data : tableData;
  }

  const isError = error && error.node_id === node.id;

  const { fieldErrors } = useQueryResult({
    error: isError ? error : undefined,
    data: tableData,
    nodeId: node.id,
  });

  return (
    <div className="px-6 pb-4">
      <ExecutedQuery
        fieldErrors={fieldErrors}
        isFetching={nodeResultDataQuery.isLoading}
        node={node}
        nodeExecutionMetadata={runState.nodeExecutionMetadata}
      />
    </div>
  );
};

const ExecutionResultDetailsTab: React.FC<{
  node: DatabaseConnectionNode;
  runState: NodeHistoricalRunStateV2;
}> = ({ node, runState }) => {
  const { workspace } = useAuthoringContext();
  const nodeResultDataQuery = useNodeResultData({
    baseUrl: workspace.base_url!!,
    decisionId: runState && "decisionId" in runState ? runState.decisionId : "",
    nodeId: node?.id ?? "",
  });
  let tableData: DataRow = {};
  let error: DecisionHistoryError | undefined = undefined;

  if (nodeResultDataQuery.data && runState?.type === "historical-data") {
    tableData = nodeResultDataQuery.data;
  }
  if (runState?.type === "historical-error") {
    error = runState.error;
    tableData = isEmpty(tableData) ? runState?.error?.data : tableData;
  }

  const isError = error && error.node_id === node.id;

  const { result, resultStatus } = useQueryResult({
    error: isError ? error : undefined,
    data: tableData,
    nodeId: node.id,
  });

  return (
    <div className="px-6 pb-4">
      <ExecutionResult result={result} status={resultStatus} />
    </div>
  );
};

const NodeDetailsSidePane: React.FC<NodeDetailsSidePaneProps> = observer(
  ({
    workspace,
    flow,
    onToggleFullscreen,
    isFullscreen,
    hasLockableFocus,
    wrapperRef,
  }) => {
    const { workspaceRole } = useSimpleRole();
    const isDetailedViewPinned = useIsFloatingWindowPinned(DETAILED_VIEW_ID);
    const { setNodeEditorOpen } = useAuthoringUIActions();
    const { version_id } = useParamsDecode<AuthorPageParamsT>();

    const {
      selectedNode,
      setSelectedNode,
      setDisplayedErroredRow,
      inputNode,
      outputNode,
    } = useGraphStore();
    const openSchema = useOpenSchemaEditor();
    const { setRightPaneTab } = useRightPane();
    const runState = useNodeRunState(selectedNode?.id ?? "");
    const { data } = useCommentsSummary(version_id, selectedNode?.id);
    const commentCount = getMaximumDisplayedCount(data, MAX_DISPLAY_COUNT);

    const openEditor = useCallback(() => {
      setRightPaneTab(NodeEditorOptions.Logic);
    }, [setRightPaneTab]);

    const showErrorInEditor = useCallback(
      (error: ErroredRow) => {
        setDisplayedErroredRow(error);
        if (inputNode && error.node_id === inputNode.id) {
          openSchema(inputNode);
        } else if (outputNode && error.node_id === outputNode.id) {
          openSchema(outputNode);
        } else {
          openEditor();
        }
      },
      [setDisplayedErroredRow, inputNode, outputNode, openSchema, openEditor],
    );

    if (!selectedNode) {
      return null;
    }

    const getTabs = () => {
      const inspectDataTab: SearchParamsComponentT = {
        title: "Inspect data",
        component: (
          <InspectDataPanelV2
            openEditor={openEditor}
            showErrorInEditor={showErrorInEditor}
          />
        ),
        searchParamValue: NodeEditorOptions.Data,
        // If we have download data available from the full run endpoint, display the download dropdown
        icon: (
          <DownloadDataIcon
            selectedNode={selectedNode}
            workspaceUrl={workspace.base_url!}
          />
        ),
      };
      const logicTab: SearchParamsComponentT = {
        title: "Logic",
        component: (
          <NodeEditor
            flow={flow}
            hasLockableFocus={hasLockableFocus}
            isFullscreen={isFullscreen}
            workspace={workspace}
          />
        ),
        searchParamValue: NodeEditorOptions.Logic,
      };
      const commentsTab = {
        title: "Comments".concat(
          commentCount === undefined ? "" : ` (${commentCount})`,
        ),
        component: <NodeComments selectedNode={selectedNode} />,
        searchParamValue: NodeEditorOptions.Comments,
        dataLoc: "side-pane-comments-tab",
      };
      let tabs = [];
      if (!isInputOrOutputNode(selectedNode)) {
        tabs.push(logicTab);
      }
      if (workspaceRole !== WorkspaceSimpleRoleType.VIEWER) {
        tabs.push(inspectDataTab);
      }
      if (!isInputOrOutputNode(selectedNode)) {
        tabs.push({
          title: "Insights",
          component: <InsightsPane node={selectedNode} />,
          searchParamValue: NodeEditorOptions.Insights,
        });
      }
      if (
        selectedNode.type === NODE_TYPE.LOOP_NODE &&
        runState &&
        isHistoricalRunState(runState)
      ) {
        tabs.push({
          title: "Iterations",
          component: (
            <LoopIterationsDetailsTab
              node={selectedNode as LoopNode}
              runState={runState}
            />
          ),
          searchParamValue: NodeEditorOptions.Iterations,
        });
      }

      if (
        runState &&
        isHistoricalRunState(runState) &&
        selectedNode.type === NODE_TYPE.SQL_DATABASE_CONNECTION_NODE
      ) {
        tabs.push(
          {
            title: "Executed query",
            errored:
              runState.type === "historical-error" &&
              !!getFieldErrors(runState.error),
            component: (
              <ExecutedQueryDetailsTab
                node={selectedNode as DatabaseConnectionNode}
                runState={runState}
              />
            ),
            searchParamValue: NodeEditorOptions.ExecutedQuery,
          },
          {
            title: "SQL response",
            errored:
              runState.type === "historical-error" &&
              runState.error &&
              !getFieldErrors(runState.error),
            component: (
              <ExecutionResultDetailsTab
                node={selectedNode as DatabaseConnectionNode}
                runState={runState}
              />
            ),
            searchParamValue: NodeEditorOptions.SqlResponse,
          },
        );
      }
      tabs.push(commentsTab);

      return tabs;
    };

    return (
      <div
        ref={wrapperRef}
        className={twJoin(
          "flex h-full flex-col",
          isFullscreen && "min-h-[50vh]",
        )}
      >
        <SidePaneHeader
          isFullscreen={isFullscreen}
          selectedNode={selectedNode as BeMappedNode}
          onCloseButtonClick={() => {
            if (isFullscreen) {
              onToggleFullscreen();
            } else {
              setNodeEditorOpen(false);

              if (!isDetailedViewPinned) {
                setSelectedNode(null);
              }
            }
          }}
          onToggleFullscreen={
            isFullScreenEnabledForNode(selectedNode.type as NODE_TYPE)
              ? onToggleFullscreen
              : undefined
          }
        />
        <div className="mb-6 mt-1 min-h-0 flex-1">
          <SearchParamsTabView
            key={selectedNode.id}
            componentClassName="flex flex-col"
            defaultValue={
              selectedNode.type === NODE_TYPE.INPUT_NODE ||
              selectedNode.type === NODE_TYPE.OUTPUT_NODE
                ? NodeEditorOptions.Data
                : NodeEditorOptions.Logic
            }
            searchParamKey={URLKeys.RightPaneTab}
            tabs={getTabs()}
          />
        </div>
      </div>
    );
  },
);

export const NodeDetailsSidePaneWrapper: React.FC = observer(() => {
  const { workspace, flow } = useAuthoringContext();
  const { selectedNode, setSelectedNode, updateNode } = useGraphStore();
  const flowId =
    selectedNode && isFlowNode(selectedNode)
      ? (selectedNode.data.child_flow_id ?? "")
      : "";
  const childFlowQuery = useFlow(flowId, { refetchOnMount: true });

  const sidePaneRef = React.useRef<HTMLDivElement>(null);
  const fullScreenRef = React.useRef<HTMLDivElement>(null);

  const nodeDetailsHasLockableFocus = useLockableChildFocused({
    considerMarkerClass: LOCK_NODE_EDITOR_CLASSNAME,
  });

  const canEditFlowVersion = useCanAuthoringEditFlowVersion();

  useAcquireVersionResourceLock(
    ResourceType.NODE,
    selectedNode?.id,
    nodeDetailsHasLockableFocus && canEditFlowVersion,
  );

  const handleFlowSelect = (flow: FlowT, flowVersion: FlowVersionT) => {
    if (!selectedNode) {
      return;
    }

    updateNode<FlowNodeDataT>({
      nodeId: selectedNode.id,
      newName: flow.name,
      newData: {
        child_flow_id: flow.id,
        child_flow_version_id: flowVersion.id,
        child_flow_slug: flow.slug,
        child_flow_version_known_etag: flowVersion.etag,
        is_stale: false,
      },
    });
  };

  if (selectedNode && isFlowNode(selectedNode)) {
    const childFlowVersionId = selectedNode.data.child_flow_version_id;
    const hasMissingChild = (() => {
      if (childFlowQuery.isLoading) {
        return false;
      }

      if (!childFlowQuery.data && childFlowQuery.isError) {
        return true;
      }

      const childFlow = childFlowQuery.data;
      const childFlowVersion = childFlow.versions.find(
        (version) => version.id === childFlowVersionId,
      );

      return !childFlowVersion;
    })();

    if (!childFlowVersionId || hasMissingChild) {
      return (
        <SelectFlowPane
          node={selectedNode}
          onClose={() => setSelectedNode(null)}
          onSelect={handleFlowSelect}
        />
      );
    }
  }

  return (
    <SidePaneNodeEditorContainer
      fullScreenRef={fullScreenRef}
      sidePaneRef={sidePaneRef}
    >
      {({ toggleFullscreen, isFullscreen, wrapperRef }) => (
        <NodeDetailsSidePane
          flow={flow}
          hasLockableFocus={nodeDetailsHasLockableFocus}
          isFullscreen={isFullscreen}
          workspace={workspace}
          wrapperRef={wrapperRef}
          onToggleFullscreen={toggleFullscreen}
        />
      )}
    </SidePaneNodeEditorContainer>
  );
});
