import {
  faClose,
  faSearch,
  faSliders,
} from "@fortawesome/pro-regular-svg-icons";
import {
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
} from "@headlessui2/react";
import { Fragment, useEffect, useMemo, useState } from "react";
import { twJoin } from "tailwind-merge";

import { FlowVersionT } from "src/api/flowTypes";
import { Button } from "src/base-components/Button";
import { Checkbox } from "src/base-components/Checkbox";
import { EmptyState } from "src/base-components/EmptyState";
import { Icon } from "src/base-components/Icon";
import { Input } from "src/base-components/Input";
import { Tooltip } from "src/base-components/Tooltip";
import { transformSchemaToOptions } from "src/decisionsOverview/utils";
import { useFuseSearch } from "src/utils/useFuseSearch";

type Props = {
  flowVersions: FlowVersionT[];
  value: string[];
  onChange: (selected: string[]) => void;
};

const Divider = () => (
  <li className="my-2.5 list-none border-b border-b-gray-200" />
);

const SearchBox: React.FC<{ value: string; onChange: (v: string) => void }> = ({
  value,
  onChange,
}) => {
  // clear value on unmount
  useEffect(() => {
    return () => onChange("");
  }, [onChange]);

  const isEmpty = value === "";

  return (
    <li className="list-none px-4 py-2">
      <Input
        placeholder="Search fields"
        prefixIcon={{ icon: faSearch }}
        suffixIcon={
          isEmpty ? undefined : { icon: faClose, onClick: () => onChange("") }
        }
        value={value}
        fullWidth
        onChange={(e) => onChange(e.target.value)}
        onKeyDown={(e) => e.stopPropagation()}
      />
    </li>
  );
};

const HeaderItem: React.FC<{
  selected: number;
  total: number;
  title: string;
}> = ({ selected, total, title }) => (
  <>
    <Divider />
    <ListboxOption
      as="li"
      className="flex items-center justify-between gap-x-2 px-4 py-2.5"
      value="header"
      disabled
    >
      <span className="text-gray-800 font-inter-semibold-13px">{title}</span>
      <span className="text-gray-400 font-inter-normal-13px">
        {selected}/{total}
      </span>
    </ListboxOption>
  </>
);

const Item: React.FC<{
  label: string;
  value: string;
}> = ({ label, value }) => (
  <ListboxOption
    as="li"
    className={({ focus }) =>
      twJoin(
        "flex cursor-pointer items-center gap-x-2 overflow-hidden px-4 py-2.5",
        focus && "bg-gray-50",
      )
    }
    value={value}
  >
    {({ selected }) => (
      <>
        <Checkbox checked={selected} />
        <span className="overflow-hidden text-ellipsis text-gray-800 font-inter-normal-13px">
          {label}
        </span>
      </>
    )}
  </ListboxOption>
);

const Footer: React.FC<{ onSave?: () => void; onReset?: () => void }> = ({
  onSave,
  onReset,
}) => (
  <li className="sticky bottom-0 mt-2.5 flex w-full list-none items-center justify-between border-t border-t-gray-200 bg-white px-4 pb-4 pt-4.5">
    <Button disabled={!onReset} size="sm" variant="secondary" onClick={onReset}>
      Reset
    </Button>
    <Button disabled={!onSave} size="sm" variant="primary" onClick={onSave}>
      Save for everyone
    </Button>
  </li>
);

const decisionItems = [
  { value: "decision.decision_id", label: "Decision ID" },
  { value: "decision.entity_id", label: "Entity ID" },
  { value: "decision.time", label: "Time" },
  { value: "decision.status", label: "Status" },
  { value: "decision.origin", label: "Origin" },
  { value: "decision.version", label: "Version" },
  { value: "decision.duration", label: "Duration" },
];

const resetValue = [
  "decision.decision_id",
  "decision.entity_id",
  "decision.time",
  "decision.status",
  "decision.origin",
  "decision.version",
  "decision.duration",
];

export const ColumnSelector: React.FC<Props> = ({
  flowVersions,
  value,
  onChange,
}) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [inputItems, outputItems] = useMemo(
    () => [
      transformSchemaToOptions(flowVersions, "input"),
      transformSchemaToOptions(flowVersions, "output"),
    ],
    [flowVersions],
  );
  const allItems = useMemo(
    () => [...decisionItems, ...inputItems, ...outputItems],
    [inputItems, outputItems],
  );

  const [inputSelectedCount, outputSelectedCount] = [
    value.filter((s) => s.startsWith("input.")).length,
    value.filter((s) => s.startsWith("output.")).length,
  ];

  const filter = useFuseSearch(allItems, {
    threshold: 0.5,
    keys: ["label"],
  });

  const filteredItems = searchQuery ? filter(searchQuery) : allItems;
  const [filteredDecisionItems, filteredInputItems, filteredOutputItems] = [
    filteredItems.filter((item) => item.value.startsWith("decision.")),
    filteredItems.filter((item) => item.value.startsWith("input.")),
    filteredItems.filter((item) => item.value.startsWith("output.")),
  ];

  return (
    <Listbox value={value} multiple onChange={onChange}>
      {({ open }) => (
        <>
          <Tooltip placement="top" title="View options" asChild>
            <ListboxButton className={twJoin("group", open && "is-open")}>
              <Icon
                color="text-gray-500 group-hover:text-gray-800 group-[.is-open]:text-gray-800"
                icon={faSliders}
                size="xs"
              />
            </ListboxButton>
          </Tooltip>
          <ListboxOptions
            anchor="bottom end"
            className="relative z-10 min-h-[156px] w-70 origin-top overflow-hidden rounded-lg bg-white pt-2 shadow-lg transition duration-200 ease-out data-[closed]:scale-95 data-[closed]:opacity-0"
            transition
            unmount
          >
            <SearchBox value={searchQuery} onChange={setSearchQuery} />
            {filteredItems.length === 0 && (
              <EmptyState
                description="Try searching for a different field"
                headline="No fields found"
                icon={faSearch}
              />
            )}

            {filteredDecisionItems.map(({ value: itemValue, label }) => (
              <Item key={itemValue} label={label} value={itemValue} />
            ))}
            {filteredInputItems.length > 0 && (
              <>
                <HeaderItem
                  selected={inputSelectedCount}
                  title="Input fields"
                  total={inputItems.length}
                />
                {filteredInputItems.map(({ value: itemValue, label }) => (
                  <Item key={itemValue} label={label} value={itemValue} />
                ))}
              </>
            )}
            {filteredOutputItems.length > 0 && (
              <>
                <HeaderItem
                  selected={outputSelectedCount}
                  title="Output fields"
                  total={outputItems.length}
                />
                {filteredOutputItems.map(({ value: itemValue, label }) => (
                  <Item key={itemValue} label={label} value={itemValue} />
                ))}
              </>
            )}
            <Footer
              onReset={searchQuery ? undefined : () => onChange(resetValue)}
            />
          </ListboxOptions>
        </>
      )}
    </Listbox>
  );
};
