import { faRefresh, faSearch } from "@fortawesome/pro-regular-svg-icons";
import { Root as AccordionRoot } from "@radix-ui/react-accordion";
import { useQueryClient } from "@tanstack/react-query";
import { range } from "lodash";
import { useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { twJoin } from "tailwind-merge";
import { useSessionStorage } from "usehooks-ts";

import {
  buildHistoryDecisionV2QueryKey,
  DecisionHistoryFiltersV2,
  DecisionHistoryRecordV2,
  NO_DECISION_FOUND,
  SingleDecisionEnvelope,
  useHistoryDecisionsV2,
  useHistoryDecisionV2,
} from "src/api/decisionHistoryV2/decisionHistoryQueries";
import { GroupFilter } from "src/api/endpoints";
import { FlowT, FlowVersionT, GenericObjectT } from "src/api/flowTypes";
import { useFlow, useFlows } from "src/api/queries";
import { DecisionEnvironment, DecisionSummaryResponse } from "src/api/types";
import { CopyTextIcon } from "src/base-components/CopyTextIcon";
import { EditorAccordionItem } from "src/base-components/EditorAccordionItem";
import { EmptyState } from "src/base-components/EmptyState";
import { EnvironmentPill } from "src/base-components/EnvironmentPill";
import { LoadingView } from "src/base-components/LoadingView";
import { Pill } from "src/base-components/Pill";
import { SkeletonPlaceholder } from "src/base-components/SkeletonPlaceholder";
import { ColumnSelector } from "src/decisionsOverview/ColumnSelector";
import { DecisionsSidepane } from "src/decisionsOverview/DecisionsSidepane";
import { useDecisionsOverview } from "src/decisionsOverview/queries";
import { SubHeader } from "src/flow/SubHeader";
import { DecisionHistoryTable } from "src/flow/decisionHistory/TableWrapper";
import { DashboardContent } from "src/layout/DashboardContent";
import { URLKeys } from "src/router/SearchParams";
import { useWorkspaceContext } from "src/router/routerContextHooks";
import { DashboardPageParamsT, getUrlToWsDashboard } from "src/router/urls";
import { pluralize } from "src/utils/stringUtils";
import { StrictDateRange, useTimeWindow } from "src/utils/timeWindow";
import { useParamsDecode } from "src/utils/useParamsDecode";

const useQueryParams = () => {
  const [searchParams] = useSearchParams();

  return {
    entityId: searchParams.get(URLKeys.EntityId),
    decisionId: searchParams.get(URLKeys.DecisionId),
  };
};

const RightContent: React.FC<{
  count: number | undefined;
  versions: FlowVersionT[];
}> = ({ count, versions }) => {
  const [cols, setCols] = useState<string[]>([]);
  return (
    <div
      className={twJoin(
        "flex h-13 items-center gap-x-2 border-b border-b-gray-200 px-6",
      )}
    >
      <Pill size="sm" variant="gray">
        <Pill.Text>
          {count ? pluralize(count, "Decision") : <>&nbsp;&nbsp; Decisions</>}
        </Pill.Text>
      </Pill>
      <ColumnSelector flowVersions={versions} value={cols} onChange={setCols} />
    </div>
  );
};

type ItemProps = {
  flow: FlowT | undefined;
  title: string;
  count: number | undefined;
  singleDecision: DecisionHistoryRecordV2<GenericObjectT> | null;
  setDisplayedDecision: (decision: DecisionHistoryRecordV2 | null) => void;
  topLevelFilters: TopLevelFilters;
  filter: DecisionHistoryFiltersV2["fields"] | undefined;
  setFilter: (tableFilter: DecisionHistoryFiltersV2["fields"]) => void;
};

const buildQueryFilter = (
  filter: TopLevelFilters,
  fields: DecisionHistoryFiltersV2["fields"] | undefined,
): DecisionHistoryFiltersV2 => {
  return {
    timeWindow: filter.timeWindow,
    environment: filter.decisionEnv,
    fields: {
      entity_or_parent_decision_id: filter.entityId
        ? filter.entityId
        : filter.decisionId,
      ...fields,
    },
  };
};

const FlowAccordionItem: React.FC<ItemProps> = ({
  flow: flowShallow,
  title,
  count,
  topLevelFilters,
  filter,
  singleDecision,
  setDisplayedDecision,
  setFilter,
}) => {
  const flow = useFlow(flowShallow?.id);
  const { workspace } = useWorkspaceContext();
  const decisions = useHistoryDecisionsV2({
    baseUrl: workspace?.base_url ?? "",
    flowSlug: flowShallow?.slug!,
    include_response_data: true,
    include_request_data: true,
    filters: buildQueryFilter(topLevelFilters, filter),
    enabled: flowShallow?.slug !== undefined,
  });

  if (!flow.data) return <AccordionSkeletonItem key={flowShallow?.id} />;

  return (
    <EditorAccordionItem
      className="border-b border-b-gray-200"
      headerClassName="border-b-gray-200 border-b flex-grow"
      headerContent={
        <RightContent count={count} versions={flow.data.versions} />
      }
      title={title}
      value={flow.data.id}
    >
      <div className="relative mx-auto flex max-h-[500px]">
        <DecisionHistoryTable
          canFetchNextPage={
            !singleDecision &&
            Boolean(!decisions.isFetching && decisions.hasNextPage)
          }
          changeFilterFields={setFilter}
          decisionHistory={
            singleDecision
              ? [singleDecision]
              : decisions.data?.pages.flatMap((page) => page.data)
          }
          env={topLevelFilters.decisionEnv}
          fetchNextPage={decisions.fetchNextPage}
          filterFields={filter || {}}
          flow={flow.data}
          isFetching={!singleDecision && decisions.isLoading}
          onDecisionClick={{
            predicate: () => true,
            effect: (decision) => {
              setDisplayedDecision(decision);
            },
          }}
        />
      </div>
    </EditorAccordionItem>
  );
};
type FlowGroup = {
  flow: FlowT;
};

type FlowGroupForCount = {
  flowId: string;
  count: number;
};

const isFlowGroup = (arg: Partial<FlowGroup>): arg is FlowGroup => {
  return arg.flow !== undefined;
};

const isFlowGroupForCount = (
  arg: Partial<FlowGroupForCount>,
): arg is FlowGroupForCount => {
  return arg.flowId !== undefined && arg.count !== undefined;
};

type Props = {
  singleDecision: DecisionHistoryRecordV2<GenericObjectT> | null;
  flowGroups: FlowGroup[];
  flowGroupsForCount: FlowGroupForCount[] | undefined;
  displayedDecision: DecisionHistoryRecordV2 | null;
  setDisplayedDecision: (decision: DecisionHistoryRecordV2 | null) => void;
  topLevelFilters: TopLevelFilters;
  groupFilter: GroupFilter;
  setGroupFilter: React.Dispatch<React.SetStateAction<GroupFilter>>;
};

const groupFilterToTableFilter = (
  groupFilter: GroupFilter[string] | undefined,
): DecisionHistoryFiltersV2["fields"] => {
  if (!groupFilter) {
    return {};
  }
  return {
    flow_version_id: groupFilter.flow_version_ids?.[0],
    status_code: groupFilter.status_codes?.[0],
    origin: groupFilter.origin,
  };
};

const tableFilterToGroupFilter = (
  tableFilter: DecisionHistoryFiltersV2["fields"],
): GroupFilter[string] => {
  return {
    ...(tableFilter.status_code && {
      status_codes: [tableFilter.status_code],
    }),
    ...(tableFilter.flow_version_id && {
      flow_version_ids: [tableFilter.flow_version_id],
    }),
    ...(tableFilter.origin && { origin: tableFilter.origin }),
  };
};

const FlowAccordion: React.FC<Props> = ({
  singleDecision,
  flowGroups,
  flowGroupsForCount,
  topLevelFilters,
  setDisplayedDecision,
  groupFilter,
  setGroupFilter,
}) => {
  const [defaultValue, setDefaultValue] = useSessionStorage<string[]>(
    "decisions-overview-accordion-default-value",
    [flowGroups.at(0)?.flow.id ?? ""],
  );
  return (
    <AccordionRoot
      defaultValue={defaultValue}
      type="multiple"
      onValueChange={setDefaultValue}
    >
      {flowGroups.map((group) => (
        <FlowAccordionItem
          key={group.flow.id}
          count={
            flowGroupsForCount
              ? (flowGroupsForCount.find(
                  (flowGroup) => flowGroup.flowId === group.flow.id,
                )?.count ?? 0)
              : undefined
          }
          filter={groupFilterToTableFilter(groupFilter[group.flow.id])}
          flow={group.flow}
          setDisplayedDecision={setDisplayedDecision}
          setFilter={(tableFilter: DecisionHistoryFiltersV2["fields"]) => {
            setGroupFilter((prevGroupFilter) => ({
              ...prevGroupFilter,
              [group.flow.id]: tableFilterToGroupFilter(tableFilter),
            }));
          }}
          singleDecision={
            singleDecision?.flow.id === group.flow.id ? singleDecision : null
          }
          title={group.flow.name}
          topLevelFilters={topLevelFilters}
        />
      ))}
    </AccordionRoot>
  );
};

type TopLevelFilters = {
  decisionEnv: DecisionEnvironment;
  entityId: string | null;
  decisionId: string | null;
  timeWindow: StrictDateRange | undefined;
};

const AccordionSkeletonItem: React.FC = () => {
  return (
    <EditorAccordionItem
      className="border-b border-b-gray-200"
      headerClassName="border-b-gray-200 border-b flex-grow"
      title={<SkeletonPlaceholder width="w-96" />}
      value="dummy"
    >
      <></>
    </EditorAccordionItem>
  );
};
const AccordionSkeleton: React.FC = () => {
  return (
    <AccordionRoot defaultValue={[]} type="multiple">
      {range(4).map((index) => (
        <AccordionSkeletonItem key={index} />
      ))}
    </AccordionRoot>
  );
};

export const DecisionsOverview: React.FC = () => {
  const { orgId, wsId } = useParamsDecode<DashboardPageParamsT>();
  const { workspace } = useWorkspaceContext();
  const { entityId, decisionId } = useQueryParams();
  const [decisionEnv, setDecisionEnv] = useSessionStorage<DecisionEnvironment>(
    "decisions-overview-decision-env",
    DecisionEnvironment.LIVE,
  );
  const [displayedDecision, setDisplayedDecision] =
    useState<DecisionHistoryRecordV2 | null>(null);

  const {
    dateRangePickerValue,
    onDateRangePickerChange,
    onDateRangePickerReset,
    timeWindow,
  } = useTimeWindow<StrictDateRange | undefined>(
    undefined,
    "decisions-overview-time-window",
  );

  const topLevelFilters: TopLevelFilters = useMemo(() => {
    return {
      decisionEnv,
      entityId,
      decisionId,
      timeWindow,
    };
  }, [decisionEnv, entityId, decisionId, timeWindow]);

  const [groupFilter, setGroupFilter] = useSessionStorage<GroupFilter>(
    "decisions-overview-group-filter",
    {},
  );
  const queryClient = useQueryClient();

  const decisionsOverviewQuery = useDecisionsOverview(workspace.base_url, {
    environments: decisionEnv,
    groupby: "flow.id",
    aggregate: "count",
    entity_id: entityId ?? undefined,
    parent_decision_id: decisionId ?? undefined,
    timeWindow: timeWindow,
  });

  const decisionsOverviewQueryForCount = useDecisionsOverview(
    workspace.base_url,
    {
      environments: decisionEnv,
      groupby: "flow.id",
      aggregate: "count",
      entity_id: entityId ?? undefined,
      parent_decision_id: decisionId ?? undefined,
      timeWindow: timeWindow,
      groupfilter: groupFilter,
    },
  );

  const singleDecisionQuery = useHistoryDecisionV2({
    baseUrl: workspace.base_url ?? "",
    decisionId: decisionId ?? "",
    environmentToMatch: decisionEnv,
    // We give an initial value for the deactivated state so that the loading view treats this as loaded.
    initialData: decisionId
      ? undefined
      : { isQueryError: true, reason: NO_DECISION_FOUND },
  });

  const flowsQuery = useFlows({ workspaceId: wsId });

  const flowGroupsForCount: FlowGroupForCount[] | undefined = useMemo(() => {
    const groups = decisionsOverviewQueryForCount.data?.groups
      .map((group) => ({
        flowId: group.flow?.id,
        count: group.count,
      }))
      .filter(isFlowGroupForCount);
    const singleDecision = singleDecisionQuery.data;
    if (singleDecision && !singleDecision.isQueryError) {
      const existingGroup = groups?.find(
        (group) => group.flowId === singleDecision.decision.flow.id,
      );
      if (existingGroup) {
        existingGroup.count += 1;
      } else {
        groups?.push({
          flowId: singleDecision.decision.flow.id,
          count: 1,
        });
      }
    }
    return groups;
  }, [decisionsOverviewQueryForCount.data, singleDecisionQuery.data]);

  const handleRefresh = () => {
    decisionsOverviewQuery.refetch();
    decisionsOverviewQueryForCount.refetch();
    flowsQuery?.data.forEach(({ slug }) => {
      const queryKey = buildHistoryDecisionV2QueryKey({
        baseUrl: workspace.base_url!,
        flowSlug: slug,
        include_request_data: true,
        include_response_data: true,
        filters: null,
      });
      queryClient.refetchQueries({ queryKey });
    });
  };

  return (
    <DashboardContent
      Header={
        <SubHeader
          backTo={getUrlToWsDashboard({ orgId, wsId })}
          title={
            <>
              <span className="whitespace-nowrap align-middle">
                {decisionId
                  ? "Decision ID "
                  : entityId
                    ? "Decisions for Entity ID "
                    : "Decisions "}
              </span>
              <div className="mb-0.5 inline-block h-fit max-w-60 items-center overflow-hidden align-middle">
                <Pill size="sm" variant="gray" maxWidth>
                  <Pill.Text fontType="code">
                    {decisionId ?? entityId ?? "-"}
                  </Pill.Text>
                </Pill>
              </div>
            </>
          }
          titleAction={
            <>
              <CopyTextIcon
                tooltip="Copy ID"
                value={decisionId ?? entityId ?? ""}
              />
              <EnvironmentPill value={decisionEnv} onChange={setDecisionEnv} />
            </>
          }
        >
          <SubHeader.SearchBox
            placeholder="Search by Deicision ID or Entity ID ..."
            onChange={() => {}}
          />
          <SubHeader.DatePicker
            placeholder="All time"
            value={dateRangePickerValue}
            resetable
            onChange={onDateRangePickerChange}
            onReset={onDateRangePickerReset}
          />
          <SubHeader.Button
            dataLoc="refresh-decisions"
            disabled={decisionsOverviewQuery.isRefetching}
            icon={faRefresh}
            spin={decisionsOverviewQuery.isRefetching}
            tooltip="Refresh"
            onClick={handleRefresh}
          />
        </SubHeader>
      }
    >
      <div className="flex h-full w-full">
        <div className="mx-7 mb-10 h-fit min-w-[600px] grow overflow-hidden rounded-2xl border border-gray-200 bg-white">
          <LoadingView
            queryResult={[
              decisionsOverviewQuery,
              flowsQuery,
              singleDecisionQuery,
            ]}
            renderUpdated={([decisionSummary, flows, singleDecision]: [
              DecisionSummaryResponse,
              FlowT[],
              SingleDecisionEnvelope<GenericObjectT>,
            ]) => {
              const flowGroups: FlowGroup[] =
                decisionSummary.groups
                  .map((group) => ({
                    flow: flows.find((flow) => flow.id === group.flow?.id),
                  }))
                  .filter(isFlowGroup) ?? [];
              if (
                !singleDecision.isQueryError &&
                !flowGroups.find(
                  (group) => group.flow?.id === singleDecision.decision.flow.id,
                )
              ) {
                const singleDecisionFlow = flows.find(
                  (flow) => flow.id === singleDecision.decision.flow.id,
                );
                if (singleDecisionFlow) {
                  flowGroups.push({
                    flow: singleDecisionFlow,
                  });
                }
              }
              if (flowGroups.length === 0) {
                return (
                  <div className="h-[calc(100vh-120px)]">
                    <EmptyState
                      description="Try adjusting the search or filter to find what you are looking for"
                      headline="No results found"
                      icon={faSearch}
                    />
                  </div>
                );
              }
              return (
                <FlowAccordion
                  displayedDecision={displayedDecision}
                  flowGroups={flowGroups}
                  flowGroupsForCount={flowGroupsForCount}
                  groupFilter={groupFilter}
                  setDisplayedDecision={setDisplayedDecision}
                  setGroupFilter={setGroupFilter}
                  singleDecision={
                    singleDecision.isQueryError ? null : singleDecision.decision
                  }
                  topLevelFilters={topLevelFilters}
                />
              );
            }}
            renderUpdating={() => <AccordionSkeleton />}
          />
        </div>
        {
          /*Make some space for the sidepane to take, but allow it to overlap the table on small screens.*/
          !!displayedDecision && <div className="w-[469px]" />
        }
        <DecisionsSidepane
          decision={displayedDecision}
          isOpen={!!displayedDecision}
          onClose={() => setDisplayedDecision(null)}
        />
      </div>
      <div className="h-14" />
    </DashboardContent>
  );
};
