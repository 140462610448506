import { Provider } from "@radix-ui/react-tooltip";
import { QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { LazyMotion } from "framer-motion";
import React from "react";
import ReactDOM from "react-dom";
import ReactDOMClient from "react-dom/client";
import { Toaster } from "react-hot-toast";

import { App } from "./App";
import "./styles/index.css";
import { CONTAINER_CLASS_NAMES } from "src/base-components/Toast/utils";
import { queryClient } from "src/queryClient";

const loadFramerMotionFeatures = () =>
  import("./framer-motion-features").then((res) => res.framerMotionFeatures);

const root = ReactDOMClient.createRoot(
  document.getElementById("root") as HTMLElement,
);
root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <Provider delayDuration={100} skipDelayDuration={100}>
        {ReactDOM.createPortal(
          <Toaster containerClassName={CONTAINER_CLASS_NAMES} />,
          document.body,
        )}
        <LazyMotion features={loadFramerMotionFeatures} strict>
          <App />
        </LazyMotion>
      </Provider>
      <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
    </QueryClientProvider>
  </React.StrictMode>,
);
