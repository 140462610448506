import { useInfiniteQuery, useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { omit } from "lodash";

import {
  decisionHistoryApiRoute,
  decisionHistoryV3ApiRoute,
} from "src/api/exporterApi";
import { GenericObjectT } from "src/api/flowTypes";
import {
  DecideDecisionError,
  DecideDecisionSuccess,
  DecisionEnvironment,
  DecisionHistoryError,
} from "src/api/types";
import { DateRange } from "src/base-components/DateRangePicker";
import { JSONValue } from "src/datasets/DatasetTable/types";
import { DecisionStatusCode } from "src/flow/decisionHistory/SharedStatusColumn";

export type DecisionHistorySuccessResponse<
  ResponseData extends GenericObjectT = GenericObjectT,
> = Pick<DecideDecisionSuccess<ResponseData>, "data" | "metadata" | "status">;

export type DecisionHistoryErrorResponse = Pick<
  DecideDecisionError,
  "detail" | "metadata" | "status"
>;

export type DecisionHistoryRecordV2<
  ResponseData extends GenericObjectT = GenericObjectT,
> =
  // To be able to discriminate the response type by the is_error field
  (
    | {
        is_error: false;
        response: DecisionHistorySuccessResponse<ResponseData>;
      }
    | { is_error: true; response: DecisionHistoryErrorResponse }
  ) & {
    id: string;
    flow: {
      id: string;
      slug: string;
      version_id: string;
      version_name: string;
    };
    entity_id: string;
    is_error: boolean;
    error: DecisionHistoryError | null;
    is_sandbox: boolean;
    duration_in_milliseconds: number;
    /**
     * date-time string
     */
    start_time: string;
    /**
     * date-time string
     */
    end_time: string;
    masked_fields: unknown;
    request: unknown;
    /**
     * Only includes data if the endpoint is called with the option to include node results
     */
    node_results: {
      nodes: {
        [nodeId: string]: {
          id: string;
          title: string;
          tracing: unknown;
          data: GenericObjectT;
        };
      };
      node_execution_metadata?: {
        [nodeId: string]: {
          variables?: {
            [key: string]: {
              value: JSONValue;
              type_: string | null | undefined;
            };
          };
        };
      };
    };
    external_resources: unknown;
    flow_version_etag: string;
    xray_trace_id?: string | null;
    status_code: string;
    parent_flow_id: string | null;
    parent_flow_version_id: string | null;
    parent_decision_id: string | null;
    linked_decisions: null | Record<
      string,
      Record<
        string,
        {
          decision_id: string;
          originating_node_id: string;
          is_called_from_loop?: boolean;
          loop_index?: number;
        }
      >
    >;
  };

export type OriginFilterOptions = "api_call" | "job" | "flow";

export type DecisionHistoryFiltersV2 = {
  timeWindow?: DateRange;
  environment?: DecisionEnvironment;
  fields: Partial<{
    status_code: DecisionStatusCode;
    flow_version_id: string;
    entity_id: string | null;
    parent_decision_id: string | null;
    entity_or_parent_decision_id: string | null;
    parent_flow_id: string;
    parent_flow_version_id: string;
    job_id: string;
    job_run_id: string;
    origin: "api_call" | "job" | "flow";
  }>;
};

export type GetDecisionsParams = {
  baseUrl: string;
  flowSlug: string;
  filters: DecisionHistoryFiltersV2;
  limit: number;
  include_node_results?: boolean;
  include_request_data?: boolean;
  include_response_data?: boolean;
};

export type DecisionHistoryPage<
  ResponseData extends GenericObjectT = GenericObjectT,
> = {
  data: DecisionHistoryRecordV2<ResponseData>[];
  page_min_timestamp: string;
};

type DecisionHistoryQueryParams = Omit<GetDecisionsParams, "limit">;
type DecisionHistoryQueryKeyParams = Omit<
  DecisionHistoryQueryParams,
  "filters"
> & { filters: DecisionHistoryFiltersV2 | null };

export const buildHistoryDecisionV2QueryKey = ({
  baseUrl,
  flowSlug,
  include_node_results,
  include_request_data,
  include_response_data,
  filters,
}: DecisionHistoryQueryKeyParams) => {
  const baseKey = [
    "decisions",
    baseUrl,
    flowSlug,
    include_node_results,
    include_request_data,
    include_response_data,
  ];

  return [...baseKey, ...(filters ? [filters] : [])];
};

export const useHistoryDecisionsV2 = <
  ResponseData extends GenericObjectT = GenericObjectT,
>(
  params: DecisionHistoryQueryParams & { enabled?: boolean },
) => {
  const env: "sandbox" | "prod" | "all" =
    params.filters.environment === undefined
      ? "all"
      : params.filters.environment === DecisionEnvironment.LIVE
        ? "prod"
        : "sandbox";
  return useInfiniteQuery<DecisionHistoryPage<ResponseData>, Error>({
    queryKey: buildHistoryDecisionV2QueryKey(params),
    getNextPageParam: (lastPage) => {
      return lastPage.data.length > 0 ? lastPage.page_min_timestamp : undefined;
    },
    queryFn: async ({
      pageParam = undefined,
    }): Promise<DecisionHistoryPage<ResponseData>> => {
      const response = await decisionHistoryApiRoute.get<{
        decisions: DecisionHistoryRecordV2<ResponseData>[];
        page_max_timestamp: string;
        page_min_timestamp: string;
      }>("/decisions", {
        baseURL: params.baseUrl,
        params: {
          flow_slug: params.flowSlug,
          before_timestamp:
            pageParam ?? params.filters.timeWindow?.to?.toISOString(),
          after_timestamp: params.filters.timeWindow?.from?.toISOString(),
          environment: env,
          limit: 100,
          only_finalized_decisions: false,
          include_extra_fields: true,
          include_node_results: params.include_node_results,
          include_request_data: params.include_request_data,
          include_response_data: params.include_response_data,
          ...params.filters.fields,
        },
      });
      return {
        data: response.data.decisions,
        page_min_timestamp: response.data.page_min_timestamp,
      };
    },
    staleTime: 0,
    // If we don't refetch on mount, changing the filters may show out of date data
    refetchOnMount: true,
    enabled: params.enabled,
  });
};

export type GetDecisionParams = {
  baseUrl: string;
  decisionId: string;
  includeExtraFields?: boolean;
  signal?: AbortSignal;
  includeNodeResultsData?: boolean;
  includeLinkedDecisions?: boolean;
};

export const getHistoryDecision = async <
  ResponseData extends GenericObjectT = GenericObjectT,
>(
  params: GetDecisionParams,
) =>
  await decisionHistoryApiRoute.get<DecisionHistoryRecordV2<ResponseData>>(
    `/decisions/${params.decisionId}`,
    {
      baseURL: params.baseUrl,
      params: {
        include_extra_fields: params.includeExtraFields || undefined,
        include_linked_decisions: params.includeLinkedDecisions !== false,
        include_node_results_data: params.includeNodeResultsData !== false,
      },
      signal: params.signal,
    },
  );

export const NO_DECISION_FOUND = "noneFound" as const;
export const ENVIRONMENT_MISSMATCH_IS_PROD = "isProdDecision" as const;
export const ENVIRONMENT_MISSMATCH_IS_SANDBOX = "isSandboxDecision" as const;
export type SingleDecisionQueryErrorReason =
  | typeof NO_DECISION_FOUND
  | typeof ENVIRONMENT_MISSMATCH_IS_PROD
  | typeof ENVIRONMENT_MISSMATCH_IS_SANDBOX;

export type SingleDecisionEnvelope<
  ResponseData extends GenericObjectT = GenericObjectT,
> =
  | {
      isQueryError: true;
      reason: SingleDecisionQueryErrorReason;
    }
  | {
      isQueryError: false;
      decision: DecisionHistoryRecordV2<ResponseData>;
    };

export const useHistoryDecisionV2 = <
  ResponseData extends GenericObjectT = GenericObjectT,
>(
  params: GetDecisionParams & {
    versionIdToMatch?: string;
    flowIdToMatch?: string;
    environmentToMatch?: DecisionEnvironment;
    initialData?: SingleDecisionEnvelope<ResponseData>;
  },
) => {
  return useQuery<SingleDecisionEnvelope<ResponseData>, Error>(
    ["decisions", omit(params, "initialData")],
    async () => {
      try {
        const decision = (await getHistoryDecision<ResponseData>(params)).data;
        const flowMatches = params.flowIdToMatch
          ? decision.flow.id === params.flowIdToMatch
          : true;
        const versionMatches = params.versionIdToMatch
          ? decision.flow.version_id === params.versionIdToMatch
          : true;
        if (!flowMatches || !versionMatches)
          return { isQueryError: true, reason: NO_DECISION_FOUND };
        if (
          params.environmentToMatch === DecisionEnvironment.SANDBOX &&
          !decision.is_sandbox
        )
          return { isQueryError: true, reason: ENVIRONMENT_MISSMATCH_IS_PROD };

        if (
          params.environmentToMatch === DecisionEnvironment.LIVE &&
          decision.is_sandbox
        )
          return {
            isQueryError: true,
            reason: ENVIRONMENT_MISSMATCH_IS_SANDBOX,
          };

        return { isQueryError: false, decision };
      } catch (error: any) {
        if (
          (error as AxiosError).response?.status === 422 ||
          (error as AxiosError).response?.status === 404
        ) {
          return { isQueryError: true, reason: NO_DECISION_FOUND };
        } else throw error;
      }
    },
    {
      enabled: params.decisionId !== "" && params.baseUrl !== "",
      retry: 1,
      staleTime: 0,
      cacheTime: 0,
      initialData: params.initialData,
    },
  );
};

export const useNodeResultData = (params: {
  nodeId: string;
  decisionId: string;
  baseUrl: string;
}) => {
  return useQuery<GenericObjectT>(
    ["nodeResultData", params],
    async () => {
      const response = await decisionHistoryV3ApiRoute.get(
        `decisions/${params.decisionId}/node_results/${params.nodeId}`,
        { params: { $fields: "data" }, baseURL: params.baseUrl },
      );
      return response.data.data;
    },
    { enabled: !!params.decisionId },
  );
};
