import { uniq } from "lodash";

import { DecisionHistoryRecordV2 } from "src/api/decisionHistoryV2/decisionHistoryQueries";
import { FlowVersionT, NodeBET } from "src/api/flowTypes";
import { EdgeDb } from "src/clients/flow-api";

const invalidEntityIds = [
  "None",
  "null",
  "none",
  "undefined",
  "string",
] as readonly string[];

export const isValidEntityId = (entityId: string): boolean =>
  !!entityId && entityId.length > 0 && !invalidEntityIds.includes(entityId);

export const findPendingNode = (
  decision: DecisionHistoryRecordV2,
  nodes: NodeBET[],
  edges: EdgeDb[],
) => {
  return nodes.find((node: NodeBET) => {
    const nodeHasResults = !!decision.node_results.nodes[node.id];
    const outgoingEdges = edges.filter(
      (edge) => edge.start_node_id === node.id,
    );
    const followingNodesExistAndHaveNoResults =
      outgoingEdges.length > 0 &&
      outgoingEdges.every(
        (outgoingEdge) =>
          !decision.node_results.nodes[outgoingEdge.end_node_id],
      );

    return nodeHasResults && followingNodesExistAndHaveNoResults;
  });
};

export const hasOutputSchemaError = (decision: DecisionHistoryRecordV2) => {
  return decision.error?.status_code === 409;
};

export const hasInputSchemaError = (decision: DecisionHistoryRecordV2) => {
  return decision.error?.status_code === 422;
};

export const transformSchemaToOptions = (
  flowVersions: FlowVersionT[],
  type: "input" | "output",
) => {
  return uniq(
    flowVersions.flatMap((fv) =>
      Object.keys(
        type === "input"
          ? (fv.input_schema?.properties ?? {})
          : (fv.output_schema?.properties ?? {}),
      ),
    ),
  )
    .sort()
    .map((key) => ({
      label: key,
      value: [type, key].join("."),
    }));
};
