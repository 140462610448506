import { endOfToday, isBefore, parseISO, startOfToday } from "date-fns";
import React, { useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useSessionStorage } from "usehooks-ts";
import { v4 as uuidV4 } from "uuid";

import {
  DecisionHistoryRecordV2,
  useHistoryDecisionsV2,
  useHistoryDecisionV2,
} from "src/api/decisionHistoryV2/decisionHistoryQueries";
import {
  informPreparingDownload,
  pollAndNotifyDownloadSuccess,
} from "src/api/downloadUtils";
import { ExporterDatasetJobsEndpoint } from "src/api/endpoints";
import { DecisionEnvironment } from "src/api/types";
import { LoadingView } from "src/base-components/LoadingView";
import { SearchParamsTabView } from "src/base-components/SearchParamsTabView";
import { toastFailure } from "src/base-components/Toast/utils";
import {
  etag_including_comments,
  useLoadChangeHistory,
} from "src/changeHistory/queries";
import { FlowVersionStatus } from "src/clients/flow-api";
import { useCreateAssembleDatasetJob } from "src/datasets/api/queries";
import {
  DecisionStatusCode,
  POSSIBLE_DECISION_STATUS_CODES,
} from "src/flow/decisionHistory/SharedStatusColumn";
import { DecisionHistoryKeys, URLKeys } from "src/router/SearchParams";
import { useAuthoringContext } from "src/router/routerContextHooks";
import * as logger from "src/utils/logger";
import { renderEmpty } from "src/utils/renderEmpty";
import { useTimeWindow, useTimeWindowQueryParams } from "src/utils/timeWindow";
import { CreateDatasetModal } from "src/versionDecisionHistory/CreateDatasetModal";
import { Filters } from "src/versionDecisionHistory/Filters";
import { Header } from "src/versionDecisionHistory/Header";
import { DecisionHistoryTable } from "src/versionDecisionHistory/Table";
import { TestCaseModal } from "src/versionDecisionHistory/TestCaseModal";
import { useDecisionHistoryPaneEnv } from "src/versionDecisionHistory/useDecisionHistoryPaneEnv";

type PropsT = {
  onRequestClose: () => void;
};

export const DecisionHistorySidePane: React.FC<PropsT> = ({
  onRequestClose,
}) => {
  const { version, workspace, flow } = useAuthoringContext();

  const changeHistory = useLoadChangeHistory(
    version.id,
    etag_including_comments(version.etag),
  );

  const { selectedEnv: environment, defaultEnv: defaultSelectedTab } =
    useDecisionHistoryPaneEnv();
  const [searchParams] = useSearchParams();
  const decisionId = searchParams.get(URLKeys.DecisionId);

  const paramsDateRange = useTimeWindowQueryParams();
  const { dateRangePickerValue, onDateRangePickerChange, timeWindow } =
    useTimeWindow(
      paramsDateRange ?? {
        from: startOfToday(),
        to: endOfToday(),
      },
      `version-${version.id}-decision-history-time-window`,
    );

  const [term, setTerm] = useSessionStorage(
    `version-${version.id}-decision-history-term-search`,
    decisionId ?? "",
  );
  const queryingByTerm = term !== "";
  const [statusCodeFilter, setStatusCodeFilter] = useSessionStorage<
    DecisionStatusCode | undefined
  >("version-decision-history-status-code-filter", undefined);

  const singleDecisionById = useHistoryDecisionV2({
    baseUrl: workspace.base_url ?? "",
    decisionId: term,
    versionIdToMatch: version.id,
    environmentToMatch: environment,
    includeNodeResultsData: false,
  });

  const decisionsByTerm = useHistoryDecisionsV2({
    baseUrl: workspace.base_url ?? "",
    flowSlug: flow.slug,
    filters: {
      timeWindow,
      fields: {
        flow_version_id: version.id,
        status_code: statusCodeFilter,
        entity_or_parent_decision_id: term,
      },
      environment,
    },
    enabled: term !== "",
  });

  const decisionHistory = useHistoryDecisionsV2({
    baseUrl: workspace.base_url ?? "",
    flowSlug: flow.slug,
    filters: {
      timeWindow,
      fields: {
        flow_version_id: version.id,
        status_code: statusCodeFilter,
      },
      environment,
    },
  });

  const [isDownloading, setIsDownloading] = useState(false);

  const handleDownload = async () => {
    const toastId = uuidV4();
    setIsDownloading(true);
    try {
      informPreparingDownload(toastId);
      const downloadJob =
        await ExporterDatasetJobsEndpoint.createExportDecisionsDatasetJob(
          workspace.base_url!,
          {
            flow_id: flow.id,
            request: {
              flow_slug: flow.slug,
              flow_versions: [version.id],
              start_date: timeWindow.from.toISOString(),
              end_date: timeWindow.to.toISOString(),
              status_codes: statusCodeFilter
                ? [statusCodeFilter]
                : POSSIBLE_DECISION_STATUS_CODES.map((s) => s.key),
              format: "csv",
            },
          },
        );
      await pollAndNotifyDownloadSuccess(
        downloadJob,
        workspace.base_url!,
        toastId,
        ExporterDatasetJobsEndpoint.getDatasetJob,
      );
    } catch (err) {
      logger.error("Failed to create download dataset job", err);
      toastFailure({
        id: toastId,
        title: `Something went wrong when downloading the run results`,
      });
    } finally {
      setIsDownloading(false);
    }
  };

  const [isSaveModalOpen, setIsSaveModalOpen] = useState(false);

  const createDatasetMutation = useCreateAssembleDatasetJob(
    workspace.base_url,
    version,
  );

  const handleDatasetSave = (name: string) => {
    setIsSaveModalOpen(false);
    createDatasetMutation.mutate({
      name,
      flow_id: flow.id,
      filters: {
        flow_versions: [version.id],
        status_codes: statusCodeFilter
          ? [statusCodeFilter]
          : POSSIBLE_DECISION_STATUS_CODES.map((s) => s.key),
        start_date: timeWindow.from.toISOString(),
        end_date: timeWindow.to.toISOString(),
      },
    });
  };

  const [decisionToAddAsTestCase, setDecisionToAddAsTestCase] = useState<
    null | string
  >(null);

  const renderTable = (
    data: DecisionHistoryRecordV2[] | undefined,
    type: DecisionEnvironment,
  ) => {
    // The change history does not contaion trivial changes to the Graph like, e.g. lock operations.
    // Therefore relying on the latest change history record here stops lock operations
    // from marking decisions as outdated.
    const disabledRowsBeforeDate =
      changeHistory.data?.pages[0]?.[0]?.created_at;
    const disableRowsWithEtagSmallerThan =
      changeHistory.data?.pages[0]?.[0]?.etag;

    return (
      <>
        <DecisionHistoryTable
          canFetchNextPage={Boolean(
            !decisionHistory.isFetching &&
              decisionHistory.hasNextPage &&
              !queryingByTerm,
          )}
          decisionHistory={data}
          disableRowsBeforeDate={disabledRowsBeforeDate}
          disableRowsWithEtagSmallerThan={disableRowsWithEtagSmallerThan}
          fetchNextPage={decisionHistory.fetchNextPage}
          isDraft={version.status === "DRAFT"}
          isFetching={
            queryingByTerm
              ? singleDecisionById.isFetching || decisionsByTerm.isFetching
              : decisionHistory.isFetching
          }
          setStatusCodeFilter={setStatusCodeFilter}
          singleDecisionError={
            !decisionsByTerm.isFetching &&
            (termFilteredData === undefined || termFilteredData.length === 0) &&
            singleDecisionById.data?.isQueryError
              ? singleDecisionById.data.reason
              : undefined
          }
          statusCodeFilter={statusCodeFilter}
          type={type}
          workspaceUrl={workspace.base_url as string}
          onAddTestCase={setDecisionToAddAsTestCase}
        />
        {/* Render a loading view below the table to give error infos while the header
    of the table is still rendered*/}
        {!queryingByTerm && (
          <LoadingView
            queryResult={decisionHistory}
            renderUpdated={renderEmpty}
            renderUpdating={renderEmpty}
          />
        )}
      </>
    );
  };

  const renderFiltersComponent = (selectedTab: "sandbox" | "live") => {
    if (selectedTab === "live" && version.status === FlowVersionStatus.DRAFT) {
      return null;
    }

    const hasLiveDecision =
      (decisionHistory.data?.pages.at(0)?.data.length ?? 0) > 0 &&
      selectedTab === DecisionEnvironment.LIVE;

    const canCreateDataset =
      hasLiveDecision && !decisionHistory.isFetching && !queryingByTerm;

    const unsaveableDataset = isBefore(timeWindow.from, parseISO("2023-04-01"));
    return (
      <Filters
        dateRangePickerValue={dateRangePickerValue}
        disableDownload={!canCreateDataset || isDownloading}
        disableSave={
          !canCreateDataset
            ? "unavailable"
            : unsaveableDataset
              ? "unsaveable_dataset"
              : undefined
        }
        initialSearchTerm={term}
        isDownloading={isDownloading}
        isRefreshing={decisionHistory.isFetching}
        isSaving={createDatasetMutation.isLoading}
        selectedTab={selectedTab}
        onClickDownload={handleDownload}
        onClickRefresh={() => decisionHistory.refetch()}
        onClickSave={() => setIsSaveModalOpen(true)}
        onDateRangePickerChange={onDateRangePickerChange}
        onTermChange={setTerm}
      />
    );
  };

  // When the single decision endpoint does not yield a result we want the table to display that no decisions were
  // found for these filters (i.e. render for an empty list)
  const singleDecisionDisplayData =
    singleDecisionById.data && !singleDecisionById.data.isQueryError
      ? [singleDecisionById.data.decision]
      : undefined;

  const termFilteredData = singleDecisionDisplayData
    ? singleDecisionDisplayData
    : decisionsByTerm.data
      ? decisionsByTerm.data?.pages?.flatMap((page) => page.data)
      : undefined;

  const dataToDisplay = queryingByTerm
    ? termFilteredData
    : decisionHistory.data?.pages?.flatMap((page) => page.data);

  return (
    <div className="flex h-full w-128 flex-col border-l border-gray-200 bg-white pt-6">
      <Header onRequestClose={onRequestClose} />
      <SearchParamsTabView
        componentClassName="flex flex-col pt-4"
        containerClassName="px-4"
        defaultValue={defaultSelectedTab}
        searchParamKey={DecisionHistoryKeys.DecisionHistoryView}
        tabListPadding="none"
        tabs={[
          {
            searchParamValue: DecisionEnvironment.LIVE,
            title: "Live",
            component: (
              <>
                {renderFiltersComponent("live")}
                {renderTable(dataToDisplay, DecisionEnvironment.LIVE)}
              </>
            ),
          },
          {
            searchParamValue: DecisionEnvironment.SANDBOX,
            title: "Sandbox",
            component: (
              <>
                {renderFiltersComponent("sandbox")}
                {renderTable(dataToDisplay, DecisionEnvironment.SANDBOX)}
              </>
            ),
          },
        ]}
      />
      <CreateDatasetModal
        isOpen={isSaveModalOpen}
        onClose={() => setIsSaveModalOpen(false)}
        onSubmit={handleDatasetSave}
      />
      <TestCaseModal
        decisionId={decisionToAddAsTestCase}
        onClose={() => setDecisionToAddAsTestCase(null)}
      />
    </div>
  );
};
