import { faRefresh } from "@fortawesome/pro-regular-svg-icons";
import { UseQueryResult } from "@tanstack/react-query";
import {
  endOfDay,
  endOfToday,
  isValid,
  parseISO,
  startOfDay,
  startOfToday,
} from "date-fns";
import { useState } from "react";
import { SelectRangeEventHandler } from "react-day-picker";
import { useSearchParams } from "react-router-dom";
import { twJoin } from "tailwind-merge";

import {
  DecisionHistoryFiltersV2,
  DecisionHistoryRecordV2,
  useHistoryDecisionsV2,
  useHistoryDecisionV2,
} from "src/api/decisionHistoryV2/decisionHistoryQueries";
import { EnvironmentPill } from "src/base-components/EnvironmentPill";
import { LoadingView } from "src/base-components/LoadingView";
import { SubHeader } from "src/flow/SubHeader";
import { DecisionHistoryTable } from "src/flow/decisionHistory/TableWrapper";
import {
  useEnvironment,
  useSearchTerm,
} from "src/flow/decisionHistory/filterHooks";
import { useIsDecisionTracingEnabled } from "src/flow/decisionHistory/useIsDecisionTracingEnabled";
import { maxTableWidth } from "src/layout/constants";
import { DecisionHistoryKeys } from "src/router/SearchParams";
import { useFlowContext } from "src/router/routerContextHooks";
import { getUrlToHistoricDecisionTrace } from "src/router/urls";
import { renderEmpty } from "src/utils/renderEmpty";
import { useTimeWindow } from "src/utils/timeWindow";

type Props = {};

const useDefaultValuesFromSearchParams = () => {
  const [searchParams] = useSearchParams();

  const fromDate = parseISO(searchParams.get(DecisionHistoryKeys.From) ?? "");
  const toDate = parseISO(searchParams.get(DecisionHistoryKeys.To) ?? "");
  const from = isValid(fromDate) ? fromDate : startOfToday();
  const to = isValid(toDate) ? toDate : endOfToday();

  const jobId = searchParams.get(DecisionHistoryKeys.JobId);
  const jobRunId = searchParams.get(DecisionHistoryKeys.JobRunId);
  const filters =
    jobRunId && jobId ? { job_id: jobId, job_run_id: jobRunId } : {};

  return {
    timeWindow: {
      from,
      to,
    },
    filters,
  };
};

export const FlowHistoryContent: React.FC<Props> = () => {
  const [, setSearchParams] = useSearchParams();
  const defaultValues = useDefaultValuesFromSearchParams();

  const { dateRangePickerValue, onDateRangePickerChange, timeWindow } =
    useTimeWindow(
      defaultValues.timeWindow,
      "flow-decision-history-time-window",
    );

  const onTimeWindowChange: SelectRangeEventHandler = (range) => {
    onDateRangePickerChange(range);
    setSearchParams((prev) => {
      const newParams = new URLSearchParams(prev);
      if (range && range.from && range.to) {
        newParams.set(
          DecisionHistoryKeys.From,
          startOfDay(range.from).toISOString(),
        );
        newParams.set(DecisionHistoryKeys.To, endOfDay(range.to).toISOString());
      } else {
        newParams.delete(DecisionHistoryKeys.From);
        newParams.delete(DecisionHistoryKeys.To);
      }
      return newParams;
    });
  };

  const [term, setTerm] = useSearchTerm();
  const isQueryingByTerm = term !== "";

  const [environment, setEnvironment] = useEnvironment();
  const [filterFields, setFilterFields] = useState<
    DecisionHistoryFiltersV2["fields"]
  >(defaultValues.filters);
  const { orgId, workspace, flow } = useFlowContext();

  const singleDecisionById = useHistoryDecisionV2({
    baseUrl: workspace.base_url ?? "",
    decisionId: term,
    flowIdToMatch: flow.id,
    environmentToMatch: environment,
  });

  const decisionsByTerm = useHistoryDecisionsV2({
    baseUrl: workspace.base_url ?? "",
    flowSlug: flow.slug,
    filters: {
      timeWindow,
      fields: {
        entity_or_parent_decision_id: term,
        ...filterFields,
      },
      environment,
    },
    enabled: term !== "",
  });

  const decisionHistory = useHistoryDecisionsV2({
    baseUrl: workspace.base_url ?? "",
    flowSlug: flow.slug,
    filters: {
      timeWindow,
      fields: filterFields,
      environment,
    },
  });

  const singleDecisionDisplayData =
    singleDecisionById.data && !singleDecisionById.data.isQueryError
      ? [singleDecisionById.data.decision]
      : undefined;

  const termFilteredData = singleDecisionDisplayData
    ? singleDecisionDisplayData
    : decisionsByTerm.data
      ? decisionsByTerm.data?.pages?.flatMap((page) => page.data)
      : undefined;

  const displayedData = isQueryingByTerm
    ? termFilteredData
    : decisionHistory.data?.pages?.flatMap((page) => page.data);

  const isDecisionTracingEnabled = useIsDecisionTracingEnabled(flow);
  const openDecisionTrace = (decision: DecisionHistoryRecordV2) => {
    const decisionTraceUrl = getUrlToHistoricDecisionTrace({
      orgId,
      wsId: workspace.id,
      flowId: decision.flow.id,
      versionId: decision.flow.version_id,
      decisionId: decision.id,
      decisionEnv: environment,
    });
    window.open(window.location.origin + decisionTraceUrl, "_blank");
  };

  const renderTable = () => (
    <>
      <DecisionHistoryTable
        canFetchNextPage={Boolean(
          !decisionHistory.isFetching &&
            decisionHistory.hasNextPage &&
            !isQueryingByTerm,
        )}
        changeFilterFields={setFilterFields}
        decisionHistory={displayedData}
        env={environment}
        fetchNextPage={decisionHistory.fetchNextPage}
        filterFields={filterFields}
        flow={flow}
        isFetching={
          isQueryingByTerm
            ? singleDecisionById.isFetching || decisionsByTerm.isFetching
            : decisionHistory.isFetching
        }
        singleDecisionError={
          !decisionsByTerm.isFetching &&
          (termFilteredData === undefined || termFilteredData.length === 0) &&
          singleDecisionById.data?.isQueryError
            ? singleDecisionById.data.reason
            : undefined
        }
        includeEntityLinks
        tracingHoverBehavior
        onDecisionClick={{
          predicate: isDecisionTracingEnabled,
          effect: (decision) => {
            if (isDecisionTracingEnabled(decision)) openDecisionTrace(decision);
          },
        }}
      />
      {/* Render a loading view below the table to give error infos while the header
        of the table is still rendered*/}

      <LoadingView
        queryResult={
          (isQueryingByTerm
            ? singleDecisionById
            : decisionHistory) as UseQueryResult<any, Error>
        }
        renderUpdated={renderEmpty}
        renderUpdating={renderEmpty}
      />
    </>
  );

  return (
    <>
      <SubHeader
        title="Decision history"
        titleAction={
          <EnvironmentPill value={environment} onChange={setEnvironment} />
        }
        paddedParent
      >
        <SubHeader.SearchBox
          defaultValue={term}
          placeholder="Search by Decision ID or Entity ID ..."
          onChange={setTerm}
        />
        <SubHeader.DatePicker
          value={dateRangePickerValue}
          onChange={onTimeWindowChange}
        />
        <SubHeader.Button
          disabled={decisionHistory.isFetching}
          icon={faRefresh}
          spin={decisionHistory.isFetching}
          tooltip="Refresh"
          onClick={() => decisionHistory.refetch()}
        />
      </SubHeader>
      <div
        className={twJoin(
          "relative mx-auto flex h-[calc(100%-48px)] rounded-lg border border-gray-200 bg-white px-4 pt-2",
          maxTableWidth,
        )}
      >
        {renderTable()}
      </div>
    </>
  );
};
