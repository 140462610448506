import { useCallback } from "react";

import { DecisionHistoryRecordV2 } from "src/api/decisionHistoryV2/decisionHistoryQueries";
import { FlowT } from "src/api/flowTypes";
import { useChangeHistories } from "src/changeHistory/queries";
import { getDecisionDisabledReason } from "src/versionDecisionHistory/Table";

export const useIsDecisionTracingEnabled = (flow: FlowT) => {
  const historyChanges = useChangeHistories(
    flow.versions.map((version) => ({
      versionId: version.id,
      etag: version.etag ?? "00000000",
    })),
  );

  return useCallback(
    (decision: DecisionHistoryRecordV2) => {
      const change = historyChanges.find(
        (change) => change.data?.flow_version_id === decision.flow.version_id,
      );

      return change?.isLoading
        ? false
        : !getDecisionDisabledReason(
            decision,
            change?.data?.etag,
            change?.data?.created_at,
          );
    },
    [historyChanges],
  );
};
