import { Extension } from "@uiw/react-codemirror";
import { toJS } from "mobx";
import { observer } from "mobx-react-lite";
import React, { createContext } from "react";
import { twJoin } from "tailwind-merge";

import { FlowT } from "src/api/flowTypes";
import { ErrorBaseInfo } from "src/api/types";
import { AssignmentNodeEditor } from "src/assignmentNode/AssignmentNodeEditor";
import { LOCK_NODE_EDITOR_CLASSNAME } from "src/authoringMultiplayerLock/constants";
import { useVersionResourceLock } from "src/authoringMultiplayerLock/useVersionResourceLock";
import { useAutocompleteExtension } from "src/base-components/CodeInput/Autocomplete/useAutocompleteExtension";
import { ResourceType, WorkspaceDataplane } from "src/clients/flow-api";
import {
  AssignmentNode,
  CustomConnectionNode,
  DecisionTableNode,
  FlowNode,
  IntegrationNode,
  MLNode,
  RuleNodeV2,
  ScorecardNode,
  SimpleNode,
  SplitNodeV2,
  InboundWebhookConnectionNode,
  ManualReviewNode,
  NodeDataT,
  SimpleNodeDataT,
  DatabaseConnectionNode,
  LoopNode,
  ManifestConnectionNode,
} from "src/constants/NodeDataTypes";
import { NamedNodeTypeT, NODE_TYPE } from "src/constants/NodeTypes";
import { CustomConnectionNodeEditor } from "src/customConnectionNode/Editor";
import { useOpenDetailedView } from "src/dataTable/TableUtils";
import { DatabaseConnecionNodeEditor } from "src/databaseConnectionNode/DatabaseConnecionNodeEditor";
import { displayedErroredRowToResultData } from "src/databaseDebugPopover/utils";
import { DecisionTableNodeEditor } from "src/decisionTableNode/DecisionTableNodeEditor";
import { useRightPane } from "src/flowContainer/hooks/useRightPane";
import { useCanAuthoringEditFlowVersion } from "src/hooks/useCanAuthoringEditFlowVersion";
import { InboundWebhookNodeEditor } from "src/inboundWebhookNode/InboundWebhookNodeEditor";
import { IntegrationNodeEditor } from "src/integrationNode/IntegrationNodeEditor";
import { ManifestConnectionNodeEditor } from "src/manifestConnectionNode/ManifestConnectionNodeEditor";
import { ManualReviewNodeEditor } from "src/manualReviewNode/ManualReviewNodeEditor";
import { MLNodeEditor } from "src/mlNode/MLNodeEditor";
import { CodeNodeEditor } from "src/nodeEditor/CodeNodeEditor";
import { ErrorInfo } from "src/nodeEditor/ErrorInfo";
import { TestRunErrorSelector } from "src/nodeEditor/TestRunErrorSelector";
import { FlowNodeEditor } from "src/parentFlowNodes/flowNode/FlowNodeEditor";
import { LoopNodeEditor } from "src/parentFlowNodes/loopNode/LoopNodeEditor";
import { NodeEditorOptions } from "src/router/SearchParams";
import { useAuthoringContext } from "src/router/routerContextHooks";
import { RuleV2Editor } from "src/ruleNodeV2Editor/RuleNodeV2Editor";
import { ScorecardNodeEditor } from "src/scorecardNode/ScorecardNodeEditor";
import { SplitNodeV2Editor } from "src/splitNodeV2Editor/SplitNodeV2Editor";
import { UpdateNodeArgs } from "src/store/GraphStore";
import { useGraphStore } from "src/store/StoreProvider";
import { useNodeRunState } from "src/store/runState/RunState";
import { formatConnectErrorMessage } from "src/utils/connectErrors";
import { isIntegrationNodes } from "src/utils/predicates";
import { errorMessage } from "src/utils/stringUtils";
import { assertUnreachable } from "src/utils/typeUtils";

export type NodeEditorBaseProps<T extends NodeDataT> = {
  immutable: boolean;
  isReactive: boolean;
  displayedError: ErrorBaseInfo | undefined;
  onUpdate: (args: UpdateNodeArgs<T>) => void;
};

type Props = {
  workspace: WorkspaceDataplane;
  flow: FlowT;
  hasLockableFocus: boolean;
  isFullscreen: boolean;
};

export const NodeEditorCompletionContext = createContext<{
  defaultCompletion: Extension | undefined;
}>({
  defaultCompletion: undefined,
});

export const NodeEditor: React.FC<Props> = observer(
  ({ workspace, flow, hasLockableFocus, isFullscreen }) => {
    const {
      selectedNode,
      updateNode: updateSelectedNodeData,
      resultsOutdated,
      displayedErroredRow,
      setDisplayedErroredRow,
    } = useGraphStore();
    const { setRightPaneTab } = useRightPane();
    const openDetailedView = useOpenDetailedView();

    const { lockedByOtherUser } = useVersionResourceLock(
      ResourceType.NODE,
      selectedNode?.id,
    );

    const { version } = useAuthoringContext();

    const completionContext = useAutocompleteExtension({
      versionId: version.id,
      selectedNodeId: selectedNode?.id,
      isLoopNode: selectedNode?.type === NODE_TYPE.LOOP_NODE,
    });

    const canEditFlowVersion = useCanAuthoringEditFlowVersion();

    const isImmutable = !canEditFlowVersion || lockedByOtherUser;

    const isReactive = !hasLockableFocus || lockedByOtherUser;
    const runStateV2 = useNodeRunState(selectedNode?.id ?? "");

    const displayedError: ErrorBaseInfo | undefined =
      runStateV2?.type === "historical-error" ||
      runStateV2?.type === "test-error"
        ? runStateV2.error
        : displayedErroredRow;

    if (selectedNode === undefined) return <></>;

    const renderRuleNodeV2Editor = (ruleNode: RuleNodeV2) => (
      <div className="px-6 pb-20 pt-8">
        <RuleV2Editor
          key={ruleNode.id}
          displayedError={displayedError}
          immutable={isImmutable}
          isReactive={isReactive}
          selectedNode={ruleNode}
          onUpdate={updateSelectedNodeData}
        />
      </div>
    );

    const renderSplitNodeV2Editor = (splitNodeV2: SplitNodeV2) => (
      <div className="decideScrollbar h-full w-full overflow-auto px-6 pb-11 pt-3">
        <SplitNodeV2Editor
          key={splitNodeV2.id}
          displayedError={displayedError}
          immutable={isImmutable}
          isReactive={isReactive}
          selectedNode={splitNodeV2}
          onUpdate={updateSelectedNodeData}
        />
      </div>
    );

    const renderIntegrationNodeEditor = (integrationNode: IntegrationNode) => (
      <div className="p-8">
        <IntegrationNodeEditor
          key={selectedNode.id}
          displayedError={displayedError}
          immutable={isImmutable}
          isReactive={isReactive}
          node={integrationNode}
          workspaceId={workspace.id}
          onUpdate={updateSelectedNodeData}
        />
      </div>
    );

    const renderManifestConnectionNodeEditor = (
      manifestConnectionNode: ManifestConnectionNode,
    ) => (
      <div className="p-8">
        <ManifestConnectionNodeEditor
          key={selectedNode.id}
          displayedError={displayedError}
          immutable={isImmutable}
          isReactive={isReactive}
          node={manifestConnectionNode}
          workspaceId={workspace.id}
          onUpdate={updateSelectedNodeData}
        />
      </div>
    );

    const renderCustomConnectionNodeEditor = (node: CustomConnectionNode) => (
      <div className="decideScrollbar h-full overflow-y-auto p-8">
        <CustomConnectionNodeEditor
          displayedError={displayedError}
          immutable={isImmutable}
          isReactive={isReactive}
          node={node}
          workspaceId={workspace.id}
          onUpdate={updateSelectedNodeData}
        />
      </div>
    );

    const renderInboundWebhookConnectionNodeEditor = (
      node: InboundWebhookConnectionNode,
    ) => (
      <div className="px-8 pb-8 pt-4">
        <InboundWebhookNodeEditor
          key={selectedNode.id}
          displayedError={displayedError}
          immutable={isImmutable}
          isReactive={isReactive}
          node={node}
          workspaceId={workspace.id}
          onUpdate={updateSelectedNodeData}
        />
      </div>
    );

    const renderDatabaseConnectionNodeEditor = (
      node: DatabaseConnectionNode,
    ) => (
      <div className="px-8 pb-8 pt-4">
        <DatabaseConnecionNodeEditor
          key={selectedNode.id}
          displayedError={displayedError}
          immutable={isImmutable}
          isReactive={isReactive}
          node={node}
          workspaceId={workspace.id}
          onUpdate={updateSelectedNodeData}
        />
      </div>
    );

    const getNodeEditor = () => {
      const nodeType = selectedNode.type as NamedNodeTypeT;
      switch (nodeType) {
        case NODE_TYPE.CODE_NODE:
          return (
            <div className="h-full w-full overflow-hidden px-6 pt-6">
              <CodeNodeEditor
                displayedError={displayedError}
                immutable={isImmutable}
                isReactive={isReactive}
                language="python"
                src={(selectedNode as SimpleNode).data?.src}
                onChange={(value) => {
                  updateSelectedNodeData<SimpleNodeDataT>({
                    newData: { src: value },
                  });
                }}
              />
            </div>
          );
        case NODE_TYPE.RULE_NODE_V2:
          return renderRuleNodeV2Editor(selectedNode as RuleNodeV2);
        case NODE_TYPE.SPLIT_NODE_V2:
          return renderSplitNodeV2Editor(selectedNode as SplitNodeV2);
        case NODE_TYPE.INTEGRATION_NODE:
          return renderIntegrationNodeEditor(selectedNode as IntegrationNode);
        case NODE_TYPE.MANIFEST_CONNECTION_NODE:
          return renderManifestConnectionNodeEditor(
            selectedNode as ManifestConnectionNode,
          );
        case NODE_TYPE.ML_NODE:
          return (
            <div className="decideScrollbar h-full overflow-y-auto px-6 pb-6 pt-6">
              <MLNodeEditor
                key={selectedNode.id}
                displayedError={displayedError}
                flow={flow}
                immutable={isImmutable}
                isReactive={isReactive}
                selectedNode={selectedNode as MLNode}
                workspace={workspace}
                onUpdate={updateSelectedNodeData}
              />
            </div>
          );
        case NODE_TYPE.CUSTOM_CONNECTION_NODE:
          return renderCustomConnectionNodeEditor(
            selectedNode as CustomConnectionNode,
          );
        case NODE_TYPE.WEBHOOK_CONNECTION_NODE:
          return renderInboundWebhookConnectionNodeEditor(
            selectedNode as InboundWebhookConnectionNode,
          );
        case NODE_TYPE.SQL_DATABASE_CONNECTION_NODE:
          return renderDatabaseConnectionNodeEditor(
            selectedNode as DatabaseConnectionNode,
          );
        case NODE_TYPE.DECISION_TABLE_NODE:
          return (
            <div className="decideScrollbar h-full overflow-y-auto px-6 pb-6 pt-6">
              <DecisionTableNodeEditor
                displayedError={displayedError}
                immutable={isImmutable}
                isFullscreen={isFullscreen}
                isReactive={isReactive}
                selectedNode={selectedNode as DecisionTableNode}
                onUpdate={updateSelectedNodeData}
              />
            </div>
          );
        case NODE_TYPE.ASSIGNMENT_NODE:
          return (
            <div className="px-6 pt-6">
              <AssignmentNodeEditor
                displayedError={displayedError}
                immutable={isImmutable}
                isReactive={isReactive}
                selectedNode={selectedNode as AssignmentNode}
                onUpdate={updateSelectedNodeData}
              />
            </div>
          );
        case NODE_TYPE.SCORECARD_NODE:
          return (
            <div className="px-7 py-8">
              <ScorecardNodeEditor
                displayedError={displayedError}
                immutable={isImmutable}
                isReactive={isReactive}
                selectedNode={selectedNode as ScorecardNode}
                onUpdate={updateSelectedNodeData}
              />
            </div>
          );
        case NODE_TYPE.FLOW_NODE:
          return (
            <div className="px-7 py-8">
              <FlowNodeEditor
                displayedError={displayedError}
                immutable={isImmutable}
                isReactive={isReactive}
                selectedNode={selectedNode as FlowNode}
                onUpdate={updateSelectedNodeData}
              />
            </div>
          );
        case NODE_TYPE.REVIEW_CONNECTION_NODE:
          return (
            <div className="p-4">
              <ManualReviewNodeEditor
                displayedError={displayedError}
                immutable={isImmutable}
                isReactive={isReactive}
                node={selectedNode as ManualReviewNode}
                onUpdate={updateSelectedNodeData}
              />
            </div>
          );
        case NODE_TYPE.LOOP_NODE:
          return (
            <LoopNodeEditor
              displayedError={displayedError}
              immutable={isImmutable}
              isReactive={isReactive}
              selectedNode={selectedNode as LoopNode}
              onUpdate={updateSelectedNodeData}
            />
          );
        case NODE_TYPE.INPUT_NODE:
        case NODE_TYPE.OUTPUT_NODE:
        case NODE_TYPE.SPLIT_BRANCH_NODE_V2:
          return <></>;
        default:
          assertUnreachable(nodeType);
          return null;
      }
    };

    const isSelectedNodeDatabase =
      selectedNode.type === NODE_TYPE.SQL_DATABASE_CONNECTION_NODE;

    const formatHistoricalErrorMessage = (message: string) => {
      if (!isIntegrationNodes(selectedNode)) {
        return message;
      }

      return formatConnectErrorMessage(message);
    };

    return (
      <>
        {(runStateV2?.type === "historical-error" ||
          runStateV2?.type === "test-error") && (
          <div className="mt-6 px-4">
            <ErrorInfo
              action={
                // Display a button to open the debug popover for DBC node from the Node details
                runStateV2.type === "historical-error" && isSelectedNodeDatabase
                  ? {
                      text: "Debug",
                      onClick: () =>
                        setRightPaneTab(NodeEditorOptions.ExecutedQuery),
                    }
                  : undefined
              }
              failingInput={
                runStateV2.type === "historical-error"
                  ? runStateV2.error.data
                  : undefined
              }
              message={formatHistoricalErrorMessage(
                errorMessage(runStateV2.error.msg),
              )}
            />
          </div>
        )}
        {runStateV2?.type === "test-run" && (
          <>
            <TestRunErrorSelector
              action={
                // Display a button to open the debug popover for DBC node from the Node details
                isSelectedNodeDatabase &&
                displayedErroredRow?.node_id === selectedNode.id
                  ? {
                      text: "Debug",
                      onClick: () => {
                        openDetailedView(
                          displayedErroredRowToResultData(
                            toJS(displayedErroredRow),
                          ),
                        );
                      },
                    }
                  : undefined
              }
              displayedFailure={displayedErroredRow}
              resultsOutdated={resultsOutdated}
              selectedNodeId={selectedNode.id}
              setDisplayedFailure={setDisplayedErroredRow}
              testResult={runStateV2.testResult}
              workspaceUrl={workspace.base_url!}
            />
          </>
        )}
        <div className={twJoin(LOCK_NODE_EDITOR_CLASSNAME, "h-full w-full")}>
          <NodeEditorCompletionContext.Provider value={completionContext}>
            {getNodeEditor()}
          </NodeEditorCompletionContext.Provider>
        </div>
      </>
    );
  },
);
