import {
  faCheck,
  faForward,
  faTriangleExclamation,
  faXmark,
} from "@fortawesome/pro-regular-svg-icons";
import { Popover } from "@headlessui2/react";
import { observer } from "mobx-react-lite";
import { useEffect } from "react";

import { GenericObjectT } from "src/api/flowTypes";
import {
  DataRow,
  DecisionHistoryError,
  ErroredRow,
  FieldErrorsT,
  NodeExecutionMetadata,
  NodeTestRunResult,
} from "src/api/types";
import { Divider } from "src/base-components/Divider";
import { EmptyState } from "src/base-components/EmptyState";
import {
  useFloatingWindowsActions,
  useIsFloatingWindowPinned,
} from "src/base-components/FloatingWindow/hooks";
import { ComputePositionFn } from "src/base-components/FloatingWindow/positioning";
import { InformationPill } from "src/base-components/InformationPill";
import { Pill } from "src/base-components/Pill";
import { SkeletonPlaceholder } from "src/base-components/SkeletonPlaceholder";
import { Tabs } from "src/base-components/Tabs";
import {
  BeMappedNode,
  DatabaseConnectionNode,
  NodeRunStateV2,
  NodeTestRunStateV2,
} from "src/constants/NodeDataTypes";
import { getNodeIconFromNode } from "src/constants/NodeIcons";
import { NODE_TYPE } from "src/constants/NodeTypes";
import { ExecutedQuery } from "src/dataTable/DetailedView/tabs/ExecuteQuery";
import { ExecutionResult } from "src/dataTable/DetailedView/tabs/ExecutionResult";
import { InspectData } from "src/dataTable/DetailedView/tabs/InspectData";
import { LoopIterations } from "src/dataTable/DetailedView/tabs/LoopIterations";
import { hasNodeAccessedFields } from "src/dataTable/DetailedView/tabs/utils";
import { ResultDataAndAuxRowV2, ResultsDataStatus } from "src/dataTable/types";
import {
  QueryResultT,
  RequestStatus,
  ResultStatus,
  TabLabel,
  getFieldErrors,
  getQueryResult,
} from "src/databaseDebugPopover/BaseDebugPopover";
import { useFieldToSourceNodeMappings } from "src/databaseDebugPopover/utils";
import {
  FloatingWindowInternal,
  useFloatingWindowPosition,
} from "src/datasets/DatasetTable/FloatingWindow";
import {
  useAuthoringUIActions,
  useIsNodeEditorOpen,
  useNodeWasExecuted,
  useSelectedResultsRowIndex,
  useSelectedResultsRowNodeExecutionMetadata,
} from "src/flowContainer/AuthoringUIContext";
import { getInputOutputNodeTitle } from "src/nodeEditor/NodeTitleEditor";
import { usePaginatedResults } from "src/nodeEditor/usePaginatedResults";
import { useNodeHighlighting } from "src/store/NodeHighlighting";
import { useGraphStore } from "src/store/StoreProvider";
import {
  useFlowOuput,
  useNodeRunState,
  useNodeRunStates,
} from "src/store/runState/RunState";
import { errorMessage } from "src/utils/stringUtils";
import { assertUnreachable } from "src/utils/typeUtils";

const useNodeResults = (
  node: BeMappedNode,
  runState: NodeTestRunStateV2,
  index: number,
) => {
  const isOutputNode = node.type === NODE_TYPE.OUTPUT_NODE;

  const {
    resultsToDisplay: results,
    fetchAdditionalData,
    isFetching,
    additionalDataCanBeFetched,
  } = usePaginatedResults(runState.testResult, isOutputNode, "all");

  const result = results.find((result) => result.index === index);
  const noResultsForThisIndex =
    !result && results.some((result) => result.index > index);

  useEffect(() => {
    if (!isFetching && !result && additionalDataCanBeFetched) {
      fetchAdditionalData();
    }
  }, [
    result,
    index,
    fetchAdditionalData,
    isFetching,
    additionalDataCanBeFetched,
  ]);

  return {
    result,
    isFetching,
    noResultsForThisIndex:
      noResultsForThisIndex || (!additionalDataCanBeFetched && !result),
  };
};

export const DetailedView: React.FC<{
  id: string;
  data: GenericObjectT;
  computePosition: ComputePositionFn;
}> = observer(({ id, computePosition }) => {
  const { selectedNode } = useGraphStore();
  const { close: closeWindow } = useFloatingWindowsActions();

  const selectedResultsRowIndex = useSelectedResultsRowIndex();
  const { setSelectedResultsRow } = useAuthoringUIActions();

  const isOutputNode = selectedNode?.type === NODE_TYPE.OUTPUT_NODE;

  const allRunStates = useNodeRunStates();
  const flowOutput = useFlowOuput();

  useTraceSelectedResultsRow();

  const runState =
    isOutputNode && flowOutput
      ? flowOutput
      : allRunStates.get(selectedNode?.id ?? "");

  useEffect(() => {
    if (!selectedNode || selectedResultsRowIndex === null) {
      closeWindow(id);
      setSelectedResultsRow(null);
    }
  }, [
    selectedNode,
    selectedResultsRowIndex,
    closeWindow,
    id,
    setSelectedResultsRow,
  ]);

  if (
    !selectedNode ||
    !runState ||
    runState.type !== "test-run" ||
    selectedResultsRowIndex === null
  ) {
    return null;
  }

  return (
    <DetailedViewContent
      computePosition={computePosition}
      id={id}
      node={selectedNode}
      rowIndex={selectedResultsRowIndex}
      runState={runState}
    />
  );
});

const DetailedViewContent: React.FC<{
  id: string;
  rowIndex: number;
  node: BeMappedNode;
  runState: NodeTestRunStateV2;
  computePosition: ComputePositionFn;
}> = ({ id, rowIndex, node, runState, computePosition }) => {
  const { close: closeWindow, setPin } = useFloatingWindowsActions();
  const { setSelectedNode } = useGraphStore();
  const {
    result: _row,
    isFetching,
    noResultsForThisIndex,
  } = useNodeResults(node, runState, rowIndex);
  const { setSelectedResultsRow } = useAuthoringUIActions();
  const nodeWasExecuted = useNodeWasExecuted(node.id);
  const isNodeEditorOpen = useIsNodeEditorOpen();
  const isPinned = useIsFloatingWindowPinned(id);

  const { position, windowRef } = useFloatingWindowPosition(computePosition);

  const icon = getNodeIconFromNode(node, "sm");

  const isEmpty = !nodeWasExecuted || (!isFetching && noResultsForThisIndex);
  const nodeName =
    node.type === NODE_TYPE.INPUT_NODE || node.type === NODE_TYPE.OUTPUT_NODE
      ? getInputOutputNodeTitle(node)
      : node.data.label;

  const title = (
    <div className="flex min-w-0 flex-1 items-center gap-x-2">
      {icon && <div>{icon}</div>}
      <div className="truncate text-nowrap text-gray-800 font-inter-semibold-13px">
        {nodeName}
      </div>
      <Divider height="h-3" orientation="vertical" />

      <Pill size="sm" variant="gray">
        <Pill.Text>#{rowIndex + 1}</Pill.Text>
      </Pill>
    </div>
  );

  const onClose = () => {
    closeWindow(id);
    setSelectedResultsRow(null);
    if (!isNodeEditorOpen) {
      setSelectedNode(null);
    }
  };

  // TODO: Remove popover
  return (
    <Popover>
      <FloatingWindowInternal
        ref={windowRef}
        dataLoc="detailed-view-window"
        draggable={true}
        height="h-120"
        id={id}
        isPinned={isPinned}
        maximizable={false}
        style={{
          inset: "0px auto auto 0px",
          transform: position,
        }}
        title={title}
        titleRight={
          isFetching ? (
            <SkeletonPlaceholder height="h-5.5" width="w-24" />
          ) : _row?.type ? (
            <RowStatusPill status={_row.type} />
          ) : null
        }
        fullBodyWidth
        resizeable
        onClose={onClose}
        onPin={() => {
          setPin(id, !isPinned);
        }}
      >
        {isEmpty ? (
          <EmptyState
            description={`This node was not executed for test case #${
              rowIndex + 1
            }.`}
            headline="No data found"
            icon={faForward}
          />
        ) : (
          <DetailedViewContentWindow
            isFetching={isFetching}
            node={node}
            row={_row}
            onClose={onClose}
          />
        )}
      </FloatingWindowInternal>
    </Popover>
  );
};

const RowStatusPillIcon: React.FC<{
  status: ResultsDataStatus;
}> = ({ status }) => {
  switch (status) {
    case "success":
    case "success_match":
      return <Pill.Icon icon={faCheck} />;
    case "success_mismatch":
      return <Pill.Icon icon={faXmark} />;
    case "failure":
    case "ignored":
      return <Pill.Icon icon={faTriangleExclamation} />;
    default:
      assertUnreachable(status);
  }
};

const getStatusColor = (status: ResultsDataStatus) => {
  switch (status) {
    case "success":
    case "success_match":
      return "green";
    case "success_mismatch":
      return "yellow";
    case "failure":
      return "red";
    case "ignored":
      return "gray";
    default:
      assertUnreachable(status);
  }
};

const getStatusText = (status: ResultsDataStatus) => {
  switch (status) {
    case "success":
    case "success_match":
      return "Successful";
    case "failure":
      return "Failed";
    case "ignored":
      return "Ignored";
    case "success_mismatch":
      return "Mismatch";
    default:
      assertUnreachable(status);
  }
};

const RowStatusPill: React.FC<{
  status: ResultsDataStatus;
}> = ({ status }) => {
  return (
    <Pill dataLoc="row-status-pill" size="sm" variant={getStatusColor(status)}>
      <RowStatusPillIcon status={status} />
      <Pill.Text>{getStatusText(status)}</Pill.Text>
    </Pill>
  );
};

const DBCNodeTabs: React.FC<{
  node: DatabaseConnectionNode;
  row?: ResultDataAndAuxRowV2;
  onClose?: () => void;
  isFetching: boolean;
}> = ({ node, row, onClose, isFetching }) => {
  const runState: NodeRunStateV2 | undefined = useNodeRunState(node.id);
  const testResult = runState?.type === "test-run" && runState.testResult;

  return (
    <DBCNodeTabsInnerTestRun
      isFetching={isFetching}
      node={node}
      row={row}
      testResult={testResult as NodeTestRunResult}
      onClose={onClose}
    />
  );
};

const DBCNodeTabsBase: React.FC<{
  isFetching: boolean;
  node: DatabaseConnectionNode;
  row?: ResultDataAndAuxRowV2;
  onClose?: () => void;
  requestStatus: RequestStatus;
  resultStatus: ResultStatus;
  fieldErrors: FieldErrorsT | undefined;
  result: QueryResultT;
}> = ({
  isFetching,
  node,
  row,
  onClose,
  requestStatus,
  resultStatus,
  fieldErrors,
  result,
}) => {
  return (
    <Tabs
      containerClassName="max-h-full decideScrollbar flex flex-col pr-4"
      defaultActiveKey="inspect-data"
      panelClassName="max-h-full min-h-0 flex-1 flex flex-col pt-2"
      panelsClassName="flex-1 min-h-0 flex flex-col"
      tabListClassName="pb-0.5 border-b border-gray-200 bg-white flex sticky top-0 z-20"
      tabs={[
        {
          key: "inspect-data",
          label: "Inspect data",
          content: (
            <InspectData
              accessedFields={getAccessedKeys(
                row?.nodeExecutionMetadata?.[node.id],
              )}
              isFetching={isFetching}
              row={row}
              rowData={row?.data}
              withAccessedFields={hasNodeAccessedFields(node)}
            />
          ),
        },
        {
          key: "executed-query",
          label: (
            <TabLabel
              isErrored={requestStatus === "error"}
              label="Executed query"
            />
          ),
          content: (
            <ExecutedQuery
              fieldErrors={fieldErrors}
              isFetching={isFetching}
              node={node}
              nodeExecutionMetadata={row?.nodeExecutionMetadata}
              onClose={onClose}
            />
          ),
        },
        {
          key: "sql-response",
          disabled: isFetching,
          label: (
            <TabLabel
              isErrored={resultStatus === "error"}
              label="SQL response"
            />
          ),
          content: <ExecutionResult result={result} status={resultStatus} />,
        },
      ]}
    />
  );
};

export const useQueryResult = ({
  error,
  data,
  nodeId,
}: {
  data?: DataRow;
  error: ErroredRow | DecisionHistoryError | undefined;
  nodeId: string;
}): {
  result: QueryResultT | null;
  resultStatus: ResultStatus;
  requestStatus: RequestStatus;
  fieldErrors: FieldErrorsT | undefined;
} => {
  const { sourceNodeToFieldNames } = useFieldToSourceNodeMappings();
  const nodeDataFields = sourceNodeToFieldNames.get(nodeId) || [];

  const fieldErrors = error ? getFieldErrors(error) : undefined;

  const resultStatus = !error
    ? "success"
    : fieldErrors
      ? "notAvailable"
      : "error";

  return {
    resultStatus,
    requestStatus: fieldErrors ? "error" : "success",
    result:
      !error && data
        ? getQueryResult(data, nodeDataFields)
        : fieldErrors
          ? errorMessage(error?.msg)
          : null,
    fieldErrors,
  };
};

const DBCNodeTabsInnerTestRun: React.FC<{
  node: DatabaseConnectionNode;
  row?: ResultDataAndAuxRowV2;
  onClose?: () => void;
  testResult: NodeTestRunResult;
  isFetching: boolean;
}> = ({ node, row, onClose, testResult, isFetching }) => {
  // Using `true` for the `isOutputNode` b/c otherwise the success data gets
  // duplicated when viewing the Inspect Data table from Output node
  const { failureRowsQuery } = usePaginatedResults(testResult, true, "failure");
  const getErrorRow = (errorIndex: number) => {
    const failureDataFlatened = failureRowsQuery.data?.pages.flatMap(
      (page) => page.data,
    );
    return failureDataFlatened?.find((failure) => failure.index === errorIndex);
  };

  // Ensure we only show error state when
  // error is coming from the DBC node
  const isError = row?.type === "failure" && row.errorOriginNodeId === node.id;
  const errorRow = isError ? getErrorRow(row.index) : undefined;

  const { result, resultStatus, requestStatus, fieldErrors } = useQueryResult({
    error: errorRow,
    data: row?.data,
    nodeId: node.id,
  });

  return (
    <DBCNodeTabsBase
      fieldErrors={fieldErrors}
      isFetching={isFetching}
      node={node}
      requestStatus={requestStatus}
      result={result}
      resultStatus={resultStatus}
      row={row}
      onClose={onClose}
    />
  );
};

const DetailedViewContentWindow: React.FC<{
  node: BeMappedNode;
  onClose?: () => void;
  row?: ResultDataAndAuxRowV2;
  isFetching: boolean;
}> = ({ node, onClose, row: _row, isFetching = false }) => {
  return (
    <div className="flex h-full min-h-0 flex-col gap-y-2 border-t border-gray-100 pl-4 pt-2">
      <RerunInformationPill nodeId={node.id} />
      {node.type === NODE_TYPE.LOOP_NODE ? (
        <Tabs
          containerClassName="max-h-full decideScrollbar pr-4"
          defaultActiveKey="inspect-data"
          panelClassName="pt-2"
          tabListClassName="pb-0.5 border-b border-gray-200 bg-white flex sticky top-0 z-20"
          tabs={[
            {
              key: "inspect-data",
              label: "Inspect data",
              content: (
                <InspectData
                  accessedFields={getAccessedKeys(
                    _row?.nodeExecutionMetadata?.[node.id],
                  )}
                  isFetching={isFetching}
                  row={_row}
                  rowData={_row?.data}
                  withAccessedFields={hasNodeAccessedFields(node)}
                />
              ),
            },
            {
              key: "iterations",
              label: "Iterations",
              content: (
                <LoopIterations
                  isFetching={isFetching}
                  rowData={_row}
                  selectedNodeId={node.id}
                />
              ),
            },
          ]}
        />
      ) : node.type === NODE_TYPE.SQL_DATABASE_CONNECTION_NODE ? (
        <DBCNodeTabs
          isFetching={isFetching}
          node={node as DatabaseConnectionNode}
          row={_row}
          onClose={onClose}
        />
      ) : (
        <InspectData
          accessedFields={getAccessedKeys(
            _row?.nodeExecutionMetadata?.[node.id],
          )}
          isFetching={isFetching}
          row={_row}
          rowData={_row?.data}
          withAccessedFields={hasNodeAccessedFields(node)}
        />
      )}
    </div>
  );
};

export const getAccessedKeys = (
  nodeExecutionMetadata?: NodeExecutionMetadata[string],
) => {
  return (nodeExecutionMetadata?.accessed_fields || []).concat(
    nodeExecutionMetadata?.updated_fields || [],
  );
};

const RerunInformationPill: React.FC<{
  nodeId: string;
}> = observer(({ nodeId }) => {
  const { resultsOutdated } = useGraphStore();
  const selectedNodeRunState = useNodeRunState(nodeId);

  if (!(resultsOutdated || selectedNodeRunState === undefined)) {
    return null;
  }

  return (
    <InformationPill type="info">Test run again to update data</InformationPill>
  );
});

const useTraceSelectedResultsRow = () => {
  const selectedRowNodeExecutionMetadata =
    useSelectedResultsRowNodeExecutionMetadata();
  const { highlightNodes, clearHighlighting } = useNodeHighlighting();
  const { updateEdgesZIndex } = useGraphStore();

  useEffect(() => {
    if (selectedRowNodeExecutionMetadata) {
      highlightNodes(Object.keys(selectedRowNodeExecutionMetadata));
    } else {
      clearHighlighting();
    }
    updateEdgesZIndex(selectedRowNodeExecutionMetadata ?? undefined);
  }, [
    selectedRowNodeExecutionMetadata,
    highlightNodes,
    clearHighlighting,
    updateEdgesZIndex,
  ]);
};
