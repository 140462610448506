import { faWarning } from "@fortawesome/pro-regular-svg-icons";
import { Handle, Position } from "reactflow";
import { twJoin } from "tailwind-merge";

import { useVersionResourceLock } from "src/authoringMultiplayerLock/useVersionResourceLock";
import { Icon } from "src/base-components/Icon";
import { ResourceType } from "src/clients/flow-api";
import { DEFAULT_UNTITLED_NODE_NAME } from "src/constants/DefaultValues";
import { NodeLatency } from "src/flowGraph/NodeLatency";
import { CanvasLock } from "src/multiplayer/CanvasLock";
import { FEATURE_FLAGS, isFeatureFlagEnabled } from "src/router/featureFlags";
import { useOpenOnHover } from "src/utils/useOpenOnHover";

export const handleClassName = "opacity-0";

export const renderHandles = (options?: {
  targetHandleClassName?: string;
  sourceHandleClassName?: string;
}) => (
  <>
    <Handle
      className={twJoin(handleClassName, options?.targetHandleClassName)}
      isConnectable={false}
      position={Position.Top}
      type="target"
    />
    <Handle
      className={twJoin(handleClassName, options?.sourceHandleClassName)}
      isConnectable={false}
      position={Position.Bottom}
      type="source"
    />
  </>
);
export type BaseNodeProps = {
  title: string;
  label: string;
  selected: boolean;
  id: string;
  grayedOut?: boolean;
  isErrored: boolean;
  isDeletable?: boolean;
  isInvalid?: boolean;
  icon: React.ReactNode;
  withUnderlay?: boolean;
  runIcon?: React.ReactNode;
  duration?: number;
  commentCount?: React.ReactNode;
  renderControls?: ({
    onMouseEnter,
    onMouseLeave,
    close,
  }: {
    onMouseEnter: () => void;
    onMouseLeave: () => void;
    close: () => void;
  }) => React.ReactNode;
  defaultName?: string;
};

export const BaseNode: React.FC<BaseNodeProps> = ({
  title,
  label,
  selected,
  isErrored,
  isInvalid = false,
  runIcon,
  id,
  grayedOut,
  commentCount,
  duration,
  icon,
  withUnderlay = false,
  renderControls,
  defaultName = DEFAULT_UNTITLED_NODE_NAME,
}) => {
  const { isOpen, setIsOpen, onMouseEnter, onMouseLeave } = useOpenOnHover<
    HTMLDivElement,
    HTMLDivElement
  >(300);

  const { lockOwner, lockedByOtherUser } = useVersionResourceLock(
    ResourceType.NODE,
    id,
  );

  return (
    <>
      <div
        className={twJoin(
          "box-border flex w-64 flex-row rounded-lg border bg-white p-2 transition-shadow hover:cursor-pointer",
          selected &&
            !isErrored &&
            !isInvalid &&
            "border-indigo-400 outline-none ring-2 ring-indigo-500/25",
          !selected && "border-white",
          selected && isErrored && "border-red-600",
          isInvalid && selected && "border-orange-500",
          grayedOut ? "shadow-sm" : "shadow-base",
        )}
        data-loc={label}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        {withUnderlay && (
          <div className="absolute left-1.5 top-1.5 -z-10 h-full w-64 rounded-xl bg-white shadow-base" />
        )}
        {icon && (
          <div
            className={twJoin(
              "h-10 w-10 shrink-0",
              grayedOut && "brightness-[1.02] grayscale",
            )}
          >
            {icon}
          </div>
        )}
        <div className="w-full overflow-hidden pl-2">
          <div
            className={twJoin(
              "font-inter-normal-12px",
              grayedOut ? "text-gray-400" : "text-gray-500",
            )}
          >
            {title}
          </div>
          <div
            className={twJoin(
              "line-clamp-3 break-words font-inter-semibold-13px",
              grayedOut ? "text-gray-500" : "text-gray-800",
            )}
          >
            {label ? label : defaultName}
          </div>
        </div>
        <div
          className={twJoin(
            "mt-1 flex flex-shrink-0 flex-row",
            grayedOut && "brightness-[1.02] grayscale",
          )}
        >
          {isInvalid ? (
            <Icon color="text-orange-500" icon={faWarning} size="xs" />
          ) : (
            commentCount
          )}
          {runIcon}
          {isFeatureFlagEnabled(FEATURE_FLAGS.nodeLatency) && (
            <NodeLatency duration={duration} isErrored={isErrored} />
          )}
        </div>
      </div>
      {lockOwner && lockedByOtherUser && (
        <CanvasLock lockOwner={lockOwner} resource="node" />
      )}
      {isOpen &&
        renderControls &&
        renderControls({
          onMouseEnter,
          onMouseLeave,
          close: () => setIsOpen(false),
        })}
      {renderHandles()}
    </>
  );
};
