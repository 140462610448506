import { faClock, faTimes } from "@fortawesome/pro-regular-svg-icons";
import React, { useEffect, useState } from "react";
import { twJoin } from "tailwind-merge";

import { DecisionHistoryRecordV2 } from "src/api/decisionHistoryV2/decisionHistoryQueries";
import { FloatingWindowsProvider } from "src/base-components/FloatingWindow/FloatingWindowsProvider";
import { Icon } from "src/base-components/Icon";
import { Pill } from "src/base-components/Pill";
import { Tabs } from "src/base-components/Tabs";
import { DecisionFields } from "src/decisionsOverview/DecisionFields";
import { ErrorTabContent } from "src/decisionsOverview/ErrorTabContent";
import { PendingOutputTabContent } from "src/decisionsOverview/PendingOutputTabContent";
import { hasInputSchemaError } from "src/decisionsOverview/utils";
import { ExcludesFalse } from "src/flow/types";

type Props = {
  isOpen: boolean;
  decision: DecisionHistoryRecordV2 | null;
  onClose: () => void;
};

const Header: React.FC<Pick<Props, "decision" | "onClose">> = ({
  decision,
  onClose,
}) => {
  return (
    <div className="flex w-full items-center justify-between px-4">
      <h3 className="flex items-center gap-x-2 font-inter-semibold-16px">
        Decision ID
        <Pill size="sm" variant="gray" maxWidth>
          <Pill.Text fontType="code">{decision?.id ?? "-"}</Pill.Text>
        </Pill>
      </h3>
      <div className="flex gap-x-1.5 text-gray-500">
        <Icon
          dataLoc="webhook-logs-close"
          icon={faTimes}
          size="base"
          onClick={onClose}
        />
      </div>
    </div>
  );
};

const TabContent: React.FC<{
  id: string;
  type: "request" | "response";
}> = ({ id, type }) => {
  return (
    <div className="px-4">
      <DecisionFields decisionId={id} type={type} />
    </div>
  );
};

export type DecisionRequest = { data: Record<string, any> };

export const DecisionsSidepane: React.FC<Props> = ({
  isOpen,
  decision,
  onClose,
}) => {
  const [activeTab, setActiveTab] = useState<string>("output-data-tab");

  // reset active tab when decision changes
  useEffect(() => {
    setActiveTab("output-data-tab");
  }, [decision]);

  const isPendingDecision = decision?.status_code === "202";
  const isErroredDecision = decision?.is_error;
  const isInputSchemaError = decision ? hasInputSchemaError(decision) : false;

  const tabs = [
    {
      label: "Input",
      key: "input-data-tab",
      content:
        isErroredDecision && isInputSchemaError ? (
          <ErrorTabContent decision={decision} />
        ) : decision && decision.request ? (
          <TabContent id={decision.id} type="request" />
        ) : null,
    },
    !isInputSchemaError && {
      label: (
        <span className="flex items-center gap-x-1.5">
          Output
          {isPendingDecision && (
            <Icon color="text-gray-500" icon={faClock} size="xs" />
          )}
        </span>
      ),
      key: "output-data-tab",
      content:
        decision?.status_code === "200" && !decision?.is_error ? (
          <TabContent id={decision.id} type="response" />
        ) : isPendingDecision ? (
          <PendingOutputTabContent decision={decision} />
        ) : isErroredDecision ? (
          <ErrorTabContent decision={decision} />
        ) : null,
    },
  ].filter(Boolean as unknown as ExcludesFalse);

  return (
    <FloatingWindowsProvider>
      <div
        className={twJoin(
          "right-0 top-[49px] ml-auto flex h-[calc(100vh-49px)] w-[469px] flex-col border-l border-gray-200 bg-white py-4 transition-opacity",
          isOpen ? "fixed" : "hidden",
        )}
      >
        <Header decision={decision} onClose={onClose} />
        <hr className="mx-4 mb-2 mt-4 border-gray-100" />
        <Tabs
          panelClassName="h-full flex flex-col"
          selectedKey={isInputSchemaError ? "input-tab-data" : activeTab}
          tabListClassName="mx-4 border-b border-gray-100 mb-6 sticky bg-white"
          tabs={tabs}
          onChange={setActiveTab}
        />
      </div>
    </FloatingWindowsProvider>
  );
};
