import { faWavePulse } from "@fortawesome/pro-regular-svg-icons";
import { useMemo } from "react";
import { twJoin } from "tailwind-merge";

import {
  DecisionHistoryFiltersV2,
  DecisionHistoryRecordV2,
  SingleDecisionQueryErrorReason,
} from "src/api/decisionHistoryV2/decisionHistoryQueries";
import { FlowT } from "src/api/flowTypes";
import { DecisionEnvironment } from "src/api/types";
import { EmptyState } from "src/base-components/EmptyState";
import { getDecisionHistoryColumns } from "src/flow/decisionHistory/Columns";
import { getHistoryEmptyStateWording } from "src/flow/decisionHistory/SharedEmptyStateWording";
import {
  FlowDecisionTableProps,
  TableComp as Table,
} from "src/flow/decisionHistory/Table";
import { useJobs } from "src/jobs/api/queries";
import { useWorkspaceContext } from "src/router/routerContextHooks";
import { FlowPageParamsT } from "src/router/urls";
import { useParamsDecode } from "src/utils/useParamsDecode";

const renderEmptyState = (
  singleDecisionError: SingleDecisionQueryErrorReason | undefined,
  emptyData: boolean,
) => {
  if (singleDecisionError || emptyData) {
    const wording = getHistoryEmptyStateWording(
      singleDecisionError ?? "emptyData",
    );
    return (
      <div className="py-16">
        <EmptyState icon={faWavePulse} {...wording} />
      </div>
    );
  }
};

type PropsT = {
  filterFields: DecisionHistoryFiltersV2["fields"];
  changeFilterFields: (filters: DecisionHistoryFiltersV2["fields"]) => void;
  decisionHistory: DecisionHistoryRecordV2[] | undefined;
  canFetchNextPage: boolean;
  fetchNextPage: () => void;
  isFetching: boolean;
  singleDecisionError?: SingleDecisionQueryErrorReason;
  env: DecisionEnvironment;
  flow: FlowT;
  onDecisionClick: FlowDecisionTableProps["onDecisionClick"];
  includeEntityLinks?: boolean;
  tracingHoverBehavior?: boolean;
};

export const DecisionHistoryTable: React.FC<PropsT> = ({
  filterFields,
  changeFilterFields,
  decisionHistory,
  canFetchNextPage,
  fetchNextPage,
  isFetching,
  singleDecisionError,
  env,
  flow,
  onDecisionClick,
  includeEntityLinks = false,
  tracingHoverBehavior = false,
}) => {
  const { orgId, wsId } = useParamsDecode<FlowPageParamsT>();
  const { workspace } = useWorkspaceContext();
  const { data: jobs, isInitialLoading: jobsQueryIsInitialLoading } = useJobs(
    workspace.base_url!,
    flow.id,
  );

  const emptyData = Boolean(decisionHistory && decisionHistory.length === 0);
  const columns = useMemo(
    () =>
      getDecisionHistoryColumns({
        orgId,
        wsId,
        flow,
        jobs: jobs ?? [],
        filterFields: filterFields,
        changeFilterFields,
        availableFlowVersions: flow.versions,
        env,
        includeEntityLinks,
        tracingHoverBehavior,
      }),
    [
      changeFilterFields,
      env,
      filterFields,
      flow,
      jobs,
      orgId,
      wsId,
      includeEntityLinks,
      tracingHoverBehavior,
    ],
  );
  return (
    <div className="flex min-h-0 flex-1 flex-col">
      <div
        className={twJoin(
          !emptyData && !singleDecisionError && "min-h-0 flex-1",
        )}
      >
        <Table
          canFetchNextPage={canFetchNextPage}
          columns={columns}
          // Show the table only after we have fetched the jobs
          data={(!jobsQueryIsInitialLoading && decisionHistory) || []}
          fetchNextPage={fetchNextPage}
          frameClassName={twJoin(emptyData ? "h-16" : "overflow-auto")}
          isFetching={isFetching || jobsQueryIsInitialLoading}
          onDecisionClick={onDecisionClick}
        />
      </div>
      {renderEmptyState(singleDecisionError, emptyData)}
    </div>
  );
};
