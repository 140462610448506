import { faChartSimple, faXmark } from "@fortawesome/pro-regular-svg-icons";
import { noop } from "lodash";
import React, { useMemo } from "react";

import { AnalyticsChart } from "src/analytics/AnalyticsChart";
import { useOpenFlowChartModal } from "src/analytics/ChartModal/store";
import {
  ComparativeAnalyticsData,
  ComparativeAnalyticsSummary,
  useComparativeAnalyticsData,
} from "src/analytics/api";
import { AddChartButton } from "src/analytics/common/AddChartButton";
import { SortableWrapper } from "src/analytics/common/SortableWrapper";
import {
  useDeleteFlowChart,
  useFlowCharts,
  useReorderFlowCharts,
} from "src/analytics/queries";
import { Chart } from "src/analytics/types";
import { FlowT } from "src/api/flowTypes";
import { Breakdown } from "src/base-components/Breakdown";
import { ConfirmationModal } from "src/base-components/ConfirmationModal";
import { EmptyState } from "src/base-components/EmptyState";
import { Icon } from "src/base-components/Icon";
import { InformationPill } from "src/base-components/InformationPill";
import { CustomPopover } from "src/base-components/Popover";
import { useModal } from "src/design-system/Modal";
import { useCapabilities } from "src/hooks/useCapabilities";
import { useAuthoringContext } from "src/router/routerContextHooks";
import * as logger from "src/utils/logger";
import { toastError } from "src/utils/toastError";

type PropsT = {
  onRequestClose: () => void;
};

export const AnalyticsSidePane: React.FC<PropsT> = ({ onRequestClose }) => {
  const { flow } = useAuthoringContext();
  const { flowCharts } = useCapabilities();
  const { data: charts } = useFlowCharts(flow.id);

  const {
    isOpen: isDeletingModalOpen,
    data: chartToDelete,
    openModal: openDeletingModal,
    closeModal: closeDeletingModal,
    afterLeave: afterLeaveDeletingModal,
  } = useModal<Chart>();
  const {
    data: analytics,
    isFetching,
    isError: unknownError,
  } = useComparativeAnalyticsData();
  const openFlowChartModal = useOpenFlowChartModal();

  const thereAreNoCharts = charts?.length === 0;

  let error: { headline: string; description: string } | null = null;

  if (analytics?.type === "error") {
    if (analytics.reason === "SOME_VERSION_ERRORED") {
      const listFormatter = new Intl.ListFormat();
      error = {
        headline: "Some of the versions didn't run successfully",
        description: `Make sure ${listFormatter.format(
          analytics.erroredVersionNames,
        )} ${
          analytics.erroredVersionNames.length > 1 ? "run" : "runs"
        } successfully with this dataset`,
      };
    } else if (analytics.reason === "NO_SUCCESSFUL_ROWS") {
      error = {
        headline: analytics.isMultiversion
          ? "These versions have no successful rows in common"
          : "No row successfully ran for this version",
        description:
          "The charts are plotted on data that runs succesfully for every selected version",
      };
    }
  } else if (unknownError) {
    error = {
      headline: "Something went wrong while getting analytics data",
      description: "Please try again",
    };
  }

  const disabledAddChartButton = analytics?.type !== "success";

  return (
    <div className="flex h-full w-128 flex-col bg-white py-6">
      <Header
        disabledAction={disabledAddChartButton}
        hideAction={thereAreNoCharts || !flowCharts.canCreate}
        onOpenAddChartModal={() => openFlowChartModal()}
        onRequestClose={onRequestClose}
      />
      <div className="decideScrollbar grow px-6">
        {error ? (
          <EmptyState
            description={error.description}
            headline={error.headline}
            icon={faChartSimple}
            variant="error"
          />
        ) : thereAreNoCharts ? (
          <EmptyState
            action={
              flowCharts.canCreate && (
                <AddChartButton
                  disabled={disabledAddChartButton}
                  loading={isFetching}
                  onClick={() => openFlowChartModal()}
                />
              )
            }
            description="Create charts to visualize output data from this Decision Flow. Charts you create will be added to this pane."
            headline="No charts created yet"
            icon={faChartSimple}
          />
        ) : (
          <Charts
            analytics={analytics}
            charts={charts}
            flow={flow}
            isLoading={isFetching}
            onDeleteClick={openDeletingModal}
          />
        )}
      </div>
      <DeleteChartModal
        afterLeave={afterLeaveDeletingModal}
        chart={chartToDelete}
        isOpen={isDeletingModalOpen}
        onClose={closeDeletingModal}
      />
    </div>
  );
};

const Header = ({
  onRequestClose,
  hideAction,
  onOpenAddChartModal,
  disabledAction,
}: {
  onRequestClose: () => void;
  onOpenAddChartModal: () => void;
  hideAction: boolean;
  disabledAction: boolean;
}) => {
  return (
    <div className="mb-4 px-6">
      <div className="flex items-start justify-around">
        <div className="grow text-gray-800 font-inter-semibold-16px">
          Analytics
        </div>

        <div className="flex gap-x-2">
          {!hideAction && (
            <AddChartButton
              disabled={disabledAction}
              loading={false}
              size="sm"
              onClick={onOpenAddChartModal}
            />
          )}
          <Icon
            color="text-gray-500 hover:text-gray-700"
            dataLoc="close-decision-history-side-pane"
            icon={faXmark}
            size="xs"
            onClick={onRequestClose}
          />
        </div>
      </div>
      <div className="mt-6">
        <div className="h-px w-full rounded-full bg-gray-100"></div>
      </div>
    </div>
  );
};

const DeleteChartModal: React.FC<{
  isOpen: boolean;
  chart?: Nullable<Chart>;
  onClose: () => void;
  afterLeave: () => void;
}> = ({ chart, isOpen, onClose, afterLeave }) => {
  const { mutateAsync: deleteChart } = useDeleteFlowChart();

  const handleDelete = async () => {
    if (!chart) return;

    try {
      await deleteChart({ chartId: chart.id, resourceId: chart.resource_id });
      onClose();
    } catch (e) {
      logger.error(e);
      toastError("Failed to delete chart");
    }
  };

  return (
    <ConfirmationModal
      afterLeave={afterLeave}
      confirmationButtonText="Delete chart"
      confirmationButtonType="warning"
      open={isOpen}
      title={`Delete the "${chart?.title}" chart?`}
      onClose={onClose}
      onConfirm={handleDelete}
    >
      <div className="mb-6 mt-4 text-gray-500 font-inter-normal-12px">
        Are you sure you want to delete the "{chart?.title}" chart? This will
        delete it from all versions of this flow. This action cannot be undone.
      </div>
    </ConfirmationModal>
  );
};

const Summary: React.FC<{ analytics?: ComparativeAnalyticsData }> = ({
  analytics,
}) => {
  if (analytics?.type !== "success") return <></>;

  const numberOfVersions = Object.keys(analytics.summary).length;

  const isMultiversion = numberOfVersions > 1;

  const allRowsWereSuccessful =
    analytics.combined_successful_count === analytics.total_count;

  const numberFormatter = new Intl.NumberFormat();
  const successfulCountFormatted = numberFormatter.format(
    analytics.combined_successful_count,
  );

  const successfulPercentageFormatted = `${Math.floor(
    (analytics.combined_successful_count / analytics.total_count) * 100,
  )}%`;

  return (
    <InformationPill
      action={{
        text: (
          <CustomPopover
            button={
              <CustomPopover.Button
                className="underline"
                data-loc="summary-details"
              >
                Details
              </CustomPopover.Button>
            }
            offsetX={24}
            placement="right"
          >
            <div
              className="relative w-122 cursor-default"
              data-loc="summary-body"
            >
              <CustomPopover.Button className="absolute right-4 top-4">
                <Icon color="text-gray-500" icon={faXmark} size="sm" />
              </CustomPopover.Button>

              <div className="border-b border-gray-100 px-5 pb-3 pt-4">
                <h1 className="text-left text-gray-800 font-inter-semibold-16px">
                  Test summary
                </h1>
              </div>
              <div className="px-5 pb-6 pt-4">
                <div className="border border-gray-100 text-left text-gray-800">
                  <div className="flex h-8 bg-gray-50 font-inter-medium-12px">
                    <div className="flex min-w-[96px] items-center border-r border-gray-100 px-2">
                      Version
                    </div>
                    <div className="flex flex-1 items-center border-r border-gray-100 px-2">
                      Test Status
                    </div>
                    <div className="flex min-w-[120px] items-center px-2">
                      Successful Rows
                    </div>
                  </div>
                  {Object.entries(analytics.summary).map(
                    ([versionName, versionSummary]) => (
                      <div
                        key={versionName}
                        className="flex h-8 border-t border-gray-100 font-inter-normal-12px"
                      >
                        <div className="flex w-[96px] items-center border-r border-gray-100 px-2">
                          <p className="min-w-0 truncate">{versionName}</p>
                        </div>
                        <div className="flex flex-1 items-center border-r border-gray-100 px-2">
                          <TestStatus summary={versionSummary} />
                        </div>
                        <div className="flex min-w-[120px] items-center px-2">
                          {numberFormatter.format(versionSummary.success_count)}
                        </div>
                      </div>
                    ),
                  )}
                  {isMultiversion && (
                    <div className="flex h-8 border-t border-gray-100 font-inter-normal-12px">
                      <div className="flex min-w-[96px] items-center border-r border-gray-100 px-2">
                        All versions
                      </div>
                      <div className="flex flex-1 items-center border-r border-gray-100 px-2">
                        <TestStatus
                          summary={{
                            success_count: analytics.combined_successful_count,
                            ignored_count:
                              analytics.total_count -
                              analytics.combined_successful_count,
                            failed_count: 0,
                          }}
                        />
                      </div>
                      <div className="flex min-w-[120px] items-center px-2 font-inter-semibold-13px">
                        {numberFormatter.format(
                          analytics.combined_successful_count,
                        )}
                      </div>
                    </div>
                  )}
                </div>
                {(isMultiversion || !allRowsWereSuccessful) && (
                  <InformationPill
                    className="mt-3 whitespace-normal text-left"
                    type="neutral"
                  >
                    {isMultiversion ? (
                      allRowsWereSuccessful ? (
                        <>
                          {successfulCountFormatted} rows successfully ran
                          across all {numberOfVersions} test versions.
                        </>
                      ) : (
                        <>
                          {successfulCountFormatted} rows successfully ran
                          across all {numberOfVersions} test versions. Only
                          these rows are visualized.
                        </>
                      )
                    ) : (
                      <>
                        {successfulCountFormatted} rows successfully ran for
                        this test. Only these rows are visualized.
                      </>
                    )}
                  </InformationPill>
                )}
              </div>
            </div>
          </CustomPopover>
        ),
        onClick: noop,
      }}
      type="neutral"
    >
      {isMultiversion ? (
        allRowsWereSuccessful ? (
          <>
            The charts below visualize {successfulCountFormatted} rows that
            successfully ran across all {numberOfVersions} test versions.
          </>
        ) : (
          <>
            The charts below visualize {successfulCountFormatted} rows (
            {successfulPercentageFormatted}) that successfully ran across all{" "}
            {numberOfVersions} test versions.
          </>
        )
      ) : allRowsWereSuccessful ? (
        <>
          The charts below visualize {successfulCountFormatted} rows that
          successfully ran on {Object.keys(analytics.summary)[0]}.
        </>
      ) : (
        <>
          The charts below visualize {successfulCountFormatted} rows (
          {successfulPercentageFormatted}) that successfully ran on{" "}
          {Object.keys(analytics.summary)[0]}.
        </>
      )}
    </InformationPill>
  );
};

const TestStatus: React.FC<{
  summary: ComparativeAnalyticsSummary[string];
}> = ({ summary }) => (
  <Breakdown
    bars={[
      { color: "green", count: summary.success_count },
      { color: "red", count: summary.failed_count },
      { color: "light-gray", count: summary.ignored_count },
    ]}
  />
);

const Charts = ({
  analytics,
  flow,
  charts,
  isLoading,
  onDeleteClick,
}: {
  analytics: ComparativeAnalyticsData | undefined;
  flow: FlowT;
  charts: Chart[] | undefined;
  isLoading: boolean;
  onDeleteClick: () => void;
}) => {
  const reorderFlowCharts = useReorderFlowCharts(flow.id);

  const chartOrderIds = useMemo(() => {
    return charts?.map((c) => c.id) ?? [];
  }, [charts]);

  return (
    <SortableWrapper
      order={chartOrderIds}
      onChangeOrder={reorderFlowCharts.mutateAsync}
    >
      {(chartOrder) => {
        return (
          <div className="flex flex-col gap-y-4">
            <Summary analytics={analytics} />
            {chartOrder.map((chartId, i) => {
              const chart = charts?.find((c) => c.id === chartId);
              return chart ? (
                <AnalyticsChart
                  key={chart.id}
                  chart={chart}
                  data={
                    analytics?.type === "success" ? analytics.data : undefined
                  }
                  dataLoc={`analytics-chart-${i}`}
                  isLoading={isLoading}
                  isMultiversion={Boolean(analytics?.isMultiversion)}
                  keys={analytics?.type === "success" ? analytics.keys : []}
                  onDelete={onDeleteClick}
                />
              ) : null;
            })}
          </div>
        );
      }}
    </SortableWrapper>
  );
};
