import React from "react";

import { TextConfirmationModal } from "src/base-components/TextConfirmationModal";
import { toastFailure, toastSuccess } from "src/base-components/Toast/utils";
import { OutcomeType } from "src/clients/flow-api";
import { useDeleteOutcomeType } from "src/outcomes/queries";
import * as logger from "src/utils/logger";

type DeleteOutcomeTypeModalProps = {
  afterLeave: () => void;
  isOpen: boolean;
  onClose: () => void;
  outcomeType: OutcomeType | undefined;
};

export const DeleteOutcomeTypeModal: React.FC<DeleteOutcomeTypeModalProps> = ({
  afterLeave,
  isOpen,
  onClose,
  outcomeType,
}) => {
  const { mutateAsync: deleteOutcomeType } = useDeleteOutcomeType();

  const handleDelete = async () => {
    if (!outcomeType) {
      return;
    }
    try {
      await deleteOutcomeType({
        id: outcomeType.id,
        flowId: outcomeType.flow_id,
      });
      toastSuccess({
        title: "Outcome type deletion has started",
        description: `${outcomeType.name} has been started. It might take a while to complete.`,
      });
      onClose();
    } catch (e) {
      toastFailure({
        title: "Failed to delete outcome type",
        description: "Please try again.",
      });
      logger.error(e);
    }
  };

  return (
    <TextConfirmationModal
      afterLeave={afterLeave}
      challengeText={outcomeType?.name}
      confirmButtonText="Delete Outcome"
      description="Are you sure you want to delete this outcome? All existing outcome data will be deleted. This change cannot be undone."
      label="Please enter the Outcome name to confirm"
      open={isOpen}
      title="Are you sure?"
      onClose={onClose}
      onConfirm={handleDelete}
    />
  );
};
