import {
  faCheck,
  faChevronDown,
  faClose,
  faSpinner,
  faTimes,
} from "@fortawesome/pro-regular-svg-icons";
import { faSearch } from "@fortawesome/pro-solid-svg-icons";
import { Listbox } from "@headlessui/react";
import type { Placement } from "@popperjs/core";
import { AnimatePresence, m } from "framer-motion";
import Fuse from "fuse.js";
import React, { useEffect } from "react";
import { useState } from "react";
import ReactDOM from "react-dom";
import { usePopper } from "react-popper";
import { twJoin, twMerge } from "tailwind-merge";

import { Icon } from "src/base-components/Icon";
import { Input } from "src/base-components/Input";
import { Pill } from "src/base-components/Pill";
import { SkeletonPlaceholder } from "src/base-components/SkeletonPlaceholder";
import { useFuseSearch } from "src/utils/useFuseSearch";

type Option = {
  key: string;
  value: React.ReactNode;
  disabled?: boolean;
  searchText?: string;
};

type CommonProps = {
  options: Option[];
  placeholder?: string;
  disabled?: boolean;
  loading?: boolean;
  placement?: Placement;
  listMatchesButtonWidth?: boolean;
  errored?: boolean;
  dataLoc?: string;
  dropdownClassName?: string;
  dropdownPlaceholder?: React.ReactNode;
  fullWidth?: boolean;
};

type MultipleSelectionProps = {
  multiple: true;
  onChange?: (value: string[]) => void;
  value: string[];
  showSelectAllAsOption?: boolean;
  selectAllText?: string;
  showResetButton?: boolean;
};

type SingleSelectionProps = {
  multiple?: false;
  value?: Nullable<string>;
} & (
  | { showResetButton?: false | undefined; onChange?: (value: string) => void }
  | { showResetButton: true; onChange?: (value: Nullable<string>) => void }
);

type SearchProps =
  | { searchable?: false }
  | {
      searchable: true;
      searchOptions?: Fuse.IFuseOptions<Option>;
      searchPlaceholder?: string;
      clearAfterClose?: boolean;
    };

type SelectProps = CommonProps &
  SearchProps &
  (MultipleSelectionProps | SingleSelectionProps);

export const Select: React.FC<SelectProps> = (propsUnfiltered) => {
  const [query, setQuery] = useState("");
  const searchOptions = propsUnfiltered.searchable
    ? (propsUnfiltered.searchOptions ?? {})
    : {};
  const hasSearchText = propsUnfiltered.options[0]?.searchText;
  const search = useFuseSearch(propsUnfiltered.options, {
    threshold: 0.3,
    ...searchOptions,
    keys: searchOptions.keys
      ? searchOptions.keys
      : hasSearchText
        ? ["searchText"]
        : ["value"],
  });
  const searchFilteredOptions = search(query);

  const onChange = (value: Nullable<string> | string[]) => {
    if (propsUnfiltered.multiple) {
      propsUnfiltered.onChange?.(value as string[]);
    } else {
      propsUnfiltered.onChange?.(value as string);
    }
    selectRef.current?.dispatchEvent(new Event("change", { bubbles: true }));
  };

  const props = {
    ...propsUnfiltered,
    options: searchFilteredOptions,
    onChange,
  };

  const [referenceElement, setReferenceElement] =
    useState<HTMLDivElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(
    null,
  );

  const {
    styles: popperStyles,
    attributes: popperAttributes,
    update: forcePopperUpdate,
  } = usePopper(referenceElement, popperElement, {
    strategy: "fixed",
    modifiers: [{ name: "offset", options: { offset: [0, 8] } }],
    placement: props.placement ?? "bottom-start",
  });

  const selectRef = React.useRef<HTMLSelectElement | null>(null);

  return (
    <>
      {/* Hidden native select element as workaround for
       * headless ui not using it and therefore not triggering
       * native events such as onChange in a form
       * https://github.com/tailwindlabs/headlessui/issues/2003
       */}
      <select ref={selectRef} style={{ display: "none" }} />
      <Listbox
        disabled={props.disabled}
        multiple={props.multiple}
        value={props.value}
        onChange={onChange}
      >
        {({ open }) => (
          <>
            <div
              ref={setReferenceElement}
              className={twJoin("flex", props.fullWidth && "w-full")}
            >
              <Listbox.Button
                className="w-full outline-none"
                data-loc={props.dataLoc}
              >
                <SelectButton
                  {...propsUnfiltered}
                  forcePopperUpdate={forcePopperUpdate}
                  open={open}
                  onChange={onChange}
                />
              </Listbox.Button>
            </div>
            {ReactDOM.createPortal(
              <AnimatePresence
                initial={false}
                onExitComplete={() => {
                  if (props.searchable && props.clearAfterClose) {
                    setQuery("");
                  }
                }}
              >
                {open && (
                  <div
                    ref={setPopperElement}
                    className={twJoin(
                      "z-50",
                      propsUnfiltered.dropdownClassName,
                    )}
                    style={
                      (props.listMatchesButtonWidth ?? true)
                        ? {
                            ...popperStyles.popper,
                            width: referenceElement?.scrollWidth,
                          }
                        : popperStyles.popper
                    }
                    {...popperAttributes.popper}
                    data-loc={`${props.dataLoc}-dropdown`}
                  >
                    <m.div
                      animate="visible"
                      className="rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-200 ring-opacity-5 focus:outline-none"
                      exit="hidden"
                      initial="hidden"
                      transition={{
                        type: "tween",
                        ease: "easeOut",
                        duration: 0.15,
                      }}
                      variants={{
                        visible: {
                          opacity: 1,
                          scale: 1,
                        },
                        hidden: {
                          opacity: 0,
                          scale: 0.95,
                        },
                      }}
                    >
                      <SelectOptions
                        {...props}
                        query={query}
                        setQuery={setQuery}
                      />
                    </m.div>
                  </div>
                )}
              </AnimatePresence>,
              document.body,
            )}
          </>
        )}
      </Listbox>
    </>
  );
};

const SelectButton: React.FC<
  SelectProps & { open: boolean; forcePopperUpdate: (() => void) | null }
> = (props) => {
  const { open, forcePopperUpdate, value } = props;
  useEffect(() => {
    if (open) forcePopperUpdate?.();
  }, [open, forcePopperUpdate, value]);

  const className = twMerge(
    "flex min-h-[32px] justify-between rounded-lg border border-gray-200 py-1 pl-3 pr-2 focus:border-indigo-400 focus:outline-none focus:ring-2 focus:ring-indigo-500/25",
    props.options.length === 0 && "cursor-default",
    props.errored && "border-red-500",
    props.open && "border-indigo-400 ring-2 ring-indigo-500/25",
    props.disabled ? "cursor-not-allowed bg-gray-50" : "bg-white",
  );

  const isValueSelected =
    (props.multiple && props.value.length > 0) ||
    (!props.multiple && props.value);

  const placeholderElement = (
    <span className="text-gray-500 font-inter-normal-12px">
      {props.placeholder}
    </span>
  );

  const handleRemove = (removedOption: Option) => {
    if (props.multiple) {
      props.onChange?.(props.value.filter((v) => v !== removedOption.key));
    }
  };

  const onReset = () => {
    if (props.showResetButton) {
      if (props.multiple) {
        props.onChange?.([]);
      } else {
        props.onChange?.(null);
      }
    }
  };

  const selectedValuesElement = props.multiple ? (
    <div className="flex min-w-0 flex-1 flex-wrap gap-1">
      {props.value
        .map((key) => props.options.find((option) => option.key === key))
        .map((option, index) =>
          option ? (
            <Pill key={option.key} size="sm" variant="gray">
              <Pill.Text>{option.value}</Pill.Text>
              {!props.disabled && (
                <div
                  className="flex items-center opacity-60 hover:opacity-100"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleRemove(option);
                  }}
                >
                  <Pill.Icon icon={faClose} />
                </div>
              )}
            </Pill>
          ) : (
            <SkeletonPlaceholder
              key={`skeleton-${index}`}
              height="h-5.5"
              width="w-24"
            />
          ),
        )}
    </div>
  ) : (
    <div
      className={twJoin(
        "min-w-0 flex-1 self-center text-left text-xs-sm",
        props.disabled ? "text-gray-500" : "text-gray-800",
      )}
    >
      {props.options.find((option) => option.key === props.value)?.value}
    </div>
  );

  const resetButtonAvailable = props.showResetButton && isValueSelected;

  return (
    <div className={twJoin("flex items-center gap-2.5", className)}>
      {isValueSelected ? selectedValuesElement : placeholderElement}

      <div className="flex">
        <Icon
          color="text-gray-500"
          cursorType="pointer"
          icon={props.loading ? faSpinner : faChevronDown}
          size="xs"
          spin={props.loading}
        />
        {resetButtonAvailable && (
          <div
            className="flex items-center justify-start"
            data-loc={props.dataLoc && `${props.dataLoc}-reset`}
            onClick={(e) => e.stopPropagation()}
          >
            <div className="mx-1.5 h-4 w-[1px] bg-gray-200" />
            <Icon
              color="text-gray-500"
              disabled={props.disabled}
              icon={faTimes}
              size="xs"
              onClick={onReset}
            />
          </div>
        )}
      </div>
    </div>
  );
};

const selectableOptions = (options: Option[]) =>
  options.filter((option) => option.key !== SELECT_DIVIDER.key);

const SelectOptions = React.forwardRef<
  HTMLUListElement,
  SelectProps & { query: string; setQuery: (q: string) => void }
>((props, ref) => {
  const allSelected =
    !!props.multiple &&
    props.value.length === selectableOptions(props.options).length;
  return (
    <div>
      <Listbox.Options
        ref={ref}
        className="outline-none"
        data-loc={`${props.dataLoc}-options`}
        static
      >
        {props.searchable && (
          <div className="px-4 py-2">
            <Input
              ref={(node) => node?.focus()} //Focus on first render
              data-loc="select-input"
              placeholder={props.searchPlaceholder}
              prefixIcon={{ icon: faSearch }}
              value={props.query}
              fullWidth
              onChange={(e) => props.setQuery(e.target.value)}
            />
          </div>
        )}
        <div className="decideScrollbar max-h-80 overflow-auto">
          {props.multiple && props.showSelectAllAsOption && (
            <>
              <div
                className="flex cursor-pointer justify-between px-4 py-3.5 text-xs-sm text-gray-800 hover:bg-gray-50"
                onClick={() =>
                  props.onChange?.(
                    allSelected
                      ? []
                      : selectableOptions(props.options).map(
                          (option) => option.key,
                        ),
                  )
                }
              >
                <span>{props.selectAllText ?? "All"}</span>
                {allSelected && (
                  <span>
                    <Icon color="text-indigo-600" icon={faCheck} size="sm" />
                  </span>
                )}
              </div>
              <Divider />
            </>
          )}
          {props.options.map((option, index) =>
            option === SELECT_DIVIDER ? (
              <Divider key={`${option.key}-${index}`} />
            ) : (
              <Listbox.Option
                key={option.key}
                className={twMerge(
                  "flex cursor-pointer items-center justify-between px-4 py-3.5 font-inter-normal-13px",
                  "ui-active:bg-gray-50",
                  option.disabled
                    ? "cursor-default text-gray-400"
                    : "text-gray-800 hover:bg-gray-50",
                )}
                data-loc={`select-option-${index}`}
                disabled={option.disabled}
                value={option.key}
              >
                {({ selected }) => (
                  <>
                    <div className="min-w-0 flex-1">{option.value}</div>
                    {selected && (
                      <div>
                        <Icon
                          color="text-indigo-500"
                          icon={faCheck}
                          size="xs"
                        />
                      </div>
                    )}
                  </>
                )}
              </Listbox.Option>
            ),
          )}
          {props.options.length === 0 && props.dropdownPlaceholder}
        </div>
      </Listbox.Options>
    </div>
  );
});

const Divider: React.FC = () => (
  <hr className="my-2.5 h-px w-full border-none bg-gray-200" />
);

export const SELECT_DIVIDER: Option = {
  key: "divider",
  value: <hr />,
  disabled: true,
};
