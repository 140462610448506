import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import { outcomeTypesApi } from "src/api/endpoints";
import { OutcomeTypeCreate, OutcomeTypeStatusEnum } from "src/clients/flow-api";

const PREFIX_CACHE_KEY = "outcome-types";

export const useOutcomeTypes = (flowId: string) => {
  return useQuery(
    [PREFIX_CACHE_KEY, flowId],
    async () =>
      (await outcomeTypesApi.getOutcomeTypesForFlowApiV1OutcomeTypesGet(flowId))
        .data,
    {
      enabled: !!flowId,
      refetchInterval: (data) => {
        const hasDeletingOutcome = data?.some(
          (outcome) => outcome.status === OutcomeTypeStatusEnum.DELETING,
        );
        return hasDeletingOutcome ? 10000 : false;
      },
    },
  );
};

export const useCreateOutcomeType = () => {
  const queryClient = useQueryClient();
  return useMutation(
    async (outcomeType: OutcomeTypeCreate) =>
      await outcomeTypesApi.createOutcomeTypeApiV1OutcomeTypesPost(outcomeType),
    {
      onSuccess: (_, outcomeType) => {
        queryClient.invalidateQueries([PREFIX_CACHE_KEY, outcomeType.flow_id]);
      },
    },
  );
};

type OutcomeTypeDelete = {
  flowId: string;
  id: string;
};

export const useDeleteOutcomeType = () => {
  const queryClient = useQueryClient();
  return useMutation(
    async ({ id }: OutcomeTypeDelete) =>
      await outcomeTypesApi.startOutcomeTypeDeletionApiV1OutcomeTypesIdDelete(
        id,
      ),
    {
      onSuccess: (_, outcomeType) => {
        queryClient.invalidateQueries([PREFIX_CACHE_KEY, outcomeType.flowId]);
      },
    },
  );
};
