import { IconProp } from "@fortawesome/fontawesome-svg-core";
import {
  faBolt,
  faChartLine,
  faCog,
  faLayerPlus,
  faSplit,
  faUserCircle,
  faWavePulse,
  faWebhook,
} from "@fortawesome/pro-regular-svg-icons";
import { faScaleUnbalancedFlip } from "@fortawesome/pro-solid-svg-icons";
import {
  NavLink,
  NavLinkProps,
  To,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { twJoin } from "tailwind-merge";
import { useLocalStorage } from "usehooks-ts";

import { FlowT, FlowVersionStatusT } from "src/api/flowTypes";
import { Icon } from "src/base-components/Icon";
import { Tooltip } from "src/base-components/Tooltip";
import { EditFlowModal } from "src/flowsOverview/EditFlowModal";
import { useSubmitFlowSettingsUpdate } from "src/flowsOverview/v2/useSubmitFlowSettingsUpdate";
import { useCapabilities } from "src/hooks/useCapabilities";
import { HashKeys } from "src/router/SearchParams";
import {
  appendCurrentFlags,
  FEATURE_FLAGS,
  isFeatureFlagEnabled,
} from "src/router/featureFlags";
import {
  FlowPageParamsT,
  getFlowSubpageUrl,
  getFlowVersionsUrl,
} from "src/router/urls";
import { useParamsDecode } from "src/utils/useParamsDecode";

type CustomNavLinkProps = NavLinkProps & {
  disabled?: boolean;
};

const CustomNavLink: React.FC<CustomNavLinkProps> = ({ disabled, ...rest }) => {
  if (disabled) {
    const disabledState = {
      isActive: false,
      isPending: false,
      isTransitioning: false,
    } as const;
    return (
      <span
        className={
          typeof rest.className === "function"
            ? rest.className(disabledState)
            : rest.className
        }
      >
        {typeof rest.children === "function"
          ? rest.children(disabledState)
          : rest.children}
      </span>
    );
  }

  return <NavLink {...rest} />;
};

type NavItemProps = {
  title: string;
  message?: string;
  to: To;
  icon: IconProp;
  disabled?: boolean;
  dataLoc?: string;
  collapsed?: boolean;
  neverShowActive?: boolean;
};

const NavItem: React.FC<NavItemProps> = ({
  disabled,
  dataLoc,
  title,
  message,
  to,
  icon,
  collapsed,
  neverShowActive = false,
}) => (
  <Tooltip
    activated={collapsed || Boolean(message)}
    align="center"
    body={message}
    placement="right"
    title={collapsed ? title : undefined}
    triggerClassName="cursor-default"
    asChild
  >
    <li>
      <CustomNavLink
        className={({ isActive }) =>
          twJoin(
            "flex items-center gap-x-2 rounded-lg p-1.5",
            isActive && !neverShowActive && "bg-gray-100",
            disabled ? "cursor-default" : "hover:bg-gray-50",
          )
        }
        data-loc={dataLoc}
        disabled={disabled}
        to={to}
        end
      >
        <Icon
          color={disabled ? "text-gray-300" : "text-gray-500"}
          icon={icon}
          size="xs"
        />
        {!collapsed && (
          <span
            className={twJoin(
              "font-inter-medium-13px",
              disabled ? "pointer-events-none text-gray-400" : "text-gray-800",
            )}
          >
            {title}
          </span>
        )}
      </CustomNavLink>
    </li>
  </Tooltip>
);

export const NavSidebar = ({ flow }: { flow?: FlowT }) => {
  const { flow_id: flowId, orgId, wsId } = useParamsDecode<FlowPageParamsT>();
  const navigate = useNavigate();
  const onSubmitFlowSettings = useSubmitFlowSettingsUpdate(wsId, flow);
  const [collapsed, setCollapsed] = useLocalStorage(
    "flowDetailNavigationCollapsed",
    false,
  );
  const hasActiveTrafficPolicies = Boolean(flow?.active_traffic_policy);
  const hasPublishedVersion = flow?.versions.some(
    (v) => v.status === FlowVersionStatusT.PUBLISHED,
  );
  const {
    decisionHistory,
    outgoingWebhooks,
    manualReview,
    flowVersions,
    jobs,
    outcomes,
  } = useCapabilities();

  const currentPageUrl = useLocation();

  return (
    <aside
      className={twJoin(
        "z-[1] overflow-auto bg-white px-2.5 py-3.5 shadow-base",
        !collapsed && "w-52 flex-shrink-0",
      )}
    >
      <nav className="h-full" data-loc="flow-subnavigation">
        <ul className="flex h-full flex-col gap-y-2">
          {flowVersions.canAccess && (
            <NavItem
              collapsed={collapsed}
              dataLoc="versions"
              icon={faLayerPlus}
              title="Versions"
              to={getFlowVersionsUrl(orgId, wsId, flowId)}
            />
          )}
          {flowVersions.canAccess && (
            <NavItem
              collapsed={collapsed}
              disabled={!hasActiveTrafficPolicies}
              icon={faSplit}
              message={
                hasActiveTrafficPolicies
                  ? undefined
                  : "This flow doesn't use traffic policies yet."
              }
              title="Routing policies"
              to={getFlowSubpageUrl(orgId, wsId, flowId, "traffic")}
            />
          )}
          {manualReview.canAccess && (
            <NavItem
              collapsed={collapsed}
              icon={faUserCircle}
              title="Review queue"
              to={getFlowSubpageUrl(orgId, wsId, flowId, "review-queue")}
            />
          )}
          {decisionHistory.canAccess && (
            <NavItem
              collapsed={collapsed}
              dataLoc="decision-history"
              icon={faWavePulse}
              title="Decision history"
              to={getFlowSubpageUrl(orgId, wsId, flowId, "history")}
            />
          )}
          {flowVersions.canAccess && (
            <NavItem
              collapsed={collapsed}
              disabled={!hasPublishedVersion}
              icon={faChartLine}
              message={
                hasPublishedVersion
                  ? undefined
                  : "This flow doesn't have published versions yet."
              }
              title="Performance"
              to={getFlowSubpageUrl(orgId, wsId, flowId, "performance")}
            />
          )}
          {outgoingWebhooks.canAccess && (
            <NavItem
              collapsed={collapsed}
              dataLoc="webhooks"
              icon={faWebhook}
              title="Webhooks"
              to={getFlowSubpageUrl(orgId, wsId, flowId, "webhooks")}
            />
          )}
          {jobs.canAccess && (
            <NavItem
              collapsed={collapsed}
              dataLoc="jobs"
              icon={faBolt}
              title="Jobs"
              to={getFlowSubpageUrl(orgId, wsId, flowId, "jobs")}
            />
          )}
          {outcomes.canAccess &&
            isFeatureFlagEnabled(FEATURE_FLAGS.outcomes) && (
              <NavItem
                collapsed={collapsed}
                dataLoc="outcomes"
                icon={faScaleUnbalancedFlip}
                title="Outcomes"
                to={getFlowSubpageUrl(orgId, wsId, flowId, "outcomes")}
              />
            )}
          {flowVersions.canEdit && (
            <NavItem
              collapsed={collapsed}
              dataLoc="flow-settings"
              icon={faCog}
              title="Settings"
              to={appendCurrentFlags(currentPageUrl.pathname) + HashKeys.Edit}
              neverShowActive
            />
          )}

          <li
            className="h-full min-h-[32px] grow"
            onClick={() => setCollapsed((prev) => !prev)}
          />
        </ul>
      </nav>
      <EditFlowModal
        flow={flow}
        mode="edit"
        open={!!flow && window.location.hash === HashKeys.Edit}
        organizationId={orgId}
        title="Edit Decision Flow"
        workspaceId={wsId}
        onClose={() => {
          navigate(appendCurrentFlags(currentPageUrl.pathname));
        }}
        onConfirm={onSubmitFlowSettings}
      />
    </aside>
  );
};
