import { DecisionHistoryRecordV2 } from "src/api/decisionHistoryV2/decisionHistoryQueries";
import { NodeBET } from "src/api/flowTypes";
import { useReviewCases } from "src/api/queries";
import { Button } from "src/base-components/Button";
import { Pill } from "src/base-components/Pill";
import { InspectDataBase } from "src/dataTable/DetailedView/tabs/InspectData";
import { ShortDateFromNow } from "src/flowReview/ReviewLogEntry";
import { UserTip } from "src/flowReview/ReviewStatusPills";
import { ReviewCaseStatus } from "src/manualReview/ReviewCaseStatus";
import { useWorkspaceContext } from "src/router/routerContextHooks";
import { getBaseUrl, getUrlToReviewCase } from "src/router/urls";
import { formatDate } from "src/utils/datetime";

export const PendingManualReviewOutput: React.FC<{
  node: NodeBET;
  decision: DecisionHistoryRecordV2;
}> = ({ decision, node }) => {
  const { workspace, orgId } = useWorkspaceContext();
  const { data, isLoading } = useReviewCases(
    workspace.base_url!,
    decision.flow.slug,
    {
      entity_or_decision_id: decision.id,
      environment: decision.is_sandbox ? "sandbox" : "prod",
    },
  );

  // there can be only one case for a decision at any given time
  const reviewCase = data?.pages.at(0)?.cases.at(0);

  if (isLoading) {
    return (
      <div className="px-4">
        <InspectDataBase
          accessedFields={[]}
          rowData={undefined}
          selectedRowIndex={null}
          withAccessedFields={false}
          dividers
          isFetching
          isHistorical
        />
      </div>
    );
  }

  const rows: [string, React.ReactNode][] = [
    ["Request time", formatDate(decision.start_time)],
    ["Needs review since", <ShortDateFromNow date={decision.end_time} />],
    [
      "Assigned to",
      reviewCase?.assignee ? (
        <UserTip orgId={orgId} userId={reviewCase.assignee} />
      ) : (
        "-"
      ),
    ],
    [
      "Version",
      <Pill size="sm" variant="gray">
        <Pill.Text>{decision.flow.version_name}</Pill.Text>
      </Pill>,
    ],
    ["Node", node.name],
    ["Status", reviewCase && <ReviewCaseStatus status={reviewCase?.status} />],
  ];

  return (
    <div className="flex flex-col gap-y-4 px-4">
      <div>
        {rows.map(([label, value]) => (
          <div
            key={label}
            className="flex border-b border-gray-100 text-gray-800 font-inter-medium-12px"
          >
            <div className="w-36 shrink-0 p-1.5">{label}</div>
            <div className="flex flex-grow items-center gap-x-1 p-1.5">
              {value}
            </div>
          </div>
        ))}
      </div>
      {reviewCase && (
        <div>
          <Button size="sm">
            <a
              href={
                getBaseUrl() +
                getUrlToReviewCase(
                  orgId,
                  workspace.id,
                  decision.flow.id,
                  reviewCase.id,
                )
              }
              rel="noreferrer"
              target="_blank"
            >
              View review case
            </a>
          </Button>
        </div>
      )}
    </div>
  );
};
