import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { useMergeRefs } from "@floating-ui/react";
import {
  faArrowUpRightFromSquare,
  faChartSimple,
  faEdit,
  faExclamationCircle,
  faTrash,
  faWarning,
} from "@fortawesome/pro-regular-svg-icons";
import { faGripDotsVertical } from "@fortawesome/pro-solid-svg-icons";
import { isEmpty } from "lodash";
import { useEffect, useRef } from "react";
import { twJoin } from "tailwind-merge";
import { useSessionStorage } from "usehooks-ts";

import {
  useChartModalActions,
  useOpenEditChartModal,
} from "src/analytics/ChartModal/store";
import { usePopoutChart } from "src/analytics/FloatingChart/FloatingChart";
import { DEFAULT_CHART_TITLE } from "src/analytics/constants";
import { usePatchChart } from "src/analytics/queries";
import {
  Chart,
  ObservableChart as ObservableChartType,
} from "src/analytics/types";
import {
  AnalyticsKey,
  isObservableChart,
  getObservableChartSpecFromChart,
  hasVersionBreakdown,
  VERSION_KEY,
} from "src/analytics/utils";
import { ObservableChart } from "src/analytics/visualisations/ObservableChart";
import { SummaryChart } from "src/analytics/visualisations/SummaryChart";
import { GenericObjectT } from "src/api/flowTypes";
import { Button } from "src/base-components/Button";
import { EmptyState } from "src/base-components/EmptyState";
import { Icon } from "src/base-components/Icon";
import { Pill } from "src/base-components/Pill";
import { Tooltip } from "src/base-components/Tooltip";
import { ChartResourceType } from "src/clients/flow-api";
import { useCapabilities } from "src/hooks/useCapabilities";
import {
  tracker,
  trackingEvents,
} from "src/instrumentation/customTrackingEvents";
import { FEATURE_FLAGS, isFeatureFlagEnabled } from "src/router/featureFlags";
import { useAuthoringContext } from "src/router/routerContextHooks";
import { useCurrentUserId } from "src/store/AuthStore";
import { useNodeRunState } from "src/store/runState/RunState";
import { ErrorBoundary } from "src/utils/ErrorBoundary";
import * as logger from "src/utils/logger";

export const AnalyticsChartBase: React.FC<{
  data: GenericObjectT[];
  keys: AnalyticsKey[];
  chart: Chart;
  fitContainer?: boolean;
  isLoading?: boolean;
}> = ({ data, chart, isLoading, fitContainer, keys }) => {
  useTrackChartView({ chart });

  if (!isObservableChart(chart)) {
    return (
      <SummaryChart data={data} isLoading={isLoading} spec={chart.dimensions} />
    );
  }

  const spec = getObservableChartSpecFromChart(chart, keys);

  return (
    <ObservableChart
      data={data}
      fitContainer={fitContainer}
      isLoading={isLoading}
      spec={spec}
    />
  );
};

type AnalyticsChartProps = {
  chart: Chart;
  data?: GenericObjectT[];
  keys: AnalyticsKey[];
  onDelete: (chart: Chart) => void;
  isLoading?: boolean;
  dataLoc?: string;
  isMultiversion: boolean;
};

export const AnalyticsChart = ({
  chart,
  data = [],
  onDelete,
  isLoading,
  keys,
  dataLoc,
  isMultiversion,
}: AnalyticsChartProps) => {
  const targetRef = useRef<HTMLDivElement>(null);
  const {
    attributes,
    listeners,
    setNodeRef,
    setActivatorNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id: chart.id });
  const containerRef = useMergeRefs([setNodeRef, targetRef]);

  const open = usePopoutChart();

  const style = {
    transform: CSS.Translate.toString(transform),
    transition,
  };

  const { flowCharts } = useCapabilities();

  return (
    <div
      ref={containerRef}
      className={twJoin(
        "group relative flex flex-col gap-y-2.5 rounded-lg border border-gray-200 bg-white px-4 pb-2.5 pt-3",
        isDragging && "z-10 shadow-xl",
      )}
      data-loc={dataLoc}
      style={style}
    >
      <ErrorBoundary
        fallbackErrorContent={
          <EmptyState
            description="We're having trouble rendering this chart"
            headline="Chart rendering error"
            icon={faExclamationCircle}
            variant="error"
          />
        }
      >
        <div className="flex">
          <div className="flex-1">
            <p className="text-gray-800 font-inter-semibold-13px">
              {chart.title ?? DEFAULT_CHART_TITLE}
            </p>
            {chart.description && (
              <p className="text-gray-500 font-inter-normal-12px">
                {chart.description}
              </p>
            )}
          </div>
          <div className="flex items-start gap-x-1 opacity-0 transition-opacity group-hover:opacity-100">
            {isFeatureFlagEnabled(FEATURE_FLAGS.popoutCharts) && (
              <Icon
                color="text-gray-500 hover:text-gray-700"
                dataLoc={`${dataLoc}-popout`}
                icon={faArrowUpRightFromSquare}
                size="xs"
                onClick={() =>
                  open(
                    {
                      id: chart.id,
                      resourceId: chart.resource_id,
                      resourceType: chart.resource_type as ChartResourceType,
                    },
                    targetRef,
                  )
                }
              />
            )}
            <EditChartIcon
              chart={chart}
              chartingData={data}
              chartingKeys={keys}
              dataLoc={dataLoc}
            />
            {flowCharts.canDelete && (
              <Icon
                color="text-gray-500 hover:text-gray-700"
                dataLoc={`${dataLoc}-delete`}
                icon={faTrash}
                size="xs"
                onClick={() => onDelete(chart)}
              />
            )}
          </div>
        </div>
        <div
          className={twJoin(
            "w-full",
            isObservableChart(chart) && "aspect-video",
          )}
        >
          <ChartRenderer
            key={chart.id}
            chart={chart}
            data={data}
            isLoading={isLoading}
            isMultiversion={isMultiversion}
            keys={keys}
          />
        </div>
        <button
          {...attributes}
          {...listeners}
          ref={setActivatorNodeRef}
          className={twJoin(
            "absolute left-0 top-3 hidden h-5 w-[15px] -translate-x-1/2 items-center justify-center rounded border border-gray-200 bg-white group-hover:flex",
            isDragging ? "cursor-grabbing" : "cursor-grab",
          )}
          data-loc={`${dataLoc}-drag`}
        >
          <Icon color="text-gray-500" icon={faGripDotsVertical} size="2xs" />
        </button>
      </ErrorBoundary>
    </div>
  );
};

const NoVersionBrakedownButton: React.FC<{
  chart: ObservableChartType;
}> = ({ chart }) => {
  const { mutateAsync: patchChart, isLoading: isPatchingChart } = usePatchChart(
    chart.resource_type!,
    chart.resource_id,
  );

  const openEditChartModal = useOpenEditChartModal();

  const makeChartBreakDownByVersion = async () => {
    try {
      await patchChart({
        chartId: chart.id,
        chart: {
          dimensions: {
            ...chart.dimensions,
            fx: {
              value: VERSION_KEY,
            },
          },
        },
        etag: chart.etag,
      });
    } catch (error) {
      logger.error("Error making chart breakdown by version", error);
    }
  };

  return isEmpty(chart.dimensions.fx.value) ? (
    <Button
      iconLeft={faEdit}
      loading={isPatchingChart}
      variant="secondary"
      onClick={makeChartBreakDownByVersion}
    >
      Add version comparison
    </Button>
  ) : (
    <Button
      iconLeft={faEdit}
      loading={isPatchingChart}
      variant="secondary"
      onClick={() => openEditChartModal(chart)}
    >
      Edit chart
    </Button>
  );
};

export const ChartRenderer = ({
  chart,
  data,
  keys,
  isLoading,
  fitContainer,
  isMultiversion,
}: {
  chart: Chart;
  data: GenericObjectT[];
  keys: AnalyticsKey[];
  isLoading?: boolean;
  fitContainer?: boolean;
  isMultiversion: boolean;
}) => {
  const thereIsNoData = data.length === 0;
  const missingChartKeys = getMissingChartKeys(chart.dimensions, keys);

  const isInvalidAnalyticsData = !!missingChartKeys.length;

  if (
    isObservableChart(chart) &&
    !hasVersionBreakdown(chart) &&
    isMultiversion
  ) {
    return (
      <EmptyState
        action={<NoVersionBrakedownButton chart={chart} />}
        description={
          <>
            Comparing data across versions without proper configuration could
            lead to misinterpretation. To view the data correctly, please edit
            the chart settings and add{" "}
            <Pill size="sm" variant="gray">
              <Pill.Text>Version</Pill.Text>
            </Pill>{" "}
            to the comparison.
          </>
        }
        headline="Version comparison error"
        icon={faWarning}
        variant="error"
      />
    );
  }

  if (!isLoading) {
    if (thereIsNoData) {
      return (
        <EmptyState
          description="Initiate a test run to derive insights from your data."
          headline="No analytics data available"
          icon={faChartSimple}
        />
      );
    }

    if (isInvalidAnalyticsData) {
      const missingChartKeysFormatted = new Intl.ListFormat().format(
        missingChartKeys,
      );

      return (
        <EmptyState
          description={`Data required for this chart is missing in the most recent test run: ${missingChartKeysFormatted}`}
          headline="Incompatible Data"
          icon={faChartSimple}
          variant="error"
        />
      );
    }
  }

  return (
    <AnalyticsChartBase
      key={chart.id}
      chart={chart}
      data={data}
      fitContainer={fitContainer}
      isLoading={isLoading}
      keys={keys}
    />
  );
};

const getMissingChartKeys = (
  dimensions: Chart["dimensions"],
  keys: AnalyticsKey[],
) => {
  const chartKeys: string[] =
    // If it's a summary chart, we only need the field
    "field" in dimensions
      ? [dimensions.field].filter(Boolean)
      : Object.values(dimensions)
          .map(({ value }) => value)
          .filter(Boolean);
  return chartKeys.filter((key) => !keys.map((k) => k.actualKey).includes(key));
};

export const EditChartIcon: React.FC<{
  chart: Chart;
  chartingData: GenericObjectT[];
  chartingKeys: AnalyticsKey[];
  dataLoc?: string;
}> = ({ chart, chartingData, chartingKeys, dataLoc }) => {
  const { flowCharts } = useCapabilities();
  const { open } = useChartModalActions();

  if (!flowCharts.canEdit) {
    return null;
  }

  const missingChartKeys = getMissingChartKeys(chart.dimensions, chartingKeys);
  const isInvalidAnalyticsData = !!missingChartKeys.length;

  const isEditingDisabled = chartingData.length === 0 || isInvalidAnalyticsData;

  return (
    <Tooltip
      activated={isEditingDisabled}
      body={
        isInvalidAnalyticsData
          ? "You need compatible analytics data to edit this chart"
          : "Initiate a test run to enable editing feature"
      }
      placement="top"
      title=""
      triggerClassName="flex items-start"
      asChild
    >
      <Icon
        color={
          isEditingDisabled
            ? "text-gray-400"
            : "text-gray-500 hover:text-gray-700"
        }
        dataLoc={`${dataLoc}-edit`}
        disabled={isEditingDisabled}
        icon={faEdit}
        size="xs"
        onClick={() => {
          if (chart.resource_type) {
            open(
              chart.resource_type === ChartResourceType.FLOW
                ? {
                    type: "flow",
                    chart,
                  }
                : {
                    type: "node",
                    nodeId: chart.resource_id,
                    chart,
                  },
            );
          } else {
            logger.error("No resource type for chart", chart);
          }
        }}
      />
    </Tooltip>
  );
};

const useTrackChartView = ({ chart }: { chart: Chart }) => {
  // To double check we do not track the same chart data multiple times
  const [lastTrackedRunId, setLastTrackedRunId] = useSessionStorage<
    string | null
  >(`last-tracked-run-id-${chart.id}`, null);
  const { orgId, flow, version } = useAuthoringContext();
  const userId = useCurrentUserId();
  const runState = useNodeRunState("output");

  const testRunId =
    runState?.type === "test-run" ? runState.testResult.test_run_id : null;

  useEffect(() => {
    if (
      chart.id &&
      userId &&
      flow.id &&
      version.id &&
      orgId &&
      testRunId &&
      testRunId !== lastTrackedRunId
    ) {
      setLastTrackedRunId(testRunId);
      tracker.emit(
        trackingEvents.chartView({
          chart_id: chart.id,
          user_id: userId,
          test_run_id: testRunId,
          flow_id: flow.id,
          flow_version_id: version.id,
          organization_id: orgId,
        }),
      );
    }
  }, [
    chart.id,
    flow.id,
    orgId,
    userId,
    version.id,
    testRunId,
    lastTrackedRunId,
    setLastTrackedRunId,
  ]);
};
