import { faClone, faSearch } from "@fortawesome/pro-regular-svg-icons";
import { isObject, times } from "lodash";
import { ReactNode } from "react";
import { twJoin } from "tailwind-merge";
import { useSessionStorage } from "usehooks-ts";

import { EmptyState } from "src/base-components/EmptyState";
import { PillToggle } from "src/base-components/PillToggle";
import { SkeletonPlaceholder } from "src/base-components/SkeletonPlaceholder";
import { Tooltip } from "src/base-components/Tooltip";
import { JSONValueViewer } from "src/dataTable/DetailedView/JSONValueViewer";
import {
  ExpectedDataPopover,
  MatchPill,
  MismatchPill,
} from "src/dataTable/ExpectedDataCell";
import { hasExpectedData } from "src/dataTable/TableUtils";
import { ResultDataAndAuxRowV2 } from "src/dataTable/types";
import { useSelectedResultsRowIndex } from "src/flowContainer/AuthoringUIContext";
import { INTERNAL_DATA_KEYS } from "src/parentFlowNodes/loopNode/constants";
import { copyTextToClipboard } from "src/utils/clipboard";
import { formatDate, isISO8601 } from "src/utils/datetime";
import { speakPythonPrimitive } from "src/utils/speakPython";

type InspectDataProps = {
  row?: ResultDataAndAuxRowV2 | undefined;
  rowData: Record<string, unknown> | undefined;
  accessedFields: string[];
  isFetching: boolean;
  isHistorical?: boolean;
  withAccessedFields: boolean;
  selectedRowIndex: number | null;
  dividers?: boolean;
};

export const InspectData: React.FC<
  Omit<InspectDataProps, "selectedRowIndex">
> = (props) => {
  const selectedRowIndex = useSelectedResultsRowIndex();

  return <InspectDataBase {...props} selectedRowIndex={selectedRowIndex} />;
};

export const InspectDataBase: React.FC<InspectDataProps> = ({
  row,
  rowData,
  accessedFields,
  isFetching,
  isHistorical,
  withAccessedFields,
  selectedRowIndex,
  dividers = false,
}) => {
  if (isFetching || !rowData) {
    return (
      <InspectDataList
        accessedFields={times(10).map((index) => String(index))}
        dividers={dividers}
        rows={times(10).map((index) => [
          String(index),
          <SkeletonPlaceholder height="h-4" width="w-24" />,
          <SkeletonPlaceholder height="h-4" width="w-80" />,
        ])}
        selectedRowIndex={selectedRowIndex}
        withAccessedFields={withAccessedFields}
      />
    );
  }

  const isOutputRow = row && hasExpectedData(row);

  return (
    <InspectDataList
      accessedFields={accessedFields}
      dividers={dividers}
      isHistorical={isHistorical}
      rows={Object.entries(rowData)
        .filter(([key]) => !INTERNAL_DATA_KEYS.includes(key))
        .map(([key, value]) => [
          key,
          key,
          <DataValue
            expectedOutputData={
              isOutputRow ? row?.expectedOutputData : undefined
            }
            expectedOutputKeysMismatch={
              isOutputRow ? row?.expectedOutputKeysMismatch : undefined
            }
            field={key}
            value={value}
          />,
        ])}
      selectedRowIndex={selectedRowIndex}
      withAccessedFields={withAccessedFields}
    />
  );
};

enum InspectDataListView {
  ALL_FIELDS = "all_fields",
  ACCESSIBLE_FIELDS = "accessible_fields",
}

const InspectDataList: React.FC<{
  rows: [string, ReactNode, ReactNode][];
  accessedFields: string[];
  isHistorical?: boolean;
  withAccessedFields: boolean;
  selectedRowIndex: number | null;
  dividers?: boolean;
}> = ({
  rows,
  accessedFields,
  isHistorical,
  withAccessedFields,
  selectedRowIndex,
  dividers = false,
}) => {
  const [selectedField, setSelectedField] =
    useSessionStorage<InspectDataListView>(
      "inspect-data-list-view",
      InspectDataListView.ALL_FIELDS,
    );

  const filteredRows = withAccessedFields
    ? rows.filter(
        ([id]) =>
          selectedField === InspectDataListView.ALL_FIELDS ||
          (selectedField === InspectDataListView.ACCESSIBLE_FIELDS &&
            accessedFields?.includes(id)),
      )
    : rows;

  return (
    <div
      className="flex h-full min-h-0 flex-1 flex-col gap-y-2"
      data-loc="inspect-data-fields"
    >
      {withAccessedFields && (
        <PillToggle value={selectedField} onChange={setSelectedField}>
          <PillToggle.Button value={InspectDataListView.ALL_FIELDS}>
            All fields
          </PillToggle.Button>
          <PillToggle.Button value={InspectDataListView.ACCESSIBLE_FIELDS}>
            Accessed fields
          </PillToggle.Button>
        </PillToggle>
      )}

      <div className="decideScrollbar h-full min-h-0 flex-1 pr-4">
        {filteredRows.length > 0 ? (
          filteredRows.map(([id, key, value]) => (
            <div
              key={id}
              className={twJoin(
                "flex max-w-full items-start gap-x-2 text-gray-800 last:mb-2",
                dividers && "border-b border-b-gray-100",
              )}
            >
              <div className="w-32 shrink-0 truncate p-1.5 font-inter-normal-12px">
                <Tooltip
                  action={{
                    icon: faClone,
                    onClick: () => {
                      if (typeof key === "string") {
                        copyTextToClipboard(key);
                      }
                    },
                  }}
                  activated={typeof key === "string"}
                  align="center"
                  delayDuration={500}
                  placement="top"
                  title={key}
                  asChild
                >
                  <span className="block w-full truncate">{key}</span>
                </Tooltip>
              </div>
              <div className="flex min-w-0 flex-1 p-1.5 font-inter-normal-12px">
                {value}
              </div>
            </div>
          ))
        ) : selectedField === InspectDataListView.ACCESSIBLE_FIELDS ? (
          <EmptyState
            description={
              isHistorical
                ? `This node did not access any fields for this decision`
                : `This node did not access any fields for #${
                    (selectedRowIndex || 0) + 1
                  } test case during the last test run`
            }
            headline="No fields accessed"
            icon={faSearch}
          />
        ) : (
          <EmptyState
            description={
              isHistorical
                ? "This decision had no fields for this decision"
                : "This test case had no fields during the last test run"
            }
            headline="No fields found"
            icon={faSearch}
          />
        )}
      </div>
    </div>
  );
};

const DataValue: React.FC<{
  field: string;
  value: unknown;
  expectedOutputData?: Record<string, unknown>;
  expectedOutputKeysMismatch?: string[];
}> = ({ field, value, expectedOutputData, expectedOutputKeysMismatch }) => {
  const isRowWithExpectedData = expectedOutputData?.[field] !== undefined;
  const isMatch =
    isRowWithExpectedData && !expectedOutputKeysMismatch?.includes(field);

  if (Array.isArray(value) || isObject(value)) {
    return (
      <div className="flex w-full flex-col items-start gap-y-1.5">
        <JSONValueViewer field={field} value={value} />

        {isRowWithExpectedData && (
          <ExpectedDataPopover
            actualOutput={{
              value: JSON.stringify(value),
              isMatch,
            }}
            expectedOutput={{
              value: JSON.stringify(expectedOutputData?.[field]),
              isMatch,
            }}
          >
            {isMatch ? <MatchPill /> : <MismatchPill />}
          </ExpectedDataPopover>
        )}
      </div>
    );
  }

  const displayValue = (() => {
    if (typeof value === "number") {
      return value;
    }
    if (typeof value === "boolean" || value === null) {
      return speakPythonPrimitive(String(value));
    }

    if (typeof value === "string" && isISO8601(value)) {
      // Naive check for date vs datetime
      if (value.length === 10) {
        return formatDate(value, "d MMMM yyyy");
      }

      return formatDate(value, "d MMMM yyyy, h:mm aaa");
    }

    return JSON.stringify(value);
  })();

  return (
    <div className="flex w-full items-center justify-between">
      <Tooltip
        action={{
          icon: faClone,
          onClick: () => {
            copyTextToClipboard(String(value));
          },
        }}
        align="center"
        delayDuration={500}
        placement="top"
        title={displayValue}
        asChild
      >
        <span className="line-clamp-5">{displayValue}</span>
      </Tooltip>

      {isRowWithExpectedData && (
        <ExpectedDataPopover
          actualOutput={{
            value: String(value),
            isMatch,
          }}
          expectedOutput={{
            value: String(expectedOutputData?.[field]),
            isMatch,
          }}
        >
          {isMatch ? <MatchPill /> : <MismatchPill />}
        </ExpectedDataPopover>
      )}
    </div>
  );
};
