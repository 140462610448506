import React, { useCallback } from "react";
import { Controller, useFormContext } from "react-hook-form";

import { ConnectionT } from "src/api/connectApi/types";
import { Card } from "src/base-components/Card";
import { InformationPill } from "src/base-components/InformationPill";
import { Input } from "src/base-components/Input";
import { Switch } from "src/base-components/Switch";
import { Tooltip } from "src/base-components/Tooltip";
import { DataRetentionUnit } from "src/connections/types";
import { DOCS_RETENTION_POLICIES } from "src/constants/ExternalLinks";
import { DataRetentionUnitDropdown } from "src/layout/WorkspaceFormModal/DataRetentionUnitDropdown";
import {
  convertPeriodValueToSeconds,
  convertSecondsToRetentionPeriodValue,
  retentionPeriodToMinutes,
} from "src/layout/WorkspaceFormModal/utils";

type DataRetentionConfigPropsT = {
  connection: ConnectionT;
  immutable: boolean;
};

type DataRetentionConfigFormT = {
  config: {
    caching: {
      active: boolean;
      max_age_seconds: string;
      // Used only for displaying in the UI
      // to properly convert seconds to the correct unit
      unit?: DataRetentionUnit;
    };
  };
};

export const DataRetentionConfig: React.FC<DataRetentionConfigPropsT> = ({
  connection,
  immutable,
}) => {
  const cachingIsDisabledOnConnection =
    connection.data_retention == null || connection.data_retention.value === 0;
  const { getValues, setValue, formState } =
    useFormContext<DataRetentionConfigFormT>();

  const timePeriodValidation = useCallback(
    (maxAgeSeconds: string) => {
      const connectionRetentionPeriod = retentionPeriodToMinutes(
        connection.data_retention!,
      );
      const nodeRetentionPeriod = parseInt(maxAgeSeconds) / 60;
      if (nodeRetentionPeriod > connectionRetentionPeriod) {
        return "Larger time period than specified in the Connection retention policy";
      }

      return true;
    },
    [connection.data_retention],
  );

  return (
    <>
      <div className="mb-2.5 flex items-center justify-between">
        <div>
          <h3 className="text-gray-800 font-inter-medium-12px">
            Make use of cached responses
          </h3>
          <span className="text-gray-500 font-inter-normal-12px">
            Use stored reports when available for faster, cost-free data
          </span>
        </div>
        <Controller
          name="config.caching.active"
          render={(props) => (
            <Tooltip
              activated={cachingIsDisabledOnConnection}
              align="center"
              placement="top"
              title="This Connection does not have caching enabled. Contact your workspace admin to enable caching for this Connection."
              triggerAs="div"
            >
              <Switch
                dataLoc="base-connection-node-caching-active"
                disabled={immutable || cachingIsDisabledOnConnection}
                enabled={props.field.value && !cachingIsDisabledOnConnection}
                onChange={() => {
                  props.field.onChange(!props.field.value);
                }}
              />
            </Tooltip>
          )}
        />
      </div>
      {getValues("config.caching.active") && (
        <Card>
          <div className="flex items-center gap-x-2">
            <div className="mr-2 flex flex-1 shrink-0 text-gray-800 font-inter-medium-12px">
              Use cached responses from the past
            </div>
            <div className="max-w-32 flex-1">
              <Controller
                name="config.caching.max_age_seconds"
                render={({ fieldState, field: { value, onChange } }) => {
                  const unit = getValues("config.caching.unit") ?? "days";

                  return (
                    <Input
                      data-loc="base-connection-node-caching-max-age-seconds"
                      disabled={immutable}
                      errored={Boolean(fieldState.error)}
                      type="number"
                      value={convertSecondsToRetentionPeriodValue(
                        parseInt(value),
                        unit,
                      )}
                      fullWidth
                      onChange={(e) => {
                        onChange(
                          String(
                            convertPeriodValueToSeconds(
                              parseInt(e.target.value),
                              unit,
                            ),
                          ),
                        );
                      }}
                    />
                  );
                }}
                rules={{
                  required: true,
                  min: 0,
                  validate: {
                    timePeriod: timePeriodValidation,
                  },
                }}
              />
            </div>
            <div className="w-1/4 shrink-0">
              <Controller
                name="config.caching.unit"
                render={({ field: { value, onChange } }) => {
                  const handleChange = (unit: string) => {
                    // As we persist value in seconds, we need to convert it to the new unit
                    const maxAgeSeconds = getValues(
                      "config.caching.max_age_seconds",
                    );
                    const currentPeriodValue =
                      convertSecondsToRetentionPeriodValue(
                        parseInt(maxAgeSeconds),
                        value ?? "days",
                      );
                    const newMaxAgeSeconds = convertPeriodValueToSeconds(
                      currentPeriodValue,
                      unit as DataRetentionUnit,
                    );

                    onChange(unit);
                    setValue(
                      "config.caching.max_age_seconds",
                      String(newMaxAgeSeconds),
                      { shouldValidate: true },
                    );
                  };
                  return (
                    <DataRetentionUnitDropdown
                      dataLoc="base-connection-node-caching-unit"
                      disabled={immutable}
                      value={value ?? "days"}
                      onChange={handleChange}
                    />
                  );
                }}
                rules={{
                  deps: ["config.caching.max_age_seconds"],
                }}
              />
            </div>
          </div>
          {formState.errors.config?.caching?.max_age_seconds?.type ===
            "timePeriod" && (
            <InformationPill
              action={{
                text: "Read more",
                onClick: () => window.open(DOCS_RETENTION_POLICIES, "_blank"),
              }}
              className="mt-3"
              type="warning"
            >
              <div>
                You've selected a larger time period than specified in the
                Connection caching retention policy (
                <span className="font-inter-medium-12px">
                  {connection.data_retention?.value}{" "}
                  {connection.data_retention?.unit}
                </span>
                ). Contact your workspace admin if you'd like to change the
                caching policy.
              </div>
            </InformationPill>
          )}
        </Card>
      )}
    </>
  );
};
