import { DecisionHistoryRecordV2 } from "src/api/decisionHistoryV2/decisionHistoryQueries";
import { FlowVersionT } from "src/api/flowTypes";
import { useFlowVersion } from "src/api/flowVersionQueries";
import { InformationPill } from "src/base-components/InformationPill";
import { LoadingView } from "src/base-components/LoadingView";
import { NodeTypes } from "src/clients/flow-api";
import { PendingManualReviewOutput } from "src/decisionsOverview/PendingManualReviewOutput";
import { findPendingNode } from "src/decisionsOverview/utils";
import { LeftPaneOptions } from "src/router/SearchParams";
import { useWorkspaceContext } from "src/router/routerContextHooks";
import { getBaseUrl, getUrlToAuthoringPage } from "src/router/urls";

export const PendingOutputTabContent: React.FC<{
  decision: DecisionHistoryRecordV2;
}> = ({ decision }) => {
  const { orgId, workspace } = useWorkspaceContext();
  const flowVersionQuery = useFlowVersion(decision.flow.version_id);
  return (
    <LoadingView
      queryResult={flowVersionQuery}
      renderUpdated={(flowVersion: FlowVersionT) => {
        if (flowVersion.graph) {
          const pendingNode = findPendingNode(
            decision,
            flowVersion.graph.nodes,
            flowVersion.graph.edges,
          );
          if (pendingNode?.type === NodeTypes.REVIEW_CONNECTION_NODE) {
            return (
              <PendingManualReviewOutput
                decision={decision}
                node={pendingNode}
              />
            );
          }
          if (pendingNode) {
            return (
              <div className="px-4">
                <InformationPill
                  action={{
                    text: "View Node",
                    onClick: () => {
                      const params = {
                        selectedNodeId: pendingNode.id,
                        decsiionId: decision.id,
                        leftPane: LeftPaneOptions.DecisionHistory,
                      } as const;
                      const url =
                        getBaseUrl() +
                        getUrlToAuthoringPage(
                          orgId,
                          workspace.id,
                          decision.flow.id,
                          flowVersion.id,
                          params,
                        );
                      window.open(url, "_blank");
                    },
                  }}
                  type="success"
                >
                  <p>
                    Waiting for the response from {pendingNode.name} to complete
                    this decision
                  </p>
                </InformationPill>
              </div>
            );
          }
        }
        return <></>;
      }}
    />
  );
};
