import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { Menu } from "@headlessui/react";
import { AnimatePresence, m } from "framer-motion";
import { Ref, forwardRef } from "react";
import { usePopper } from "react-popper";
import { twJoin } from "tailwind-merge";

import { Icon } from "src/base-components/Icon";
import { Tooltip } from "src/base-components/Tooltip";

type DividerElement = { key: "divider"; action?: undefined };
type HeaderElement = { key: "header"; action?: undefined; header: string };
type NavElement = {
  key: "nav";
  nav: string;
  action: () => void;
  icon?: IconProp;
  suffix?: IconProp;
  subtitle?: React.ReactNode;
};

export type DropdownElement = {
  key: string;
  action: () => void;
  icon?: IconProp;
  disabled?: boolean | string;
  suffix?: IconProp;
  subtitle?: React.ReactNode;
};

export type OptionsDropdownElement =
  | DropdownElement
  | DividerElement
  | HeaderElement
  | NavElement;

const isDivider = (
  element: OptionsDropdownElement,
): element is DividerElement => element === MENU_DIVIDER;

const isHeader = (element: OptionsDropdownElement): element is HeaderElement =>
  element.key === "header";

export const isNav = (element: OptionsDropdownElement): element is NavElement =>
  element.key === "nav";

export const MENU_DIVIDER: DividerElement = { key: "divider" };

const itemPaddings = "px-4 py-2.5";

type Props = {
  elements: OptionsDropdownElement[];
  open: boolean;
  popper: ReturnType<typeof usePopper>;
  getOnElementClick: (
    element: DropdownElement,
  ) => (event: React.MouseEvent<Element>) => void;
  timeout?: number;
};

export const OptionsDropdownItems = forwardRef<HTMLDivElement, Props>(
  (
    {
      elements,
      open,
      popper,
      getOnElementClick: onElementClick,
      timeout = 500,
    },
    ref: Ref<HTMLDivElement>,
  ) => {
    return (
      <AnimatePresence initial={false}>
        {open && (
          <Menu.Items
            as={m.div}
            {...popper.attributes.popper}
            ref={ref}
            className="z-50"
            style={popper.styles.popper}
            static
          >
            <m.div
              key="options-menu"
              animate="visible"
              className="min-w-[224px] max-w-[273px] overflow-hidden rounded-lg bg-white py-2 shadow-lg focus:outline-none"
              data-loc="options-menu"
              exit="hidden"
              initial="hidden"
              transition={{
                type: "tween",
                ease: "easeOut",
                duration: 0.15,
              }}
              variants={{
                visible: {
                  opacity: 1,
                  scale: 1,
                },
                hidden: {
                  opacity: 0,
                  scale: 0.95,
                  transition: {
                    delay: timeout / 1000,
                  },
                },
              }}
            >
              {elements.map((element: OptionsDropdownElement, index) =>
                isDivider(element) ? (
                  <hr
                    key={`divider-${index}`}
                    className="my-2.5 h-px border-none bg-gray-200"
                  />
                ) : isHeader(element) ? (
                  <Menu.Item
                    key={`header-${element.header}`}
                    as="div"
                    className={twJoin(
                      "cursor-default text-gray-800 font-inter-semibold-13px",
                      itemPaddings,
                    )}
                  >
                    {element.header}
                  </Menu.Item>
                ) : isNav(element) ? (
                  <Menu.Item
                    key={`nav-${element.nav}`}
                    as="button"
                    className={twJoin(
                      "group flex max-w-full flex-col gap-y-2 enabled:hover:bg-gray-50",
                      itemPaddings,
                    )}
                    onClick={onElementClick(element)}
                  >
                    <span className="flex w-full cursor-pointer items-center gap-x-2 text-gray-800 font-inter-semibold-13px">
                      {element.icon && (
                        <Icon
                          color="text-gray-500"
                          icon={element.icon}
                          size="sm"
                        />
                      )}
                      <span className="truncate">{element.nav}</span>
                      {element.suffix && (
                        <span className="ml-auto opacity-0 group-hover:opacity-100">
                          <Icon
                            color="text-gray-500"
                            icon={element.suffix}
                            size="sm"
                          />
                        </span>
                      )}
                    </span>
                    {element.subtitle && (
                      <span className="inline-block w-full text-left text-gray-600 font-inter-normal-12px">
                        {element.subtitle}
                      </span>
                    )}
                  </Menu.Item>
                ) : (
                  <Menu.Item key={element.key}>
                    {/* This div must exist to not have logger.warning about ref */}
                    <div>
                      <Tooltip
                        activated={typeof element.disabled === "string"}
                        body={element.disabled}
                        placement="right"
                        title=""
                        asChild
                      >
                        <button
                          className={twJoin(
                            "min-h-12 group flex w-full cursor-pointer items-center gap-x-2 text-left text-gray-800 font-inter-normal-13px enabled:hover:bg-gray-50 disabled:cursor-not-allowed disabled:text-gray-400",
                            itemPaddings,
                          )}
                          data-loc={element.key}
                          disabled={!!element.disabled}
                          onClick={onElementClick(element)}
                        >
                          {element.icon && (
                            <div className="self-start">
                              <Icon
                                color="text-gray-400"
                                icon={element.icon}
                                size="sm"
                              />
                            </div>
                          )}
                          <div className="min-w-0">
                            <p className="truncate">{element.key}</p>
                            {element.subtitle && (
                              <p className="mt-0.5 text-gray-600 font-inter-normal-12px">
                                {element.subtitle}
                              </p>
                            )}
                          </div>
                          {element.suffix && (
                            <span className="ml-auto opacity-0 group-hover:opacity-100">
                              <Icon
                                color="text-gray-500"
                                icon={element.suffix}
                                size="xs"
                              />
                            </span>
                          )}
                        </button>
                      </Tooltip>
                    </div>
                  </Menu.Item>
                ),
              )}
            </m.div>
          </Menu.Items>
        )}
      </AnimatePresence>
    );
  },
);
