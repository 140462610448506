import { capitalize } from "lodash";

import {
  JSONSchemaPropertyDefinition,
  ManifestIntegrationProvider,
  ManifestJSONSchema,
} from "src/api/connectApi/manifestTypes";
import { getDefaultOutputMapping } from "src/integrationNode/integrationResources/DefaultResourceConfig";
import {
  DEFAULT_OUTPUT_BE_NAME,
  DropDownInputT,
  InputMappingBET,
  InputMappingGroupT,
  InputMappingListT,
  InputMappingsT,
  InputMappingT,
  OutputMappingBET,
  OutputMappingsT,
} from "src/integrationNode/types";
import {
  inputMappingsFeToBe,
  multiselectsFeToBe,
} from "src/integrationNode/utils";
import {
  getDefaultManifestInputGroups,
  getDefaultManifestInputUngrouped,
} from "src/manifestConnectionNode/manifestConnectionResources/DefaultResources";
import {
  ManifestIntegrationResourceBET,
  ManifestIntegrationResourceT,
} from "src/manifestConnectionNode/types";

const DEFAULT_INPUT_CONTENT = {
  hint: undefined,
  rules: { required: false },
  type: "text",
};

const manifestIntegrationResourceInputBeToFe = (
  resourceInputBE: InputMappingBET,
): InputMappingsT => {
  /**
   * Display name, hint, rules, and getDefaultElements are defaulted here and the InputMappings
   * are filled from the manifest when the node editor is rendered.
   * */
  const resourceInputFe: InputMappingsT = {
    ungrouped: {},
    grouped: {},
    lists: {},
  };
  Object.entries(resourceInputBE.singles).forEach(
    ([singleName, singleMapping]) => {
      resourceInputFe.ungrouped[singleName] = {
        ...DEFAULT_INPUT_CONTENT,
        id: singleMapping.id,
        displayName: singleName,
        assignedTo: singleMapping.expression,
      } as InputMappingT;
    },
  );
  Object.entries(resourceInputBE.groups).forEach(
    ([groupName, groupMapping]) => {
      resourceInputFe.grouped[groupName] = {
        displayName: groupName,
        rules: { required: false },
        getDefaultElements: () => ({}),
      };
      Object.entries(groupMapping.singles).forEach(
        ([singleName, singleMapping]) => {
          if (!resourceInputFe.grouped[groupName].elements) {
            resourceInputFe.grouped[groupName].elements = {};
          }
          resourceInputFe.grouped[groupName].elements![singleName] = {
            ...DEFAULT_INPUT_CONTENT,
            id: singleMapping.id,
            displayName: singleName,
            assignedTo: singleMapping.expression,
          } as InputMappingT;
        },
      );
    },
  );
  Object.entries(resourceInputBE.lists).forEach(([listName, listMappings]) => {
    resourceInputFe.lists[listName] = {
      displayName: listName,
      rules: { required: false },
      getDefaultElement: () => ({}),
      elements: [],
    };
    for (const listMapping of listMappings) {
      let listElements: { [key: string]: InputMappingT } = {};
      Object.entries(listMapping.singles).forEach(
        ([singleName, singleMapping]) => {
          listElements[singleName] = {
            ...DEFAULT_INPUT_CONTENT,
            id: singleMapping.id,
            displayName: singleName,
            assignedTo: singleMapping.expression,
          } as InputMappingT;
        },
      );
      resourceInputFe.lists[listName].elements.push(listElements);
    }
  });
  return resourceInputFe;
};

const manifestIntegrationResourceOutputBeToFe = (resourceOutputBE: {
  [DEFAULT_OUTPUT_BE_NAME]: OutputMappingBET;
  [key: string]: OutputMappingBET;
}): OutputMappingsT => {
  /**
   * Display name and hint are defaulted here and the OutputMappings
   * are filled from the manifest when the node editor is rendered.
   * */
  const resourceOutputFe: OutputMappingsT = {
    [DEFAULT_OUTPUT_BE_NAME]: getDefaultOutputMapping(),
    insights: {},
  };

  Object.entries(resourceOutputBE).forEach(([key, value]) => {
    if (key !== DEFAULT_OUTPUT_BE_NAME) {
      resourceOutputFe.insights[key] = {
        id: value.id,
        assignedTo: value.mapped_to,
        selected: value.active,
        displayName: "",
        hint: undefined,
      };
    } else {
      resourceOutputFe[DEFAULT_OUTPUT_BE_NAME].id = value.id;
      resourceOutputFe[DEFAULT_OUTPUT_BE_NAME].assignedTo = value.mapped_to;
      resourceOutputFe[DEFAULT_OUTPUT_BE_NAME].selected = value.active;
    }
  });
  return resourceOutputFe;
};

export const manifestIntegrationResourceBeToFe = (
  reportBE: ManifestIntegrationResourceBET,
): ManifestIntegrationResourceT => {
  const manifestIntegrationResource: ManifestIntegrationResourceT = {
    connectionId: reportBE.connection_id,
    resourceConfigId: reportBE.resource_config_id,
    providerResource: reportBE.provider_resource,
    input: manifestIntegrationResourceInputBeToFe(reportBE.input),
    output: manifestIntegrationResourceOutputBeToFe(reportBE.output),
    config: reportBE.config,
  };

  return manifestIntegrationResource;
};

export const manifestIntegrationResourceFeToBe = (
  resourceFE: ManifestIntegrationResourceT,
): ManifestIntegrationResourceBET => {
  return {
    provider_resource: resourceFE.providerResource,
    connection_id: resourceFE.connectionId,
    resource_config_id: resourceFE.resourceConfigId,
    config: resourceFE.config,
    input: {
      singles: {
        ...inputMappingsFeToBe(resourceFE.input.ungrouped),
        ...multiselectsFeToBe(resourceFE.input.multiselectors),
      },
      groups: Object.fromEntries(
        Object.entries(resourceFE.input.grouped)
          .map(([groupName, group]) => [
            groupName,
            group.elements
              ? {
                  singles: inputMappingsFeToBe(group.elements),
                  groups: {},
                  lists: {},
                }
              : undefined,
          ])
          .filter(([, value]) => value),
      ),
      lists: Object.fromEntries(
        Object.entries(resourceFE.input.lists).map(([listName, list]) => [
          listName,
          list.elements.map((element) => ({
            singles: inputMappingsFeToBe(element),
            groups: {},
            lists: {},
          })),
        ]),
      ),
    },
    output: {
      [DEFAULT_OUTPUT_BE_NAME]: {
        id: resourceFE.output[DEFAULT_OUTPUT_BE_NAME].id,
        mapped_to: resourceFE.output[DEFAULT_OUTPUT_BE_NAME].assignedTo,
        active: resourceFE.output[DEFAULT_OUTPUT_BE_NAME].selected,
      },
      ...Object.keys(resourceFE.output.insights).reduce(
        (acc: { [key: string]: OutputMappingBET }, beName) => {
          const outputMapping = resourceFE.output.insights[beName];
          acc[beName] = {
            id: outputMapping.id,
            mapped_to: outputMapping.assignedTo,
            active: outputMapping.selected,
          };
          return acc;
        },
        {},
      ),
    },
  };
};

const fillElementFromManifest = (
  elementKey: string,
  element: InputMappingT,
  elementSchema: JSONSchemaPropertyDefinition,
  requiredElements: string[] | undefined,
) => {
  const filledElement = { ...element };
  if (elementSchema.widget === "single_select") {
    filledElement.type = "dropDown";
    (filledElement as DropDownInputT).elements =
      elementSchema.enum?.map((enumValue) => ({
        key: `"${enumValue}"` as string,
        value: enumValue as string,
      })) || [];
  }
  filledElement.displayName = elementSchema.title || capitalize(elementKey);
  filledElement.hint = elementSchema.description || undefined;
  filledElement.rules = {
    ...filledElement.rules,
    maxLength: elementSchema.maxLength,
    minLength: elementSchema.minLength,
    required: requiredElements?.includes(elementKey),
  };
  filledElement.beType = elementSchema.type;
  filledElement.example = elementSchema.example;
  return filledElement;
};

const fillUngroupedInputMappingFromManifest = (
  inputMappings: InputMappingsT,
  manifestNodeInputs: ManifestJSONSchema,
): {
  [key: string]: InputMappingT;
} => {
  const ungrouped: {
    [key: string]: InputMappingT;
  } = {};

  if (!manifestNodeInputs.properties) return {};

  Object.entries(manifestNodeInputs.properties!).forEach(
    ([inputKey, inputSchema]) => {
      if (typeof inputSchema !== "object" || inputSchema === null) return;

      if (!inputMappings.ungrouped[inputKey]) return;

      ungrouped[inputKey] = fillElementFromManifest(
        inputKey,
        inputMappings.ungrouped[inputKey],
        inputSchema,
        manifestNodeInputs.required,
      );
    },
  );

  return ungrouped;
};

const fillGroupElementsFromManifest = (
  elements: { [key: string]: InputMappingT },
  manifestGroupDefinition: ManifestJSONSchema,
): { [key: string]: InputMappingT } => {
  const groupElements: { [key: string]: InputMappingT } = {};

  if (!manifestGroupDefinition.properties) return {};

  Object.entries(manifestGroupDefinition.properties!).forEach(
    ([elementKey, elementSchema]) => {
      if (typeof elementSchema !== "object" || elementSchema === null) return;
      if (!elements[elementKey]) return;

      groupElements[elementKey] = fillElementFromManifest(
        elementKey,
        elements[elementKey],
        elementSchema,
        manifestGroupDefinition.required,
      );
    },
  );
  return groupElements;
};

const fillGroupedInputMappingsFromManifest = (
  inputMappings: InputMappingsT,
  manifestNodeInputs: ManifestJSONSchema,
): { [key: string]: InputMappingGroupT } => {
  const grouped = getDefaultManifestInputGroups(
    manifestNodeInputs,
    inputMappings.grouped,
  );

  Object.keys(grouped).forEach((groupedKey) => {
    const manifestGroupDefinition = manifestNodeInputs.properties![groupedKey];
    if (
      typeof manifestGroupDefinition !== "object" ||
      manifestGroupDefinition === null
    ) {
      return;
    }

    grouped[groupedKey] = {
      ...grouped[groupedKey],
      displayName: manifestGroupDefinition.title || capitalize(groupedKey),
      rules: { required: manifestNodeInputs.required?.includes(groupedKey) },
      getDefaultElements: () =>
        getDefaultManifestInputUngrouped(
          manifestGroupDefinition as ManifestJSONSchema,
        ),
    };

    if (grouped[groupedKey].elements) {
      grouped[groupedKey].elements = fillGroupElementsFromManifest(
        grouped[groupedKey].elements!,
        manifestGroupDefinition,
      );
    }
  });
  return grouped;
};

const fillListInputMappingFromManifest = (
  inputMappings: InputMappingsT,
  manifestNodeInputs: ManifestJSONSchema,
): {
  [listName: string]: InputMappingListT;
} => {
  const listInputs: {
    [listName: string]: InputMappingListT;
  } = {};

  if (!manifestNodeInputs.properties) return {};

  Object.entries(manifestNodeInputs.properties!).forEach(
    ([listKey, manifestInputDefinition]) => {
      if (!(listKey in inputMappings.lists)) {
        return;
      }

      if (
        typeof manifestInputDefinition !== "object" ||
        manifestInputDefinition === null ||
        // Here we only fill the array properties from the manifest
        manifestInputDefinition.type !== "array"
      ) {
        return;
      }

      const manifestListItemDefinition =
        manifestInputDefinition.items as ManifestJSONSchema;

      if (
        typeof manifestListItemDefinition !== "object" ||
        manifestListItemDefinition === null
      ) {
        return;
      }

      listInputs[listKey] = {
        ...inputMappings.lists[listKey],
        rules: {
          ...inputMappings.lists[listKey].rules,
          required: manifestNodeInputs.required?.includes(listKey),
        },
        displayName: manifestInputDefinition.title || capitalize(listKey),
        getDefaultElement: () =>
          getDefaultManifestInputUngrouped(manifestListItemDefinition),
      };

      if (!manifestListItemDefinition.properties) return;

      listInputs[listKey].elements = inputMappings.lists[listKey].elements.map(
        (listElement) => {
          let elementObj: { [key: string]: InputMappingT } = {};

          Object.entries(manifestListItemDefinition.properties!).forEach(
            ([elementKey, elementSchema]) => {
              if (typeof elementSchema !== "object" || elementSchema === null)
                return;

              elementObj[elementKey] = fillElementFromManifest(
                elementKey,
                listElement[elementKey],
                elementSchema,
                manifestListItemDefinition.required,
              );
            },
          );

          return elementObj;
        },
      );
    },
  );
  return listInputs;
};

export const fillInputMappingFromManifest = (
  manifest: ManifestIntegrationProvider,
  integrationResource: ManifestIntegrationResourceT,
): InputMappingsT => {
  // The BE doesn't hold displayName, description, and required status
  // for the inputMappings. This function fills the inputMappings
  // with these fields from the manifest.
  const inputMapping = integrationResource.input;
  const resource = integrationResource.providerResource.resource;
  const manifestNodeInputs = manifest.resources[resource].node_inputs;

  if (manifestNodeInputs && manifestNodeInputs.properties !== undefined) {
    inputMapping.ungrouped = fillUngroupedInputMappingFromManifest(
      inputMapping,
      manifestNodeInputs,
    );
    inputMapping.grouped = fillGroupedInputMappingsFromManifest(
      inputMapping,
      manifestNodeInputs,
    );
    inputMapping.lists = fillListInputMappingFromManifest(
      inputMapping,
      manifestNodeInputs,
    );
  }
  return inputMapping;
};

export const fillOutputMappingFromManifest = (
  manifest: ManifestIntegrationProvider,
  integrationResource: ManifestIntegrationResourceT,
): OutputMappingsT => {
  // The output display name and description are added from the
  // manifest to complete the output mappings
  const outputMapping = integrationResource.output;
  const resource = integrationResource.providerResource.resource;
  const manifestInsights = manifest.resources[resource].insights;

  Object.keys(outputMapping.insights).forEach((insightKey) => {
    const manifestInsight = manifestInsights[insightKey];
    if (manifestInsight) {
      outputMapping.insights[insightKey].displayName =
        manifestInsight.display_name;
      outputMapping.insights[insightKey].hint = manifestInsight.description;
    }
  });

  return outputMapping;
};
