import { faBan, faEye } from "@fortawesome/pro-regular-svg-icons";
import { ColumnDef, CellContext, Cell } from "@tanstack/react-table";
import { ComponentProps } from "react";
import { twJoin } from "tailwind-merge";

import {
  DecisionHistoryFiltersV2,
  DecisionHistoryRecordV2,
} from "src/api/decisionHistoryV2/decisionHistoryQueries";
import { FlowT, FlowVersionT } from "src/api/flowTypes";
import { DecisionEnvironment } from "src/api/types";
import { ColumnFilter } from "src/base-components/ColumnFilter";
import { CopyTextIcon } from "src/base-components/CopyTextIcon";
import { Icon } from "src/base-components/Icon";
import { EllipsisOptionsDropdown } from "src/base-components/OptionsDropdown/EllipsisOptionsDropdown";
import { Pill } from "src/base-components/Pill";
import { Tooltip } from "src/base-components/Tooltip";
import { useChangeHistories } from "src/changeHistory/queries";
import { RawCell } from "src/dataTable/RawCell";
import { ResultCell } from "src/dataTable/ResultCell";
import { isValidEntityId } from "src/decisionsOverview/utils";
import {
  getParentFlowsWithVersions,
  singleFlowGetParentFlows,
  singleFlowGetPotentialParentVersions,
} from "src/flow/Model";
import { getSourceDetails } from "src/flow/decisionHistory/DecisionHistoryUtils";
import { OriginColumnFilter } from "src/flow/decisionHistory/OriginColumnFilter";
import {
  SharedDecisionHistoryStatus,
  POSSIBLE_DECISION_STATUS_CODES,
} from "src/flow/decisionHistory/SharedStatusColumn";
import { SourceDetails, SourcePill } from "src/flow/decisionHistory/SourcePill";
import { Job } from "src/jobs/types";
import { FEATURE_FLAGS, isFeatureFlagEnabled } from "src/router/featureFlags";
import { useWorkspaceContext } from "src/router/routerContextHooks";
import { getBaseUrl, getUrlToDecisionsOverview } from "src/router/urls";
import { formatDate } from "src/utils/datetime";
import { formatDuration } from "src/utils/numbers";
import { getDecisionDisabledReason } from "src/versionDecisionHistory/Table";

type Args = {
  orgId: string;
  wsId: string;
  flow: FlowT;
  jobs: Job[];
  filterFields: DecisionHistoryFiltersV2["fields"];
  changeFilterFields: (fields: DecisionHistoryFiltersV2["fields"]) => void;
  availableFlowVersions: FlowVersionT[];
  env: DecisionEnvironment;
  includeEntityLinks: boolean;
  tracingHoverBehavior: boolean;
};

const availableStatusCodes = POSSIBLE_DECISION_STATUS_CODES.map(
  (option) => option.key,
);

const HeaderCell: React.FC<{
  children?: React.ReactNode;
  className: string;
}> = ({ children, className }) => (
  <div
    className={twJoin(
      "py-3.5 pl-2 pr-2 text-left text-gray-800 font-inter-medium-12px",
      className,
    )}
  >
    {children}
  </div>
);

export const getDecisionHistoryColumns = ({
  orgId,
  wsId,
  flow,
  jobs,
  changeFilterFields,
  filterFields,
  availableFlowVersions,
  env,
  includeEntityLinks,
  tracingHoverBehavior,
}: Args): ColumnDef<DecisionHistoryRecordV2, any>[] => {
  const resetFieldFilter = (key: keyof DecisionHistoryFiltersV2["fields"]) => {
    const { [key]: _, ...fieldsWithoutStatusCode } = filterFields;
    return changeFilterFields(fieldsWithoutStatusCode);
  };

  const setStatusCodeFilter = (
    newStatusCode: (typeof POSSIBLE_DECISION_STATUS_CODES)[number]["key"],
  ) => {
    // if selected field is the same than the previously selected
    // then we remove the filter.
    if (newStatusCode === filterFields.status_code) {
      resetFieldFilter("status_code");
    } else {
      const currentFields = filterFields;
      const newFields = { ...currentFields, status_code: newStatusCode };
      changeFilterFields(newFields);
    }
  };

  const setVersionIdFilter = (newVersionId: string) => {
    // if selected field is the same than the previously selected
    // then we remove the filter.
    if (newVersionId === filterFields.flow_version_id) {
      resetFieldFilter("flow_version_id");
    } else {
      const currentFields = filterFields;
      const newFields = { ...currentFields, flow_version_id: newVersionId };
      changeFilterFields(newFields);
    }
  };

  const statusFilterOptions = POSSIBLE_DECISION_STATUS_CODES.map((option) => ({
    ...option,
    disabled: !availableStatusCodes.includes(option.key),
  }));

  const parentFlows = singleFlowGetParentFlows(flow);
  const potentialParentVersions = singleFlowGetPotentialParentVersions(flow);
  const parentFlowsWithVersions = getParentFlowsWithVersions(flow);

  return [
    {
      id: "decisionId",
      size: 50,
      header: () => (
        <HeaderCell className="flex items-center">
          <span className="inline-block">Decision ID</span>
        </HeaderCell>
      ),
      accessorFn: (row) => (
        <CopyTextIcon
          color="hover:text-indigo-500"
          dataLoc="copy-id"
          value={row["id"]}
        />
      ),
      cell: ({ cell }) => (
        <RawCell<DecisionHistoryRecordV2>
          cell={cell}
          classNameOverrides="max-w-0"
        />
      ),
    },
    {
      id: "entityId",
      size: 50,
      header: () => (
        <HeaderCell className="flex items-center">
          <span className="inline-block">Entity ID</span>
        </HeaderCell>
      ),
      accessorFn: (row) =>
        row["entity_id"] ? (
          <CopyTextIcon
            color="hover:text-indigo-500"
            dataLoc="copy-id"
            value={row["entity_id"]}
          />
        ) : (
          <div className="ml-1">—</div>
        ),
      cell: ({ cell }) => (
        <RawCell<DecisionHistoryRecordV2>
          cell={cell}
          classNameOverrides="max-w-0"
        />
      ),
    },
    {
      id: "datetime",
      size: 70,
      header: () => <HeaderCell className="w-24">Time</HeaderCell>,
      accessorFn: (row) => formatDate(row.start_time, "dd MMM yy HH:mm:ss"),
      cell: ({ cell }) => (
        <RawCell<DecisionHistoryRecordV2>
          cell={cell}
          classNameOverrides="max-w-0 w-36"
        />
      ),
    },
    {
      id: "status",
      size: 200,
      header: () => (
        <HeaderCell className="flex w-auto items-center">
          <span>Status</span>
          <span className="ml-1">
            <ColumnFilter
              description="Filter by status"
              elements={statusFilterOptions}
              isFiltering={Boolean(filterFields.status_code)}
              placement="bottomLeft"
              selected={filterFields.status_code}
              onResetRequest={() => resetFieldFilter("status_code")}
              onSelect={(value) =>
                setStatusCodeFilter(
                  value as (typeof availableStatusCodes)[number],
                )
              }
            />
          </span>
        </HeaderCell>
      ),
      accessorFn: (row) => (
        <SharedDecisionHistoryStatus statusCode={row["status_code"]} />
      ),
      cell: ({ cell }: CellContext<DecisionHistoryRecordV2, any>) => {
        const message = (
          <span className="justify-start">{cell.getValue()}</span>
        );

        return (
          <ResultCell<DecisionHistoryRecordV2>
            cell={message}
            className="max-w-0 overflow-auto"
            objectDetailPosition="topRight"
          />
        );
      },
    },
    {
      id: "source",
      size: 100,
      header: () => {
        let originFilterValue: ComponentProps<
          typeof OriginColumnFilter
        >["selected"] = undefined;

        if (filterFields.origin === "api_call") {
          originFilterValue = { type: "api_call" };
        } else if (filterFields.job_run_id && filterFields.job_id) {
          const { job_run_id, job_id } = filterFields;
          originFilterValue = { type: "job_run", job_id, job_run_id };
        } else if (
          filterFields.parent_flow_id &&
          filterFields.parent_flow_version_id
        ) {
          const { parent_flow_id, parent_flow_version_id } = filterFields;
          originFilterValue = {
            type: "parent_flow_version",
            parent_flow_id,
            parent_flow_version_id,
          };
        }
        return (
          <HeaderCell className="flex w-24 items-center">
            Origin{" "}
            <div>
              <OriginColumnFilter
                env={env}
                flows={parentFlowsWithVersions}
                jobs={jobs}
                placement="bottom-start"
                selected={originFilterValue}
                onSelect={(value) => {
                  const resetBase: DecisionHistoryFiltersV2["fields"] = {
                    ...filterFields,
                    job_id: undefined,
                    job_run_id: undefined,
                    parent_flow_id: undefined,
                    parent_flow_version_id: undefined,
                    origin: undefined,
                  };
                  if (value === undefined) {
                    changeFilterFields(resetBase);
                  } else if (value.type === "api_call") {
                    changeFilterFields({ ...resetBase, origin: "api_call" });
                  } else if (
                    value.type === "job_run" ||
                    value.type === "parent_flow_version"
                  ) {
                    changeFilterFields({ ...resetBase, ...value });
                  }
                }}
              />
            </div>
          </HeaderCell>
        );
      },
      accessorFn: (row) =>
        getSourceDetails(row, parentFlows, potentialParentVersions, jobs),
      cell: ({ cell }: CellContext<DecisionHistoryRecordV2, SourceDetails>) => {
        const sourceDetails = cell.getValue();
        const content = (
          <SourcePill
            decisionEnv={env}
            orgId={orgId}
            sourceDetails={sourceDetails}
            wsId={wsId}
          />
        );

        return (
          <RawCell<DecisionHistoryRecordV2>
            cell={content}
            classNameOverrides="max-w-[300px] w-auto"
          />
        );
      },
    },
    {
      id: "Duration",
      size: 100,
      header: () => (
        <HeaderCell className="flex items-center">
          <span className="inline-block">Duration</span>
        </HeaderCell>
      ),
      accessorFn: (row) => (
        <span className="w-full">
          <Tooltip
            activated={row.duration_in_milliseconds > 1000}
            align="center"
            placement="top"
            title={`${row.duration_in_milliseconds}ms`}
          >
            {formatDuration(row.duration_in_milliseconds)}
          </Tooltip>
        </span>
      ),
      cell: ({ cell }) => (
        <RawCell<DecisionHistoryRecordV2>
          cell={cell}
          classNameOverrides="max-w-0"
        />
      ),
    },
    {
      id: "Version",
      size: 100,
      header: () => (
        <HeaderCell className="flex items-center">
          <span className="inline-block">Version</span>
          <span className="ml-1">
            <ColumnFilter
              description="Filter by version"
              elements={availableFlowVersions.map((version) => ({
                key: version.id,
                value: version.name,
              }))}
              isFiltering={Boolean(filterFields.flow_version_id)}
              placement="bottomLeft"
              selected={filterFields.flow_version_id}
              onResetRequest={() => resetFieldFilter("flow_version_id")}
              onSelect={(key) => setVersionIdFilter(key)}
            />
          </span>
        </HeaderCell>
      ),
      accessorFn: (row) => {
        return <span className="w-full">{row.flow.version_name}</span>;
      },
      cell: ({ cell }: CellContext<DecisionHistoryRecordV2, any>) => {
        return (
          <VersionCell
            availableFlowVersions={availableFlowVersions}
            cell={cell}
            includeEntityLink={includeEntityLinks}
            tracingHoverBehavior={tracingHoverBehavior}
          />
        );
      },
    },
  ];
};

const VersionCell: React.FC<{
  cell: Cell<DecisionHistoryRecordV2, any>;
  availableFlowVersions: FlowVersionT[];
  includeEntityLink: boolean;
  tracingHoverBehavior: boolean;
}> = ({
  cell,
  availableFlowVersions,
  includeEntityLink,
  tracingHoverBehavior,
}) => {
  const { orgId, workspace } = useWorkspaceContext();
  const decisionVersion = availableFlowVersions.find(
    (version) => version.id === cell.row.original.flow.version_id,
  );
  const historyChanges = useChangeHistories(
    decisionVersion
      ? [
          {
            versionId: decisionVersion?.id,
            etag: decisionVersion?.etag ?? "00000000",
          },
        ]
      : [],
  );
  const disableReason = getDecisionDisabledReason(
    cell.row.original,
    historyChanges?.[0]?.data?.etag,
    historyChanges?.[0]?.data?.created_at,
  );

  const message = (
    <div className="flex flex-grow items-center justify-between">
      <div className="justify-start">
        <Pill size="sm" variant="gray">
          <Pill.Text>{cell.getValue()}</Pill.Text>
        </Pill>
      </div>

      <div className="invisible flex justify-end gap-x-2 align-middle text-gray-500 group-hover/row:visible">
        {tracingHoverBehavior &&
          (disableReason ? (
            <Tooltip
              align="center"
              placement="top"
              title={
                disableReason === "outdated"
                  ? "This decision cannot be displayed as the flow logic was in draft and has since been updated."
                  : "Decisions with status code 500 cannot be displayed due to an internal error. Our engineering team has been notified and will investigate the issue."
              }
              triggerClassName="cursor-default"
            >
              <Icon icon={faBan} size="sm" />
            </Tooltip>
          ) : (
            <Icon icon={faEye} size="sm" />
          ))}

        {includeEntityLink &&
          isFeatureFlagEnabled(FEATURE_FLAGS.entitiesView) &&
          isValidEntityId(cell.row.original.entity_id) && (
            <EllipsisOptionsDropdown
              elements={[
                {
                  key: "nav",
                  nav: "View related decisions",
                  subtitle: (
                    <>
                      Show all decisions with the same Entity ID{" "}
                      <Pill size="sm" variant="gray" maxWidth>
                        <Pill.Text fontType="code">
                          {cell.row.original.entity_id}
                        </Pill.Text>
                      </Pill>
                    </>
                  ),
                  action: () => {
                    window.open(
                      getBaseUrl() +
                        getUrlToDecisionsOverview(orgId, workspace.id, {
                          entityId: cell.row.original.entity_id,
                        }),
                      "_blank",
                    );
                  },
                },
              ]}
            />
          )}
      </div>
    </div>
  );
  return (
    <RawCell<DecisionHistoryRecordV2>
      cell={message}
      classNameOverrides="max-w-full w-full"
    />
  );
};
