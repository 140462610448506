import { twJoin } from "tailwind-merge";

export const NodeLatency = ({
  duration,
  isErrored,
}: {
  duration: number | undefined;
  isErrored: boolean;
}) => {
  if (duration === undefined) return;
  return (
    <div
      className={twJoin(
        "flex h-4 items-center font-inter-semibold-13px",
        isErrored ? "text-red-600" : "text-green-600",
      )}
    >
      {duration} ms
    </div>
  );
};
