import { autocompletion } from "@codemirror/autocomplete";
import {
  faBox,
  faPlus,
  faPlay as faPlayRegular,
  faBracketsCurly,
  faArrowRight,
  faDatabase,
  faWandMagicSparkles,
  faCheck,
  faInfoCircle,
} from "@fortawesome/pro-regular-svg-icons";
import { faPlay } from "@fortawesome/pro-solid-svg-icons";
import { Root as AccordionRoot } from "@radix-ui/react-accordion";
import { useIsMutating } from "@tanstack/react-query";
import levenshtein from "damerau-levenshtein";
import { Dictionary, isEqual, keyBy, lowerCase, noop } from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import {
  Controller,
  FieldArrayWithId,
  FormProvider,
  useFieldArray,
  useForm,
  useFormContext,
  useWatch,
} from "react-hook-form";
import { twJoin } from "tailwind-merge";
import { useDebouncedCallback } from "use-debounce";

import { useConnections } from "src/api/connectApi/queries";
import { ConnectionT, isSQLDatabaseProvider } from "src/api/connectApi/types";
import { FlowVersionFlowChild } from "src/api/flowTypes";
import { Button } from "src/base-components/Button";
import { Card } from "src/base-components/Card";
import { CodeEditor } from "src/base-components/CodeInput/CodeEditor";
import { Combobox } from "src/base-components/Combobox";
import {
  EditorAccordionItem as AccordionItem,
  accordionRootClassName,
} from "src/base-components/EditorAccordionItem";
import { EmptyState } from "src/base-components/EmptyState";
import { Icon } from "src/base-components/Icon";
import { InformationPill } from "src/base-components/InformationPill";
import { Select } from "src/base-components/Select";
import { TitleEditor } from "src/base-components/TitleEditor";
import {
  TAKTILE_TEAM_NOTIFIED,
  toastFailure,
} from "src/base-components/Toast/utils";
import { Tooltip } from "src/base-components/Tooltip";
import { SCHEMA_TYPE_ICONS } from "src/base-components/TypeIcons";
import { ProviderIcon } from "src/connections/config/Icon";
import { Modal } from "src/design-system/Modal";
import { useModal } from "src/design-system/Modal";
import { useCapabilities } from "src/hooks/useCapabilities";
import { usePatchJobSource, usePreviewJobSource } from "src/jobs/api/queries";
import { SQLResponsePreview } from "src/jobs/common/SQLSourceModal/SQLResponsePreview";
import { SelectVersionModal } from "src/jobs/common/SelectVersionModal";
import { TrafficPolicyModal } from "src/jobs/common/TrafficPolicyModal";
import { DEFAULT_METADATA_MAPPING } from "src/jobs/common/constants";
import { useJobSource, useUsedVersions } from "src/jobs/common/hooks";
import { Job, JobSQLSource, SQLJobSourceConfiguration } from "src/jobs/types";
import { SchemaField } from "src/parentFlowNodes/SchemaField";
import { buildSchemaTabUrl } from "src/parentFlowNodes/utils";
import { queryClient } from "src/queryClient";
import { SchemaOptions } from "src/router/SearchParams";
import { FEATURE_FLAGS, isFeatureFlagEnabled } from "src/router/featureFlags";
import {
  useFlowContext,
  useWorkspaceContext,
} from "src/router/routerContextHooks";
import { getBaseUrl, getUrlToWsDashboard } from "src/router/urls";
import { SchemaConverter, PropertyUIT } from "src/schema/utils";
import * as logger from "src/utils/logger";
import { isPreconditionError } from "src/utils/predicates";

export type SQLSourceUpdates = {
  name?: string;
  configuration?: Partial<SQLJobSourceConfiguration>;
};

const ENTITY_ID_DOCS_PAGE =
  "https://docs.taktile.com/developer-docs/linking-decisions";

export const SQLSourceModal: React.FC<{
  job: Job;
  sourceId?: string;
  onClose: () => void;
  open: boolean;
}> = ({ job, onClose, open, sourceId }) => {
  const { workspace } = useWorkspaceContext();
  const source = useJobSource<JobSQLSource>(job.id, sourceId);
  const { mutateAsync: patchSqlSource } = usePatchJobSource(
    workspace.base_url!,
    job.id,
  );

  const updateSqlSource = async (updates: SQLSourceUpdates) => {
    if (!source) return;

    try {
      await patchSqlSource({
        id: source.id,
        etag: source.etag,
        name: updates.name ?? source.name,
        configuration: {
          ...source.configuration,
          ...updates.configuration,
          type: "sql",
        },
      });
    } catch (error) {
      logger.error(error);

      if (isPreconditionError(error)) {
        await queryClient.invalidateQueries([
          "job_sources",
          workspace.base_url,
          job.id,
        ]);
        toastFailure({
          title: "Failed to update SQL source",
          description:
            "Somebody has changed the Source, check the updated configuration and try again",
        });
      } else {
        toastFailure({
          title: "Failed to update SQL source",
          description: TAKTILE_TEAM_NOTIFIED,
        });
      }
    }
  };

  const title = (
    <div className="flex w-full items-center justify-between gap-x-2">
      <h2 className="line-clamp-1 w-2/4 text-gray-800 font-inter-semibold-14px">
        <TitleEditor
          autofocus={false}
          dataLoc="dataset-name-editor"
          placeholder="Untitled SQL Source"
          value={source?.name}
          onSubmit={(name) => updateSqlSource({ name })}
        />
      </h2>
    </div>
  );

  return (
    <Modal open={open} size="lg" onClose={onClose}>
      <Modal.Header>{title}</Modal.Header>
      {source && (
        <SQLSourceModalContent
          job={job}
          source={source}
          onClose={onClose}
          onSubmit={updateSqlSource}
        />
      )}
    </Modal>
  );
};

const SQLSourceModalContent: React.FC<{
  source: JobSQLSource;
  job: Job;
  onSubmit: (data: SQLSourceUpdates) => void;
  onClose: () => void;
}> = ({ source, onSubmit, onClose, job }) => {
  const [sourceQueryValuesHasChanged, setSourceQueryValuesHasChanged] =
    useState<"connection_id" | "query" | false>(false);
  const { workspace } = useWorkspaceContext();

  const usedVersions = useUsedVersions(job);

  const mapping = useMemo(() => {
    const allInputFields = usedVersions
      .flatMap((v) =>
        v?.input_schema
          ? SchemaConverter.beToUI(
              v.input_schema,
              SchemaOptions.Input,
            ).properties.map((f) => f.fieldName)
          : undefined,
      )
      .filter((f) => f !== undefined) as string[];

    const unionOfInputFields = Array.from(new Set(allInputFields));

    return (
      unionOfInputFields.map((field) => {
        const db_field =
          source.configuration.mapping.find(
            (mapping) => mapping.flow_input_field === field,
          )?.db_field ?? null;

        return {
          db_field,
          flow_input_field: field,
        };
      }) ?? []
    );
  }, [source.configuration.mapping, usedVersions]);

  const form = useForm<SQLJobSourceConfiguration>({
    defaultValues: {
      ...source.configuration,
      type: "sql",
      metadata_mapping:
        source.configuration.metadata_mapping ?? DEFAULT_METADATA_MAPPING,
      mapping,
    },
  });
  const { watch, reset } = form;

  useEffect(() => {
    // Double-check that we actually want to reset the form
    if (!isEqual(mapping, source.configuration.mapping)) {
      reset((formValues) => ({ ...formValues, mapping }));
    }
  }, [reset, mapping, source.configuration.mapping]);

  const previewJobSource = usePreviewJobSource(
    workspace.base_url!,
    source.job_id,
  );
  const runQuery = async () => {
    if (!source) return;

    try {
      setSourceQueryValuesHasChanged(false);
      const formValues = form.getValues();
      await previewJobSource.mutateAsync({
        query: formValues.query,
        connection_id: formValues.connection_id,
      });
    } catch (error) {
      logger.error(error);
    }
  };

  const updateSqlSourceDebounced = useDebouncedCallback(onSubmit, 500);
  useEffect(() => {
    const subscription = watch((values, { name, type }) => {
      if (source) {
        updateSqlSourceDebounced({
          configuration: values as SQLJobSourceConfiguration,
        });

        if (
          type === "change" &&
          (name === "query" || name === "connection_id")
        ) {
          setSourceQueryValuesHasChanged(name);
        }
      }
    });

    return () => {
      subscription.unsubscribe();
    };
  }, [watch, source, updateSqlSourceDebounced]);

  return (
    <form>
      <FormProvider {...form}>
        <Modal.Content noScroll>
          <div className="flex h-full min-h-0 flex-1 items-stretch gap-x-5">
            <div className="decideScrollbar min-h-[38rem] w-6/12 max-w-164 flex-none py-5">
              {source && (
                <SQLQueryConfiguration
                  columns={Object.keys(
                    previewJobSource.data?.data.result[0] ?? {},
                  )}
                  job={job}
                  showQueryChangedWarning={sourceQueryValuesHasChanged}
                  usedVersions={usedVersions}
                  onRunQuery={runQuery}
                />
              )}
            </div>
            <div className="flex min-w-0 flex-1 flex-col gap-y-3 py-5">
              <SQLResponsePreview previewMutation={previewJobSource} />
            </div>
          </div>
        </Modal.Content>
        <Modal.Footer
          primaryButton={
            <Button
              dataLoc="sql-modal-done"
              variant="primary"
              onClick={onClose}
            >
              Done
            </Button>
          }
        >
          <RunQueryButton jobId={job.id} onClick={runQuery} />
        </Modal.Footer>
      </FormProvider>
    </form>
  );
};

export const connectionHasSandboxCredentials = (
  connection: ConnectionT | undefined,
) => {
  return (
    connection &&
    connection.non_prod_env_configs &&
    Object.keys(connection.non_prod_env_configs).length !== 0
  );
};

const SQLQueryConfiguration: React.FC<{
  job: Job;
  onRunQuery: () => void;
  columns: string[];
  showQueryChangedWarning: "query" | "connection_id" | false;
  usedVersions: FlowVersionFlowChild[];
}> = ({ onRunQuery, job, columns, showQueryChangedWarning, usedVersions }) => {
  const {
    isOpen: isSelectVersionModalOpen,
    openModal: openSelectVersionModal,
    closeModal: closeSelectVersionModal,
  } = useModal();
  const { workspace, orgId, flow } = useFlowContext();
  const connections = useConnections(workspace.base_url);
  const dbConnections =
    connections.data?.filter((connection) =>
      isSQLDatabaseProvider(connection.provider),
    ) ?? [];
  const { connections: connectionsCapabilities } = useCapabilities();
  const { fields, update } = useFieldArray<SQLJobSourceConfiguration>({
    name: "mapping",
  });
  const form = useFormContext<SQLJobSourceConfiguration>();

  const currentDbConnection = dbConnections.find(
    (connection) => connection.id === form.getValues().connection_id,
  );

  const allowSelectingSandboxCredentials =
    connectionHasSandboxCredentials(currentDbConnection);

  // Give feedback to the user for auto map
  const [autoMapState, setAutoMapState] = useState<
    "standby" | "in_progress" | "done"
  >("standby");
  const autoMapFields = async () => {
    setAutoMapState("in_progress");
    await new Promise((r) => setTimeout(r, 800));
    // Do not replace with `fields` as they do not work properly
    // when db_field is reset to null, fields are not updated
    try {
      form
        .getValues("mapping")
        .forEach(({ flow_input_field, db_field }, index) => {
          if (db_field) return;

          const { similarity, match } = closestMatch(flow_input_field, columns);
          if (similarity > 0.85) {
            update(index, { db_field: match, flow_input_field });
          }
        });
      setAutoMapState("done");
      await new Promise((r) => setTimeout(r, 800));
    } finally {
      setAutoMapState("standby");
    }
  };

  const versionWithoutSchema = usedVersions.find((v) => !v.input_schema);

  const areTrafficPoliciesEnabled = isFeatureFlagEnabled(
    FEATURE_FLAGS.jobTrafficPolicies,
  );

  return (
    <>
      <AccordionRoot
        className={accordionRootClassName}
        defaultValue={["sql-query", "mapping"]}
        type="multiple"
      >
        <AccordionItem
          className="flex flex-col gap-y-3 pb-6"
          title="SQL Query"
          value="sql-query"
        >
          <Controller
            name="connection_id"
            render={({ field }) => (
              <Select
                dataLoc="db-connection-select"
                dropdownPlaceholder={
                  <EmptyState
                    action={
                      connectionsCapabilities.canAccess && (
                        <Button
                          iconLeft={faPlus}
                          variant="secondary"
                          onClick={() => {
                            window.open(
                              getBaseUrl() +
                                getUrlToWsDashboard({
                                  orgId,
                                  wsId: workspace.id,
                                  page: "connections",
                                }),
                            );
                          }}
                        >
                          Add Connection
                        </Button>
                      )
                    }
                    description={
                      connectionsCapabilities.canAccess
                        ? "Please add a new DB connection in order to add a SQL source"
                        : "Please ask a person with sufficient permissions to create a SQL source for you"
                    }
                    headline="No database connection found"
                    icon={faDatabase}
                  />
                }
                loading={connections.isLoading}
                options={dbConnections.map((connection) => ({
                  key: connection.id,
                  value: (
                    <div className="flex items-center gap-x-2.5">
                      <ProviderIcon provider={connection.provider} />
                      {connection.name}
                    </div>
                  ),
                }))}
                placeholder="Select DB connection"
                value={field.value}
                onChange={(value) => {
                  field.onChange(value);

                  // If we are currently using sandbox credentials
                  // for sandbox runs we need to check whether the
                  // new selected connection has sandbox credentials.
                  // If it doesn't, we reset it to live credentials
                  const configurationIsUsingSandboxCredentials =
                    form.getValues().credentials_for_sandbox_run ===
                    "sandbox_credentials";

                  if (configurationIsUsingSandboxCredentials) {
                    const selectedConnection = dbConnections.find(
                      (connection) => connection.id === value,
                    );

                    const selectedConnectionHasSandboxCredentials =
                      connectionHasSandboxCredentials(selectedConnection);

                    if (!selectedConnectionHasSandboxCredentials) {
                      form.setValue(
                        "credentials_for_sandbox_run",
                        "live_credentials",
                      );
                    }
                  }
                }}
              />
            )}
          />
          <div className="h-70">
            <Controller
              name="query"
              render={({ field }) => (
                <CodeEditor
                  // Disable autocompletion
                  completionExtension={autocompletion({
                    override: [() => null],
                  })}
                  dataLoc="sql-query-editor"
                  keyBindings={[
                    {
                      mac: "Cmd-Enter",
                      win: "Ctrl-Enter",
                      linux: "Ctrl-Enter",
                      run: () => {
                        onRunQuery();
                        return true;
                      },
                    },
                  ]}
                  language="sql"
                  value={field.value ?? ""}
                  onChange={field.onChange}
                />
              )}
            />
          </div>
          <div className="flex items-start gap-x-2">
            <RunQueryButton jobId={job.id} onClick={onRunQuery} />
            {showQueryChangedWarning && (
              <InformationPill className="flex-1" type="warning">
                {showQueryChangedWarning === "connection_id"
                  ? "A different connection was selected."
                  : "SQL query updated."}{" "}
                Run the query to refresh the preview results.
              </InformationPill>
            )}
          </div>
        </AccordionItem>
        <AccordionItem
          className="pb-6"
          headerContent={
            <span className="justify-end pr-4">
              <Tooltip
                align="center"
                body={
                  <>
                    <p>
                      Automatically align SQL response fields with the
                      corresponding fields in the decision flow input schema
                      based on the column names.
                    </p>
                    {columns.length === 0 && (
                      <p className="mt-2">Run query to enable this feature.</p>
                    )}
                  </>
                }
                placement="bottom"
                title="Auto-fill Fields"
              >
                <Button
                  dataLoc="auto-fill-button"
                  disabled={columns.length === 0 || autoMapState !== "standby"}
                  size="sm"
                  variant="secondary"
                  onClick={autoMapFields}
                >
                  <div className="flex items-center gap-x-1.5">
                    {autoMapState !== "done" && (
                      <div
                        className={
                          autoMapState === "in_progress" ? "animate-wand" : ""
                        }
                      >
                        <Icon
                          color="text-gray-500"
                          icon={faWandMagicSparkles}
                          padding={false}
                          size="2xs"
                        />
                      </div>
                    )}
                    {autoMapState === "done" && (
                      <Icon
                        color="text-green-600"
                        icon={faCheck}
                        padding={false}
                        size="2xs"
                      />
                    )}
                    Auto-fill Fields
                  </div>
                </Button>
              </Tooltip>
            </span>
          }
          title="Mapping"
          value="mapping"
        >
          {usedVersions.at(0) ? (
            <div className="flex flex-col gap-y-3">
              {isFeatureFlagEnabled(FEATURE_FLAGS.sqlEntityId) && (
                <Card>
                  <Card.Content>
                    <div className="flex flex-col gap-y-2">
                      <MappingFieldsWrapper
                        left={
                          <span className="text-gray-500 font-inter-normal-12px">
                            SQL Response
                          </span>
                        }
                        right={
                          <span className="text-gray-500 font-inter-normal-12px">
                            Flow Metadata
                          </span>
                        }
                        hideArrow
                      />

                      <MappingFieldsWrapper
                        left={
                          <Controller
                            name="metadata_mapping.0.db_field"
                            render={({ field }) => (
                              <Combobox
                                dataLoc="db-metadata-field-combobox"
                                dropdownPlaceholder={
                                  <EmptyState
                                    action={
                                      <RunQueryButton
                                        jobId={job.id}
                                        onClick={onRunQuery}
                                      />
                                    }
                                    description="Run query to get column name suggestions."
                                    headline="No columns found"
                                    icon={faPlayRegular}
                                    size="sm"
                                  />
                                }
                                errored={
                                  field.value &&
                                  columns.length &&
                                  !columns.includes(field.value)
                                }
                                options={columns.map((column) => ({
                                  key: column,
                                  value: column,
                                }))}
                                placeholder="select field"
                                value={field.value}
                                monospaced
                                showResetButton
                                onChange={field.onChange}
                              />
                            )}
                          />
                        }
                        right={<EntityIdSchemaField />}
                      />
                      {isFeatureFlagEnabled(
                        FEATURE_FLAGS.jobTrafficPolicies,
                      ) && (
                        <MappingFieldsWrapper
                          left={
                            <Controller
                              name="metadata_mapping.1.db_field"
                              render={({ field }) => (
                                <Combobox
                                  dataLoc="db-metadata-field-combobox"
                                  dropdownPlaceholder={
                                    <EmptyState
                                      action={
                                        <RunQueryButton
                                          jobId={job.id}
                                          onClick={onRunQuery}
                                        />
                                      }
                                      description="Run query to get column name suggestions."
                                      headline="No columns found"
                                      icon={faPlayRegular}
                                      size="sm"
                                    />
                                  }
                                  errored={
                                    field.value &&
                                    columns.length &&
                                    !columns.includes(field.value)
                                  }
                                  options={columns.map((column) => ({
                                    key: column,
                                    value: column,
                                  }))}
                                  placeholder="select field"
                                  value={field.value}
                                  monospaced
                                  showResetButton
                                  onChange={field.onChange}
                                />
                              )}
                            />
                          }
                          right={<VersionSchemaField />}
                        />
                      )}
                    </div>
                  </Card.Content>
                </Card>
              )}
              {versionWithoutSchema ? (
                <div className="rounded-lg border border-gray-200">
                  <EmptyState
                    action={
                      <Button
                        iconLeft={faPlus}
                        variant="secondary"
                        onClick={() => {
                          window.open(
                            buildSchemaTabUrl(
                              orgId,
                              workspace.id,
                              flow.id,
                              versionWithoutSchema.id,
                              SchemaOptions.Input,
                            ),
                            "_blank",
                          );
                        }}
                      >
                        Define Schema
                      </Button>
                    }
                    description={`Define a schema for ${flow.name}, ${versionWithoutSchema.name} to complete mapping.`}
                    headline="Missing Schema"
                    icon={faBracketsCurly}
                  />
                </div>
              ) : (
                <SourceToInputMapping
                  columns={columns}
                  fields={fields}
                  job={job}
                  usedVersions={usedVersions}
                  onRunQuery={onRunQuery}
                />
              )}
            </div>
          ) : (
            <div className="rounded-lg border border-gray-200">
              <EmptyState
                action={
                  <Button
                    dataLoc="select-decision-flow-button"
                    iconLeft={faPlus}
                    variant="secondary"
                    onClick={openSelectVersionModal}
                  >
                    Select Decision Flow
                  </Button>
                }
                description="Select a Decision Flow you this job to run in order to complete mapping"
                headline="No Decision Flow selected"
                icon={faBox}
              />
            </div>
          )}
        </AccordionItem>
        <AccordionItem
          className="flex flex-col gap-y-3 pb-6"
          title="Advanced settings"
          value="advanced-settings"
        >
          <div className="flex items-center justify-between">
            <div>
              <p className="text-gray-800 font-inter-semibold-13px">
                For sandbox run, use
              </p>
              <p className="text-gray-500 font-inter-normal-12px">
                Jobs run through sandbox mode
              </p>
            </div>
            <div className="w-[188px] flex-shrink-0">
              <Controller
                name="credentials_for_sandbox_run"
                render={({ field }) => (
                  <Select
                    options={[
                      {
                        key: "sandbox_credentials",
                        value: (
                          <Tooltip
                            action={
                              connectionsCapabilities.canAccess
                                ? {
                                    label: "Go to connections",
                                    onClick: () => {
                                      window.open(
                                        getBaseUrl() +
                                          getUrlToWsDashboard({
                                            orgId,
                                            wsId: workspace.id,
                                            page: "connections",
                                          }),
                                      );
                                    },
                                  }
                                : undefined
                            }
                            activated={!allowSelectingSandboxCredentials}
                            placement="right"
                            placementOffset={50}
                            title="Configure a Sandbox connection to select this option"
                          >
                            Sandbox Connection
                          </Tooltip>
                        ),
                        disabled: !allowSelectingSandboxCredentials,
                      },
                      { key: "live_credentials", value: "Live Connection" },
                    ]}
                    value={field.value}
                    onChange={field.onChange}
                  />
                )}
              />
            </div>
          </div>
          <div className="flex items-center justify-between">
            <div>
              <p className="text-gray-800 font-inter-semibold-13px">
                For live run, use
              </p>
              <p className="text-gray-500 font-inter-normal-12px">
                Jobs run through live mode, or triggered on schedule
              </p>
            </div>
            <div className="w-[188px] flex-shrink-0">
              <Select
                options={[
                  { key: "live_credentials", value: "Live Connection" },
                ]}
                value="live_credentials"
                disabled
                onChange={noop}
              />
            </div>
          </div>
        </AccordionItem>
      </AccordionRoot>
      {areTrafficPoliciesEnabled ? (
        <TrafficPolicyModal
          job={job}
          open={isSelectVersionModalOpen}
          onClose={closeSelectVersionModal}
        />
      ) : (
        <SelectVersionModal
          closeModal={closeSelectVersionModal}
          isOpen={isSelectVersionModalOpen}
          job={job}
        />
      )}
    </>
  );
};

const caseInsensitiveLevenshtein = (a: string, b: string) =>
  levenshtein(lowerCase(a), lowerCase(b));

const closestMatch = (input: string, options: string[]) =>
  options.reduce(
    (acc, option) => {
      const { similarity } = caseInsensitiveLevenshtein(input, option);
      return similarity > acc.similarity ? { similarity, match: option } : acc;
    },
    { similarity: -Infinity, match: "" },
  );

const getSchemaUnion = (schemas: Dictionary<PropertyUIT>[]) => {
  const union: Dictionary<PropertyUIT> = {};
  for (const schema of schemas) {
    for (const [key, value] of Object.entries(schema)) {
      union[key] = value;
    }
  }
  return union;
};

const SourceToInputMapping: React.FC<{
  job: Job;
  usedVersions: FlowVersionFlowChild[];
  onRunQuery: () => void;
  columns: string[];
  fields: FieldArrayWithId<SQLJobSourceConfiguration, "mapping", "id">[];
}> = ({ job, usedVersions, onRunQuery, columns, fields }) => {
  const { workspace, orgId, flow } = useFlowContext();
  const schemas = usedVersions.map((v) =>
    keyBy(
      SchemaConverter.beToUI(v.input_schema!, SchemaOptions.Input).properties,
      "fieldName",
    ),
  );

  const schema = getSchemaUnion(schemas);

  const getVersionSchemaUrlForField = (fieldName: string) => {
    const versionId = usedVersions.find(
      (v) => fieldName in v.input_schema!.properties,
    )!.id;
    return buildSchemaTabUrl(
      orgId,
      workspace.id,
      flow.id,
      versionId,
      SchemaOptions.Input,
    );
  };

  return (
    <Card data-loc="sql-source-mapping">
      <Card.Content>
        <div className="flex flex-col gap-y-2">
          <MappingFieldsWrapper
            left={
              <span className="text-gray-500 font-inter-normal-12px">
                SQL Response
              </span>
            }
            right={
              <span className="text-gray-500 font-inter-normal-12px">
                Decision Flow Input Schema
              </span>
            }
            hideArrow
          />
          {fields
            .filter(({ flow_input_field }) => flow_input_field in schema)
            .map(({ id, flow_input_field }, index) => (
              <MappingFieldsWrapper
                key={id}
                left={
                  <Controller
                    name={`mapping.${index}.db_field`}
                    render={({ field }) => (
                      <Combobox
                        dataLoc="db-field-combobox"
                        dropdownPlaceholder={
                          <EmptyState
                            action={
                              <RunQueryButton
                                jobId={job.id}
                                onClick={onRunQuery}
                              />
                            }
                            description="Run query to get column name suggestions."
                            headline="No columns found"
                            icon={faPlayRegular}
                            size="sm"
                          />
                        }
                        errored={
                          field.value &&
                          columns.length &&
                          !columns.includes(field.value)
                        }
                        options={columns.map((column) => ({
                          key: column,
                          value: column,
                        }))}
                        placeholder="select field"
                        value={field.value}
                        monospaced
                        showResetButton
                        onChange={field.onChange}
                      />
                    )}
                  />
                }
                right={
                  <SchemaField
                    name={flow_input_field}
                    nullable={schema[flow_input_field].type[1] === "null"}
                    required={schema[flow_input_field].required}
                    schemaTabUrl={getVersionSchemaUrlForField(flow_input_field)}
                    sensitive={schema[flow_input_field].sensitive}
                    tooltipBody="Modify the input fields on the Decision Flow canvas."
                    type={schema[flow_input_field].type[0]}
                  />
                }
              />
            ))}
        </div>
      </Card.Content>
    </Card>
  );
};

const MappingFieldsWrapper: React.FC<{
  left: React.ReactNode;
  right: React.ReactNode;
  hideArrow?: boolean;
}> = ({ left, right, hideArrow }) => (
  <div className="flex items-center gap-x-3">
    <div className="w-1/2 min-w-0">{left}</div>
    <div className="flex-none">
      <div className={twJoin(hideArrow && "invisible")}>
        <Icon color="text-gray-500" icon={faArrowRight} size="xs" />
      </div>
    </div>
    <div className="w-1/2 min-w-0">{right}</div>
  </div>
);

const useMutatingPreview = (jobId: string) => {
  const { workspace } = useWorkspaceContext();
  return !!useIsMutating({
    mutationKey: ["preview_job_source", workspace.base_url, jobId],
  });
};

const getQueryButtonTooltip = (
  query: string | undefined,
  connection_id: string | undefined,
) => {
  if (!query && !connection_id)
    return "Input a connection and query to run the test";
  if (!query) return "A query string is required to run the test";
  if (!connection_id) return "A connection is required to run the test";
};

const RunQueryButton: React.FC<{
  jobId: string;
  onClick: () => void;
}> = ({ jobId, onClick }) => {
  const isMutating = useMutatingPreview(jobId);
  const [query, connection_id] = useWatch({ name: ["query", "connection_id"] });
  const tooltip = getQueryButtonTooltip(query, connection_id);

  return (
    <Tooltip
      activated={!!tooltip}
      align="center"
      placement="top"
      title={tooltip}
    >
      <Button
        dataLoc="run-query-button"
        disabled={!!tooltip || isMutating}
        iconLeft={faPlay}
        loading={isMutating}
        variant="secondary"
        onClick={onClick}
      >
        Run Query
      </Button>
    </Tooltip>
  );
};

const EntityIdSchemaField: React.FC<{}> = () => (
  <div className="flex h-8 w-full flex-1 flex-grow items-center gap-x-1 rounded-lg border border-gray-200 bg-gray-50 py-1 pl-3 pr-2 text-gray-500">
    <Tooltip placement="bottom" title="string" asChild>
      <Icon icon={SCHEMA_TYPE_ICONS.string} size="xs" />
    </Tooltip>

    <span className="truncate font-inter-normal-12px">entity_id</span>

    <div className="ml-auto flex items-center gap-x-1">
      <Tooltip
        action={{
          label: "Read more",
          onClick: () => window.open(ENTITY_ID_DOCS_PAGE, "_blank"),
        }}
        body={
          <>
            <code>entity_id</code> helps you to link decisions to your internal
            systems. <code>entity_id</code> is passed as a metadata of the
            decision request, and does not affect the decision logic.
          </>
        }
        placement="right"
        title=""
        asChild
      >
        <Icon color="text-gray-500" icon={faInfoCircle} size="xs" />
      </Tooltip>
    </div>
  </div>
);

const VersionSchemaField: React.FC<{}> = () => (
  <div className="flex h-8 w-full flex-1 flex-grow items-center gap-x-1 rounded-lg border border-gray-200 bg-gray-50 py-1 pl-3 pr-2 text-gray-500">
    <Tooltip placement="bottom" title="string" asChild>
      <Icon icon={SCHEMA_TYPE_ICONS.string} size="xs" />
    </Tooltip>

    <span className="truncate font-inter-normal-12px">version</span>

    <div className="ml-auto flex items-center gap-x-1">
      <Tooltip
        action={{
          label: "Read more",
          onClick: () => window.open(ENTITY_ID_DOCS_PAGE, "_blank"),
        }}
        body="Use this column to specify the version name that should be used for each row. When a value is present it is used instead of the routing configuration."
        placement="right"
        title=""
        asChild
      >
        <Icon color="text-gray-500" icon={faInfoCircle} size="xs" />
      </Tooltip>
    </div>
  </div>
);
