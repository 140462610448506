import { faPlus } from "@fortawesome/pro-regular-svg-icons";
import React from "react";

import { OutcomeType, OutcomeTypeStatusEnum } from "src/clients/flow-api";
import { useModal } from "src/design-system/Modal";
import { SubHeader } from "src/flow/SubHeader";
import { useCapabilities } from "src/hooks/useCapabilities";
import { WhitePane } from "src/jobs/common/WhitePane";
import { DeleteOutcomeTypeModal } from "src/outcomes/DeleteOutcomeTypeModa";
import { ManageOutcomeTypeModal } from "src/outcomes/ManageOutcomeTypeModal";
import { OutcomeTypesTable } from "src/outcomes/OutcomeTypesTable";
import { useOutcomeTypes } from "src/outcomes/queries";
import { useFlowContext } from "src/router/routerContextHooks";

const MAX_OUTCOME_TYPES = 5;

export const OutcomesContent: React.FC = () => {
  const { flow } = useFlowContext();
  const { data: outcomeTypes, isLoading } = useOutcomeTypes(flow.id);
  const { outcomes } = useCapabilities();
  const { isOpen: isModalOpen, openModal, closeModal } = useModal();
  const {
    isOpen: isDeleteModalOpen,
    openModal: openDeleteModal,
    closeModal: closeDeleteModal,
    afterLeave: closeDeleteModalAfterLeave,
    data: outcomeTypeToDelete,
  } = useModal<OutcomeType>();

  const isMaxOutcomeTypesReached =
    (outcomeTypes ?? []).filter(
      (type) => type.status === OutcomeTypeStatusEnum.ACTIVE,
    ).length >= MAX_OUTCOME_TYPES;

  return (
    <>
      <SubHeader title="Outcomes" paddedParent>
        {outcomes.canCreate && (
          <SubHeader.Button
            dataLoc="create-outcome-button"
            disabled={isMaxOutcomeTypesReached}
            icon={faPlus}
            tooltip={
              isMaxOutcomeTypesReached
                ? "You have maximum number of outcome types"
                : "Create Outcome"
            }
            onClick={openModal}
          />
        )}
      </SubHeader>
      <div
        className="max-w m-auto flex w-full max-w-[1800px] flex-col gap-y-5.5"
        data-loc="outcomes-content"
      >
        <WhitePane>
          <OutcomeTypesTable
            data={outcomeTypes}
            isLoading={isLoading}
            onOutcomeTypeDelete={openDeleteModal}
          />
        </WhitePane>
      </div>
      <ManageOutcomeTypeModal isOpen={isModalOpen} onClose={closeModal} />
      <DeleteOutcomeTypeModal
        afterLeave={closeDeleteModalAfterLeave}
        isOpen={isDeleteModalOpen}
        outcomeType={outcomeTypeToDelete}
        onClose={closeDeleteModal}
      />
    </>
  );
};
