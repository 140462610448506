import { get, capitalize } from "lodash";
import { useState } from "react";
import { Controller, useFormContext } from "react-hook-form";

import { EnumField } from "./EnumField";
import { ManifestJSONSchema } from "src/api/connectApi/manifestTypes";
import { ErrorHint } from "src/base-components/ErrorHint";
import { FormItem } from "src/base-components/FormItem";
import { Input } from "src/base-components/Input";
import { Switch } from "src/base-components/Switch";
import { Textarea } from "src/base-components/Textarea";
import { EditSecretButton } from "src/connections/config/manifest/common/EditSecretButton";
import {
  FormPathT,
  ManifestFormType,
} from "src/connections/config/manifest/types";
import { isFieldOfNullableType } from "src/connections/config/manifest/utils";

type ManifestFormT = {
  schema: ManifestJSONSchema;
  prefix: string;
  isConnectionUpdate?: boolean;

  // Since this form is used in multiple places,
  // we need a way to properly construct the form names for the inputs
  formPath: FormPathT;
};

const isFieldString = (field: ManifestJSONSchema): boolean => {
  return isFieldOfNullableType(field, "string");
};

const isFieldNumber = (field: ManifestJSONSchema): boolean => {
  return isFieldOfNullableType(field, "number");
};

const isFieldInteger = (field: ManifestJSONSchema): boolean => {
  return isFieldOfNullableType(field, "integer");
};

const isFieldBoolean = (field: ManifestJSONSchema): boolean => {
  return isFieldOfNullableType(field, "boolean");
};

export const Form: React.FC<ManifestFormT> = ({
  schema,
  prefix,
  formPath,
  isConnectionUpdate,
}) => {
  const form = useFormContext<ManifestFormType>();

  const [editableFields, setEditableFields] = useState<{
    [key: string]: boolean;
  }>({});

  const toggleEditable = (key: string) => {
    setEditableFields((prev) => {
      const newState = { ...prev, [key]: !prev[key] };
      // We reset the value to an empty string when the secret
      // is toggled to editable.
      if (!prev[key]) {
        form.setValue(`${formPath}.${key}`, "");
      }
      return newState;
    });
  };

  if (!schema) return null;

  const secretFields = schema.secrets || [];
  return Object.entries(schema.properties || {}).map(([key, value]) => {
    if (typeof value === "boolean") {
      throw new Error("Boolean is not supported");
    }
    const isRequired = schema.required?.includes(key);

    const isSecret = secretFields.includes(key);
    const fieldError = get(form.formState.errors, `${formPath}.${key}`);

    const isDisabledSecretInput =
      isConnectionUpdate && isSecret && !editableFields[key];

    return (
      <>
        {"enum" in value ? (
          <FormItem
            key={key}
            dataLoc={`${prefix}-${key}`}
            description={value.description}
            gap="sm"
            id={`${prefix}_${key}`}
            isRequired={!!isRequired}
            label={value.title || capitalize(key)}
          >
            <EnumField
              fieldKey={key}
              formPath={formPath}
              isRequired={!!isRequired}
              jsonSchemaDefinition={value}
              prefix={prefix}
            />
          </FormItem>
        ) : isFieldBoolean(value) ? (
          <FormItem
            key={key}
            className="flex items-center justify-between"
            dataLoc={`${prefix}-${key}`}
            description={value.description}
            gap="sm"
            id={`${prefix}_${key}`}
            isRequired={!!isRequired}
            label={value.title || capitalize(key)}
          >
            <Controller
              control={form.control}
              name={`${formPath}.${key}`}
              render={({ field: { value, onChange } }) => (
                <div className="ml-2">
                  <Switch enabled={value as boolean} onChange={onChange} />
                </div>
              )}
              rules={{
                required: isRequired && "This field is required",
              }}
            />
          </FormItem>
        ) : isFieldString(value) && (value?.maxLength || 0) > 1000 ? (
          <FormItem
            key={key}
            dataLoc={`${prefix}-${key}`}
            description={value.description}
            gap="sm"
            id={`${prefix}_${key}`}
            isRequired={!!isRequired}
            label={value.title || capitalize(key)}
          >
            <Textarea
              errored={!!get(form.formState.errors, key)}
              {...form.register(`${formPath}.${key}`, {
                required: isRequired,
              })}
              placeholder={`Enter ${(value.title || key).toLowerCase()}`}
            />
          </FormItem>
        ) : (
          <FormItem
            key={key}
            dataLoc={`${prefix}-${key}`}
            description={value.description}
            gap={fieldError ? "xxs" : "sm"}
            id={`${prefix}_${key}`}
            isRequired={!!isRequired}
            label={value.title || capitalize(key)}
          >
            <div className="flex w-full">
              <div className="flex-1">
                <Input
                  data-loc={`${prefix}-${key}-input`}
                  disabled={isDisabledSecretInput}
                  errored={!!get(form.formState.errors, key)}
                  placeholder={`Enter ${(value.title || key).toLowerCase()}`}
                  type={isSecret ? "password" : "text"}
                  fullWidth
                  {...form.register(`${formPath}.${key}`, {
                    required: isRequired,
                    setValueAs: (v) => {
                      if (isFieldInteger(value) || isFieldNumber(value)) {
                        // "empty" values get casted to `undefined`
                        if (["", null, undefined].includes(v)) {
                          return undefined;
                        }

                        // whitespace strings are parsed as `0` by `Number`
                        // so we return `NaN` here instead
                        if (
                          v.toString().length !== v.toString().trim().length
                        ) {
                          return NaN;
                        }

                        return Number(v);
                      }

                      return v;
                    },
                    validate: (v) => {
                      if (v === undefined) return true;
                      else if (isFieldInteger(value) && !Number.isInteger(v)) {
                        return `This field must contain an integer`;
                      } else if (isFieldNumber(value) && isNaN(v)) {
                        return `This field must contain a number`;
                      } else return true;
                    },
                  })}
                />
              </div>
              {isDisabledSecretInput && (
                <EditSecretButton
                  alignment="center"
                  secretKey={key}
                  secretName={value.title || ""}
                  toggleEditable={() => toggleEditable(key)}
                />
              )}
            </div>
          </FormItem>
        )}
        {fieldError && (
          <ErrorHint className="mb-6">
            {(fieldError.message as string) || ""}
          </ErrorHint>
        )}
      </>
    );
  });
};
